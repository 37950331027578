import React, {useEffect, useState} from 'react';

type Props = {
	children: React.ReactNode;
};

const FadeIn = ({children}: Props) => {
	const [visible, setVisible] = useState<boolean>(false);

	useEffect(() => {
		setTimeout(() => {
			setVisible(true);
		}, 50);
	}, []);

	return (
		<div className={`fadein ${visible ? 'visible' : 'hidden'}`}>
			{children}
		</div>
	);
};

export default FadeIn;