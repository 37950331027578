import {UseMutationResult, useMutation} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';

import Api from './api';
import {useLoadingMessage} from '../utils/useMessage';

export type SetPassword = {
	email: string;
	token: string;
	password: string;
};

type SetPasswordResponse = {
	status: 'success' | 'error',
};

const setPassword = async (body: SetPassword) => {
	const data: AxiosResponse<SetPasswordResponse> = await Api.put(
		'/user/set-password',
		body,
	);
	return data;
};

export const useSetPassword = (): UseMutationResult<AxiosResponse<SetPasswordResponse>, unknown, SetPassword, unknown> => {
	const loading = useLoadingMessage();

	return useMutation({
		mutationFn: (data) => {
			return setPassword(data);
		},
		onError: (err) => {
			console.error(err);
			loading.error();
		}
	});
};