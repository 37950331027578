import React, {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Form, InputNumber, Typography, DatePicker, Button, FormInstance} from 'antd';
import dayjs from 'dayjs';

import 'dayjs/locale/fi';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/sv';
import localeFI from 'antd/es/date-picker/locale/fi_FI';
import localeEN from 'antd/es/date-picker/locale/en_GB';
import localeSV from 'antd/es/date-picker/locale/sv_SE';

const {Text} = Typography;
const {RangePicker} = DatePicker;

type DateRangeInputExtraProps = {
	onSelectRange: (range: Range) => void;
	selectedRange: Range | null;
}

type Range = 'today' | 'yesterday' | 'last-7-days' | 'last-30-days' | 'current-month' | 'last-month';

const DateRangeInputExtra = ({onSelectRange, selectedRange}: DateRangeInputExtraProps) => {
	const {t} = useTranslation();

	return (
		<div className={'exports__type__filter__date__extra'}>
			<Text>{t('tools.exports.date-picker.label')}</Text>
			<div className={'exports__type__filter__date__extra__buttons'}>
				<Button size={'small'} type={selectedRange === 'today' ? 'primary' : 'default'} onClick={() => onSelectRange('today')}>
					{t('tools.exports.date-picker.today')}
				</Button>
				<Button size={'small'} type={selectedRange === 'yesterday' ? 'primary' : 'default'} onClick={() => onSelectRange('yesterday')}>
					{t('tools.exports.date-picker.yesterday')}
				</Button>
				<Button size={'small'} type={selectedRange === 'last-7-days' ? 'primary' : 'default'} onClick={() => onSelectRange('last-7-days')}>
					{t('tools.exports.date-picker.last-7-days')}
				</Button>
				<Button size={'small'} type={selectedRange === 'last-30-days' ? 'primary' : 'default'} onClick={() => onSelectRange('last-30-days')}>
					{t('tools.exports.date-picker.last-30-days')}
				</Button>
				<Button size={'small'} type={selectedRange === 'current-month' ? 'primary' : 'default'} onClick={() => onSelectRange('current-month')}>
					{t('tools.exports.date-picker.current-month')}
				</Button>
				<Button size={'small'} type={selectedRange === 'last-month' ? 'primary' : 'default'} onClick={() => onSelectRange('last-month')}>
					{t('tools.exports.date-picker.last-month')}
				</Button>
			</div>
		</div>
	);
};

const getRangeDates = (range: Range) => {
	const today = dayjs();
	if (range === 'today') {
		return [today, today];
	}
	if (range === 'yesterday') {
		const yesterday = today.subtract(1, 'd');
		return [yesterday, yesterday];
	}
	if (range === 'last-7-days') {
		return [today.subtract(7, 'd'), today];
	}
	if (range === 'last-30-days') {
		return [today.subtract(30, 'd'), today];
	}
	if (range === 'current-month') {
		return [today.startOf('month'), today];
	}
	if (range === 'last-month') {
		const lastMonth = today.subtract(1, 'M');
		return [lastMonth.startOf('month'), lastMonth.endOf('month')];
	}
};

type InputProps = {
	visible: boolean;
};

const DateRangeInput = ({visible, form}: InputProps & {form: FormInstance}) => {
	const {t, i18n} = useTranslation();

	const [selectedRange, setSelectedRange] = useState<Range | null>(null);

	const datePickerLocale = useMemo(() => {
		const lng = i18n.language;
		if (lng.includes('en')) return localeEN;
		if (lng.includes('sv')) return localeSV;
		return localeFI;
	}, [i18n]);

	const onSelectRange = useCallback((range: Range) => {
		setSelectedRange(range);
		const dates = getRangeDates(range);
		form.setFieldValue('type_date', dates);
	}, [form]);

	return (
		<div className={`exports__type__filter ${visible ? 'visible' : 'hidden'}`}>
			<Text strong>{t('tools.exports.filter.date.label')}</Text>
			<Form.Item name={'type_date'}>
				<RangePicker
					locale={datePickerLocale}
					format={'D.M.YYYY'}
					onChange={() => setSelectedRange(null)}
					renderExtraFooter={() => <DateRangeInputExtra onSelectRange={onSelectRange} selectedRange={selectedRange} />}
				/>
			</Form.Item>
		</div>
	);
};

const ReferenceNumberInput = ({visible}: InputProps) => {
	const {t} = useTranslation();

	return (
		<div className={`exports__type__filter ${visible ? 'visible' : 'hidden'}`}>
			<Text strong>{t('tools.exports.filter.reference-number.label')}</Text>
			<Form.Item name={'type_reference-number-start'}>
				<InputNumber min={1} />
			</Form.Item>
			–
			<Form.Item name={'type_reference-number-end'}>
				<InputNumber min={1} />
			</Form.Item>
		</div>
	);
};

const AccountingNumberInput = ({visible}: InputProps) => {
	const {t} = useTranslation();

	return (
		<div className={`exports__type__filter ${visible ? 'visible' : 'hidden'}`}>
			<Text strong>{t('tools.exports.filter.accounting-number.label')}</Text>
			<Form.Item name={'type_accounting-number-start'}>
				<InputNumber min={1} />
			</Form.Item>
			–
			<Form.Item name={'type_accounting-number-end'}>
				<InputNumber min={1} />
			</Form.Item>
		</div>
	);
};

type Props = {
	type: 'date' | 'reference-number' | 'accounting-number';
	form: FormInstance;
}

const Filter = ({type, form}: Props) => {
	return (
		<div className={'exports__type__filter__container'}>
			<DateRangeInput visible={type === 'date'} form={form} />
			<ReferenceNumberInput visible={type === 'reference-number'} />
			<AccountingNumberInput visible={type === 'accounting-number'} />
		</div>
	);
};

export default Filter;