import {UseMutationResult, useMutation} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';

import Api from './api';
import {useLoadingMessage} from '../utils/useMessage';
import {useGetAccountInfo} from './useGetAccountInfo';

type UpdateMileageTypeData = {
	id: number;
	mileageType: string;
	mileageInUse: number;
};

type UpdateMileageTypeDataResult = {
	mileageId: number;
	mileageType: string;
	mileageAmount: number;
	mileageInUse: number;
};

const updateMileageTypeById = async (body: UpdateMileageTypeData) => {
	const data: AxiosResponse<UpdateMileageTypeDataResult> = await Api.put(
		`/mileage-types/${body.id}`,
		{mileageType: body.mileageType, mileageInUse: body.mileageInUse},
		{withCredentials: true}
	);
	return data;
};

export const useUpdateMileageType = (): UseMutationResult<AxiosResponse<UpdateMileageTypeDataResult>, unknown, UpdateMileageTypeData, unknown> => {
	const loading = useLoadingMessage();
	const {updateMileageTypesData} = useGetAccountInfo();

	return useMutation({
		mutationFn: (data) => {
			loading.start();
			return updateMileageTypeById(data);
		},
		onSuccess: ({data}) => {
			updateMileageTypesData(data, 'update');
			loading.success();
		},
		onError: (err) => {
			console.error(err);
			loading.error();
		}
	});
};