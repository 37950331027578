import {useQuery} from '@tanstack/react-query';

import Api from './api';
import {AxiosResponse} from 'axios';
import {useIsLoggedIn} from '../utils/useIsLoggedIn';

type Account = {
	accountId: number;
	accountName: string;
};

export type UserInfoData = {
	userEmail: string;
	userEmailNotifications: number;
	userEmailNotificationsArchive: number;
	userId: number;
	userName: string;
	userActiveAccount: number;
	userAccounts: Account[];
	accessLevel: number;
};

type UserInfoResponse = {
	data: UserInfoData;
	isLoading: boolean;
	isSuccess: boolean;
	isError: boolean;
	error: unknown;
}

export const emptyData = {
	userEmail: '',
	userEmailNotifications: 0,
	userEmailNotificationsArchive: 0,
	userId: 0,
	userName: '',
	userActiveAccount: 0,
	userAccounts: [],
	accessLevel: 2,
};

const getUserInfo = async () => {
	const {data}: AxiosResponse<UserInfoData> = await Api.get('/user');
	return data;
};

export const useGetUserInfo = (forceEnable?: boolean): UserInfoResponse => {
	const loggedIn = useIsLoggedIn();

	const query = useQuery({
		queryKey: ['user-info'],
		queryFn: getUserInfo,
		cacheTime: 60 * 60 * 1000,
		enabled: loggedIn || !!forceEnable,
	});

	return {
		data: query.data || emptyData,
		isLoading: query.isLoading,
		isSuccess: query.isSuccess,
		isError: query.isError,
		error: query.error,
	};
};