import {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Form, Alert, Input, Button} from 'antd';
import {Navigate} from 'react-router-dom';

import RouteContainer from '../../components/RouteContainer';
import ContentCard from '../../components/ContentCard';
import SelectBills from './Components/SelectBills';
import ConfirmModal from './Components/ConfirmModal';
import {useAddFiscalYear} from '../../queries/useAddFiscalYear';
import {useMessage} from '../../utils/useMessage';
import {useUserRole} from '../../utils/useUserRole';
import TranslatedText from '../../components/TranslatedText';

type DatePickerValue = {
	$d: Date;
};

type FormValues = {
	name: string;
	startDate: DatePickerValue;
	endDate: DatePickerValue;
	password: string;
};

const LockAccountingPeriod = () => {
	const {t} = useTranslation();
	const [form] = Form.useForm();

	const message = useMessage();
	const {role, isLoading: isLoadingRole} = useUserRole();

	const {isLoading, ...addFiscalYear} = useAddFiscalYear();

	const [selected, setSelected] = useState<number[]>([]);
	const [confirmModal, setConfirmModal] = useState<boolean>(false);

	const onFormSubmit = useCallback(() => {
		if (selected.length === 0) {
			return message.open({
				type: 'error',
				content: t('tools.lock.select-at-least-one-bill'),
				duration: 5,
			});
		}
		setConfirmModal(true);
	}, [selected, message, t]);

	const closeConfirmModal = useCallback(() => {
		setConfirmModal(false);
	}, []);

	const createFiscalYear = useCallback(() => {
		const values: FormValues = form.getFieldsValue(true);
		const data = {
			name: values.name,
			bills: selected,
		};

		addFiscalYear.mutate(data, {
			onSuccess: () => {
				closeConfirmModal();
				setSelected([]), form.setFieldValue('name', '');
			},
		});
	}, [form, selected, addFiscalYear, closeConfirmModal]);

	if (role === 'view' && !isLoadingRole) {
		return <Navigate replace to={'/404'} />;
	}

	return (
		<RouteContainer title={t('tools.lock.title')}>
			<Alert
				type={'info'}
				description={<TranslatedText translationKey={'tools.lock.info'} />}
				className={'lockaccountingperiod__info'}
			/>
			<ContentCard
				title={t('tools.lock.title')}
				className={'lockaccountingperiod__card'}
			>
				<Form
					form={form}
					className={'lockaccountingperiod__form'}
					wrapperCol={{span: 10}}
					onFinish={onFormSubmit}
					validateMessages={{
						required: t('tools.lock.invalid-field'),
					}}
				>
					<Form.Item
						name={'name'}
						label={t('tools.lock.name')}
						colon={false}
						required={false}
						rules={[{required: true}]}
					>
						<Input
							placeholder={t('tools.lock.name-placeholder', {
								year: new Date().getFullYear() - 1,
							})}
						/>
					</Form.Item>

					<SelectBills
						selected={selected}
						setSelected={setSelected}
					/>

					<Button
						htmlType={'submit'}
						type={'primary'}
						loading={isLoading}
					>
						{t('tools.lock.submit')}
					</Button>
				</Form>
			</ContentCard>

			<ConfirmModal
				open={confirmModal}
				onCancel={closeConfirmModal}
				onSubmit={createFiscalYear}
				isLoading={isLoading}
			/>
		</RouteContainer>
	);
};

export default LockAccountingPeriod;
