import React, {useCallback, useState, useEffect} from 'react';
import {Form, Upload, Button, Modal, Spin, FormInstance} from 'antd';
import type {RcFile, UploadChangeParam, UploadFile, UploadFileStatus} from 'antd/es/upload/interface';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUpload, faRotateLeft, faRotateRight, faTrash, faPlus} from '@fortawesome/free-solid-svg-icons';
import {useTranslation} from 'react-i18next';
import {AxiosResponse} from 'axios';

import {InvoiceValues, FileUploadResponse} from '../types';
import {useRotateImage, RotateImageResponse} from '../../../queries/useRotateImage';
import {useMessage} from '../../../utils/useMessage';

const convertFilesToFileList = (files: InvoiceValues[]): UploadFile<FileUploadResponse>[] => {
	const fileList: UploadFile<FileUploadResponse>[] = files.map((file) => ({
		uid: file.edit?.initialValue?.toString() || file.edit?.name || file.value.toString(),
		name: file.edit?.initialValue?.toString() || file.edit?.name || file.value.toString(),
		status: 'success' as UploadFileStatus,
		url: file.link,
		percent: 100,
		response: {
			status: 'success',
			url: file.link,
			id: file.edit?.initialValue?.toString() || file.edit?.name || file.value.toString(),
		},
	}));
	return fileList;
};

type Props = {
	files: InvoiceValues[];
	name: string;
	formInstance: FormInstance;
};

const EditFiles = ({files, name, formInstance}: Props) => {
	const {t} = useTranslation();

	const [fileList, setFileList] = useState<UploadFile<FileUploadResponse>[]>(convertFilesToFileList(files));
	const [isModalOpen, setIsModalOpen] = useState<UploadFile<FileUploadResponse> | false>(false);

	const {isLoading, ...rotateImage} = useRotateImage();
	const message = useMessage();

	useEffect(() => {
		formInstance.setFieldValue(name, fileList);
	}, [fileList, formInstance, name]);

	const onPreviewFile = useCallback((file?: UploadFile<FileUploadResponse>) => {
		if (file) {
			const mimetype = file.name.split('.').at(-1);
			// Open PDFs in new tab
			if (mimetype === 'pdf') return window.open(file.url);

			setIsModalOpen(file);
		}
	}, []);

	const onClosePreview = useCallback(() => setIsModalOpen(false), []);

	const onDelete = useCallback((id?: string) => {
		if (!id) return;
		const [folder] = id.split('/');

		if (folder === 'tmp') {
			const newFileList: UploadFile<FileUploadResponse>[] = [...fileList].filter((file) => {
				return file.response?.id !== id;
			});
			setFileList([...newFileList]);
			return setIsModalOpen(false);
		}

		const newFileList: UploadFile<FileUploadResponse>[] = [...fileList].map((file) => {
			if (file.response?.id === id) {
				return {
					...file,
					status: 'removed',
					response: {
						...file.response,
						status: 'removed',
					},
				};
			}
			return file;
		});

		if (isModalOpen !== false) {
			setIsModalOpen({
				...isModalOpen,
				status: 'removed',
				response: {
					...isModalOpen.response,
					status: 'removed',
				},
			});
		}

		setFileList([...newFileList]);
		return false;
	}, [fileList, isModalOpen]);

	const onRestore = useCallback((id?: string) => {
		const newFileList: UploadFile<FileUploadResponse>[] = [...fileList].map((file) => {
			if (file.uid === id) {
				return {
					...file,
					status: 'success' as UploadFileStatus,
					response: {
						...file.response,
						status: 'success',
					},
				};
			}
			return file;
		});
		if (isModalOpen !== false) {
			setIsModalOpen({
				...isModalOpen,
				status: 'success' as UploadFileStatus,
				response: {
					...isModalOpen.response,
					status: 'success',
				},
			});
		}
		setFileList([...newFileList]);
	}, [fileList, isModalOpen]);

	const onRotate = useCallback((direction: 'left' | 'right') => {
		if (isModalOpen !== false) {
			const data = {
				id: encodeURI(isModalOpen.uid) || '',
				direction,
			};

			rotateImage.mutate(data, {
				onSuccess: (result: AxiosResponse<RotateImageResponse>) => {
					if (result.status === 200) {
						setIsModalOpen({...isModalOpen, url: result.data.url, response: result.data});
						const newFileList = [...fileList].map((x) => {
							if (x.response?.id === result.data.id) {
								return {
									...x,
									url: result.data.url,
									response: result.data,
								};
							}
							return x;
						});
						setFileList(newFileList);
					}
				},
			});
		}
	}, [isModalOpen, fileList, rotateImage]);

	const onFileChange = useCallback((e: UploadChangeParam<UploadFile<FileUploadResponse>>) => {
		const {file, fileList: newFileList} = e;
		if (!file.status) return;
		const newFiles = newFileList.map((f) => {
			if (f.uid === file.uid) {
				return {
					...f,
					url: f.response?.url,
				};
			}
			return f;
		});
		setFileList([...newFiles]);
	}, []);

	const beforeUpload = useCallback((file: RcFile) => {
		const isLt10Mb = file.size < 10000000;
		if (!isLt10Mb) {
			message.open({
				type: 'error',
				content: t('bill.view.invoice.too-large-file'),
				duration: 5,
			});
		}
		return isLt10Mb;
	}, [t, message]);

	return (
		<>
			<Modal
				open={isModalOpen !== false}
				onCancel={onClosePreview}
				footer={null}
			>
				{isModalOpen !== false && (
					<div className={'viewbill__steps__card__content__editfiles__modal'}>
						{isLoading && (
							<div className={'viewbill__steps__card__content__editfiles__modal__loadingindicator'}>
								<Spin size={'large'} />
							</div>
						)}
						<img src={isModalOpen.url} />
						<div>
							<Button onClick={() => onRotate('left')} disabled={isLoading}>
								<FontAwesomeIcon icon={faRotateLeft} />
								{t('bill.view.invoice.rotate-file-anticlockwise')}
							</Button>
							<Button onClick={() => onRotate('right')} disabled={isLoading}>
								{t('bill.view.invoice.rotate-file-clockwise')}
								<FontAwesomeIcon icon={faRotateRight} />
							</Button>
							{isModalOpen.status !== 'removed' ? (
								<Button onClick={() => onDelete(isModalOpen.uid)} disabled={isLoading} danger={true}>
									<FontAwesomeIcon icon={faTrash} />
									{t('bill.view.invoice.delete-file')}
								</Button>
							) : (
								<Button onClick={() => onRestore(isModalOpen.uid)} disabled={isLoading} type={'primary'} ghost={true}>
									<FontAwesomeIcon icon={faPlus} />
									{t('bill.view.invoice.restore-file')}
								</Button>
							)}
						</div>
					</div>
				)}
			</Modal>

			<Form.Item name={name} className={'viewbill__steps__formitem fileupload'}>
				<Upload
					fileList={fileList}
					onChange={onFileChange}
					listType={'text'}
					action={'/api/files/upload'} name={'file'}
					accept={'.jpg, .jpeg, .png, .pdf'}
					onPreview={(file: UploadFile<FileUploadResponse>) => onPreviewFile(file)}
					onRemove={(file: UploadFile<FileUploadResponse>) => onDelete(file.response?.id)}
					multiple={true}
					beforeUpload={beforeUpload}
					itemRender={(originNode, file) => {
						if (file.status === 'removed') {
							const {children, className, ...itemProps} = originNode.props;
							return (
								<div className={`${className} viewbill__steps__card__content__editfiles__removed`} {...itemProps}>
									{children}
									<Button type={'text'} size={'small'} className={'restorefile'} onClick={() => onRestore(file.uid)}>
										<FontAwesomeIcon icon={faPlus} size={'xs'} />
										{t('bill.view.invoice.restore-file')}
									</Button>
								</div>
							);
						}
						return originNode;
					}}
				>
					<Button
						icon={<FontAwesomeIcon icon={faUpload} />}
					>
						{t('bill.view.invoice.upload-file')}
					</Button>
				</Upload>
			</Form.Item>
		</>
	);
};

export default EditFiles;