export const useIsLoggedIn = () => {
	const loggedIn = localStorage.getItem('loggedIn') === 'true';
	const expires = localStorage.getItem('logInExpires');

	if (loggedIn && expires === null) return true;

	// Check if logInExpires has expired (expires in 60 minutes)
	if (loggedIn && Number(expires) >= Date.now()) return true;

	return false;
};