import {UseMutationResult, useMutation, useQueryClient} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';

import Api from './api';
import {useLoadingMessage} from '../utils/useMessage';
import {AccessesData} from './useGetAccesses';

type AddAccessData = {
	email: string;
	accessLevel: 1 | 2;
};

type AddAccessDataResult = {
	accountId: number;
	userEmail: string;
	userName: string;
	accessLevel: 3;
	userId: null;
};

const addAccess = async (body: AddAccessData) => {
	const data: AxiosResponse<AddAccessDataResult> = await Api.post('/accesses/add', body, {withCredentials: true});
	return data;
};

export const useAddAccess = (): UseMutationResult<AxiosResponse<AddAccessDataResult>, unknown, AddAccessData, unknown> => {
	const queryClient = useQueryClient();
	const loading = useLoadingMessage();

	return useMutation({
		mutationFn: (data) => {
			loading.start();
			return addAccess(data);
		},
		onSuccess: ({data}) => {
			loading.success();
			queryClient.setQueryData(['account-accesses'], (oldData: AccessesData[] | undefined) => {
				if (!oldData) {
					return [];
				}
				return [
					...oldData,
					{
						userEmail: data.userEmail,
						userName: data.userName,
						userId: data.userId,
						accessLevel: data.accessLevel,
					},
				];
			});
		},
		onError: (err) => {
			console.error(err);
			loading.error();
		}
	});
};