import React, {useCallback, useEffect, useState, useMemo} from 'react';
import {
	Alert,
	Input,
	Select,
	Typography,
	Button,
} from 'antd';
import {useTranslation} from 'react-i18next';

import ContentCard from '../../components/ContentCard';
import RouteContainer from '../../components/RouteContainer';
import {useGetAccountInfo} from '../../queries/useGetAccountInfo';
import {useUpdateCategories} from '../../queries/useUpdateCategories';
import {useUserRole} from '../../utils/useUserRole';

const {TextArea} = Input;
const {Text} = Typography;

const Categories = () => {
	const {t} = useTranslation();

	const {data: accountInfo, isLoading: isLoadingAccountInfo} = useGetAccountInfo();
	const {accountExpenseCategories, accountIncomeCategories} = accountInfo;

	const {role} = useUserRole();

	const {isLoading, ...categoriesPut} = useUpdateCategories();

	const [expensesEnabled, setExpensesEnabled] = useState<boolean>(accountExpenseCategories !== '');
	const [incomesEnabled, setIncomesEnabled] = useState<boolean>(accountIncomeCategories !== '');

	const [expenseCategories, setExpenseCategories] = useState<string>('');
	const [incomeCategories, setIncomeCategories] = useState<string>('');

	useEffect(() => {
		setExpensesEnabled(accountExpenseCategories !== '');
		setIncomesEnabled(accountIncomeCategories !== '');
		setExpenseCategories(accountExpenseCategories);
		setIncomeCategories(accountIncomeCategories);
	}, [accountExpenseCategories, accountIncomeCategories, setExpenseCategories, setIncomeCategories]);

	const onExpenseCategoriesChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
		setExpenseCategories(event.target.value);
	}, [setExpenseCategories]);

	const onIncomeCategoriesChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
		setIncomeCategories(event.target.value);
	}, [setIncomeCategories]);

	const onSubmit = useCallback(() => {
		const data = {
			accountExpenseCategories: expensesEnabled ? expenseCategories : '',
			accountIncomeCategories: incomesEnabled ? incomeCategories : '',
		};

		categoriesPut.mutate(data);
	}, [
		expensesEnabled,
		incomesEnabled,
		expenseCategories,
		incomeCategories,
		categoriesPut,
	]);

	const fieldDisabled = useMemo(() => {
		return role === 'view';
	}, [role]);

	return (
		<RouteContainer title={t('categories.title')} isLoading={isLoadingAccountInfo}>
			<div className={'categories__content'}>
				<Alert
					message={t('categories.info-title')}
					description={t('categories.info-text')}
					type={'info'}
					className={'categories__info'}
				/>

				<div className={'categories__cards'}>
					<ContentCard title={t('categories.expenses.title')}>
						<Text strong>{t('categories.expenses.usage')}</Text>
						<Select
							className={'categories__cards__select'}
							onChange={setExpensesEnabled}
							defaultValue={expensesEnabled}
							value={expensesEnabled}
							disabled={fieldDisabled}
							options={[
								{value: true, label: t('categories.expenses.usage-enabled')},
								{value: false, label: t('categories.expenses.usage-disabled')},
							]}
						/>
						{expensesEnabled &&
							<TextArea
								disabled={fieldDisabled}
								placeholder={t('categories.expenses.input-field')}
								className={'categories__cards__textarea'}
								value={expenseCategories}
								onChange={onExpenseCategoriesChange}
							/>
						}
					</ContentCard>

					<ContentCard title={t('categories.incomes.title')}>
						<Text strong>{t('categories.incomes.usage')}</Text>
						<Select
							className={'categories__cards__select'}
							onChange={setIncomesEnabled}
							defaultValue={incomesEnabled}
							value={incomesEnabled}
							disabled={fieldDisabled}
							options={[
								{value: true, label: t('categories.incomes.usage-enabled')},
								{value: false, label: t('categories.incomes.usage-disabled')},
							]}
						/>
						{incomesEnabled &&
							<TextArea
								disabled={fieldDisabled}
								placeholder={t('categories.incomes.input-field')}
								className={'categories__cards__textarea'}
								value={incomeCategories}
								onChange={onIncomeCategoriesChange}
							/>
						}
					</ContentCard>

					{role === 'admin' && (
						<ContentCard className={'categories__cards__save'}>
							<Button onClick={onSubmit} type={'primary'} loading={isLoading}>{t('categories.save')}</Button>
						</ContentCard>
					)}
				</div>

			</div>

		</RouteContainer>
	);
};

export default Categories;