import React from 'react';
import {Divider} from 'antd';

type Props = {
	info: string;
};

const SectionInfo = ({info}: Props) => {
	return (
		<div>
			<p className={'createaccount__form__info'}>{info}</p>
			<Divider/>
		</div>
	);
};

export default SectionInfo;