import React, {useEffect, useRef} from 'react';

type Props = {
	children: React.ReactNode;
	setDivHeight: (height: number) => void;
};

const HeightObserver = ({children, setDivHeight}: Props) => {
	const ref = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		if (!ref.current) return;

		const resizeObserver = new ResizeObserver(() => {
			if (ref.current?.clientHeight !== undefined) {
				setDivHeight(ref.current?.clientHeight || 0);
			}
		});
		resizeObserver.observe(ref.current);
		return () => resizeObserver.disconnect(); // clean up
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div ref={ref}>
			{children}
		</div>
	);
};

export default HeightObserver;