import React, {useMemo, useState, useCallback, useEffect} from 'react';
import {useTranslation, Trans} from 'react-i18next';
import {Button, Modal, Form, Typography, Input as AntdInput} from 'antd';
import {Link} from 'react-router-dom';

import ContentCard from '../../../components/ContentCard';
import {useGetAccountInfo} from '../../../queries/useGetAccountInfo';
import {useUpdateAccountInfo} from '../../../queries/useUpdateAccountInfo';
import {useUserRole} from '../../../utils/useUserRole';
import {useMessage} from '../../../utils/useMessage';
import Input from '../../../components/Input';
import Table, {Rows} from './Table';
import {formatIban} from '../../../utils/formatIban';

const {Text} = Typography;
const {TextArea} = AntdInput;

type FormPasswordRowProps = {
	value: string | null;
};

const FormPasswordRow = ({value}: FormPasswordRowProps) => {
	const {t} = useTranslation();

	return (
		<>
			<Text>{value || t('account.information.field-empty')}</Text>
			<Text italic type={'secondary'}>
				<Trans
					i18nKey={'account.information.form-password-info-text'}
					components={{
						info: <Link to={'https://kululaskut.fi/tuki/'} target={'_blank'}></Link>,
					}}
				/>
			</Text>
		</>
	);
};

const AccountInformation = () => {
	const {t} = useTranslation();
	const [form] = Form.useForm();
	const message = useMessage();

	const [editModalVisible, setEditModalVisible] = useState<boolean>(false);

	const {data} = useGetAccountInfo();
	const {isLoading, ...updateAccountInfo} = useUpdateAccountInfo();
	const {role} = useUserRole();

	const onEdit = useCallback(() => setEditModalVisible(true), [setEditModalVisible]);
	const onClose = useCallback(() => setEditModalVisible(false), [setEditModalVisible]);

	const formattedIban = useMemo(() => {
		return formatIban(data.accountIban);
	}, [data]);

	useEffect(() => {
		form.setFieldValue('accountName', data.accountName || '');
		form.setFieldValue('accountBusinessId', data.accountBusinessId || '');
		form.setFieldValue('accountIban', formattedIban || '');
		form.setFieldValue('accountHelpText', data.accountHelpText || '');
		form.setFieldValue('accountFormPassword', data.accountFormPassword || '');
	}, [data, form, formattedIban]);

	const rows: Rows = useMemo(() => {
		const formLink = `https://${data.accountSlug}.kululaskut.fi`;
		return [
			[{value: t('account.information.name'), type: 'text'}, {value: data.accountName, type: 'text'}],
			[{value: t('account.information.business-id'), type: 'text'}, {value: data.accountBusinessId || t('account.information.field-empty'), type: 'text'}],
			[{value: t('account.information.iban'), type: 'text'}, {value: formattedIban || t('account.information.field-empty'), type: 'text'}],
			[{value: t('account.information.form-link'), type: 'text'}, {value: formLink.replace('https://', ''), link: formLink, type: 'link'}],
			[{value: t('account.information.form-help-text'), type: 'text'}, {value: data.accountHelpText || t('account.information.field-empty'), type: 'text'}],
			[{value: t('account.information.form-password'), type: 'text'}, {value: () => <FormPasswordRow value={data.accountFormPassword} />, type: 'node', containerClass: 'formpassword'}],
		];
	}, [data, formattedIban, t]);

	const onSubmit = useCallback(async () => {
		try {
			await form.validateFields();
		} catch (e) {
			return message.open({
				type: 'error',
				content: t('account.information.validation-error'),
				duration: 5,
			});
		}

		const data = form.getFieldsValue(true);

		updateAccountInfo.mutate(data, {
			onSuccess: onClose,
		});
	}, [form, updateAccountInfo, message, onClose, t]);

	return (
		<ContentCard title={t('account.information.title')}>
			<Modal
				open={editModalVisible}
				onOk={onSubmit}
				okButtonProps={{loading: isLoading}}
				okText={t('account.information.save')}
				onCancel={onClose}
				cancelText={t('account.information.cancel')}
				forceRender={true}
			>
				<Form form={form} className={'accountinformation__information__edit'}>
					<Form.Item
						name={'accountName'}
						required={true}
						rules={[{required: true, message: t('account.information.name-not-valid')}]}
					>
						<Input
							label={t('account.information.name')}
						/>
					</Form.Item>
					<Form.Item name={'accountBusinessId'}>
						<Input label={t('account.information.business-id')} />
					</Form.Item>
					<Form.Item name={'accountIban'} className={'accountinformation__information__edit__iban'}>
						<Input label={t('account.information.iban')} />
					</Form.Item>
					<Text italic type={'secondary'}>{t('account.information.no-iban-info')}</Text>

					<Text strong>{t('account.information.form-help-text')}</Text>
					<Form.Item name={'accountHelpText'} className={'accountinformation__information__edit__accounthelptext'}>
						<TextArea />
					</Form.Item>

					<Form.Item name={'accountFormPassword'} className={'accountinformation__information__edit__formpassword'}>
						<Input label={t('account.information.form-password')} />
					</Form.Item>
					<Text italic type={'secondary'}>
						<Trans
							i18nKey={'account.information.form-password-info-text'}
							components={{
								info: <Link to={'https://kululaskut.fi/tuki/'} target={'_blank'}></Link>,
							}}
						/>
					</Text>
				</Form>
			</Modal>

			<Table rows={rows} />
			{role === 'admin' && (
				<Button type={'primary'} className={'accountinformation__information__button'} onClick={onEdit}>
					{t('account.information.edit')}
				</Button>
			)}
		</ContentCard>
	);
};

export default AccountInformation;