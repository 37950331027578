import {UseMutationResult, useMutation} from '@tanstack/react-query';
import {AxiosError, AxiosResponse} from 'axios';
import {useTranslation} from 'react-i18next';

import Api from './api';
import {useLoadingMessage} from '../utils/useMessage';

type ChangePasswordData = {
	oldPassword: string;
	newPassword: string;
};

type ChangePasswordResult = {
	status: 'success';
};

const changePassword = async (body: ChangePasswordData) => {
	const data: AxiosResponse<ChangePasswordResult> = await Api.put(
		'/user/change-password',
		body,
		{withCredentials: true}
	);
	return data;
};

export const useUpdateUserPassword = (): UseMutationResult<AxiosResponse<ChangePasswordResult>, unknown, ChangePasswordData, unknown> => {
	const {t} = useTranslation();
	const loading = useLoadingMessage();

	return useMutation({
		mutationFn: (data) => {
			loading.start();
			return changePassword(data);
		},
		onSuccess: () => {
			loading.success(t('user-information.change-password.password-changed'));
		},
		onError: (err) => {
			console.error(err);
			if (err instanceof AxiosError) {
				if (err.response?.status === 403) {
					return loading.error(t('user-information.change-password.old-password-not-correct'));
				}
			}
			loading.error();
		}
	});
};