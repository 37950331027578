import validator from 'validator';

enum BIC {
	Aktia = 'HELSIFIHH',
	Bigbank = 'BIGKFIH1',
	POP = 'POPFFI22',
	Citibank = 'CITIFIHX',
	Danske = 'DABAFIHH',
	DNB = 'DNBAFIHX',
	Handelsbanken = 'HANDFIHH',
	Holvi = 'HOLVFIHH',
	Nordea = 'NDEAFIHH',
	OP = 'OKOYFIHH',
	SEB = 'ESSEFIHX',
	SPankki = 'SBANFIHH',
	Swedbank = 'SWEDFIHH',
	SP = 'ITELFIHH',
	VivaPaymentServices = 'VPAYFIH2',
	Alandsbanken = 'AABAFI22',
	FellowBank = 'EVSEFIHH',
	NarviPayments = 'NARYFIH2',
}

const bicNumbers: {[key in BIC]: Array<number[]>} = {
	[BIC.Nordea]: [[100, 299]],
	[BIC.Handelsbanken]: [[310, 319]],
	[BIC.SEB]: [[330, 339]],
	[BIC.Danske]: [[340, 349], [800, 899]],
	[BIC.SPankki]: [[360, 369]],
	[BIC.DNB]: [[370,  379], [390, 399]],
	[BIC.Swedbank]: [[380, 389]],
	[BIC.SP]: [
		[400, 400],
		[402, 403],
		[406, 408],
		[410, 412],
		[414, 421],
		[423, 432],
		[435, 452],
		[454, 464],
		[483, 493],
		[495, 496],
		[715, 715]
	],
	[BIC.Aktia]: [[405, 405], [497, 497]],
	[BIC.POP]: [[470, 479]],
	[BIC.OP]: [[500, 599]],
	[BIC.Alandsbanken]: [[600, 699]],
	[BIC.Citibank]: [[713, 713]],
	[BIC.FellowBank]: [[714, 714]],
	[BIC.Bigbank]: [[717, 717]],
	[BIC.NarviPayments]: [[796, 796]],
	[BIC.VivaPaymentServices]: [[798, 798]],
	[BIC.Holvi]: [[799, 799]],
};

const getBicNumbers = (iban: string): number => {
	return Number(iban.slice(4, 7));
};

// Function uses this logic
// https://www.finanssiala.fi/julkaisut/suomalaiset-rahalaitostunnukset-ja-bic-koodit/
// (see excel file in above link)
// https://www.finanssiala.fi/wp-content/uploads/2022/11/suomalaiset-rahalaitostunnukset-ja-bic.pdf
export const getBicFromIban = (iban: string): BIC | null => {
	const formattedIban = iban.replace(/ /g, '').toUpperCase();

	if (!formattedIban.includes('FI') || !validator.isIBAN(formattedIban)) return null;

	const num = getBicNumbers(formattedIban);

	const bic = Object.entries(bicNumbers).find(([, values]) => {
		const findInnerArrays = values.find(([start, end]) => {
			return start <= num && num <= end;
		});
		if (findInnerArrays) return true;
		return false;
	});

	if (bic) {
		return bic[0] as BIC;
	}

	return null;
};
