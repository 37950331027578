import {useCallback, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {refNum} from '../../utils/refNum';

const PageTitle = () => {
	const {t} = useTranslation();
	const {pathname} = useLocation();

	const generateTitle = useCallback((pathname: string): string => {
		if (pathname === '/') return 'Kululaskut.fi';

		// Different handling for bills
		const match = pathname.match(/\/bills\/(\d+)/);
		if (match && match.length >= 2) {
			const ref = refNum(Number(match[1]));
			return t('page-titles.bill-number', {id: ref});
		}

		// Different handling for locked bill view
		if (pathname.includes('/bills/locked/')) {
			return t('page-titles.bills-locked');
		}

		// Different handling for mileage edit modal
		if (pathname.includes('/mileages/edit/')) {
			return t('page-titles.mileages');
		}

		const transKey = pathname.split('/').filter(x => x).join('-');
		return t(`page-titles.${transKey}`);
	}, [t]);

	useEffect(() => {
		document.title = generateTitle(pathname);
	}, [generateTitle, pathname]);

	return null;
};

export default PageTitle;