import React, {useCallback, useState, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Alert, Typography, Form, Popconfirm, Input} from 'antd';
import {useNavigate} from 'react-router-dom';

import Icon from './Icon';
import ComponentCard from '../../../components/ContentCard';
import {useDeleteBillById} from '../../../queries/useDeleteBillById';
import {useReturnBillToUser} from '../../../queries/useReturnBillToUser';
import {useArchiveBill} from '../../../queries/useArchiveBill';
import {useUserRole} from '../../../utils/useUserRole';
import {BillStatus} from '../../../queries/useGetBills';
import {refNum} from '../../../utils/refNum';

const {Text} = Typography;
const {TextArea} = Input;

const InfoContent = () => {
	const {t} = useTranslation();

	return (
		<div className={'viewbill__steps__card__actions__info'}>
			<div className={'viewbill__steps__card__actions__info__row'}>
				<Text strong>{t('bill.view.actions.info.accept-label')}</Text>
				<Text>{t('bill.view.actions.info.accept-text')}</Text>
			</div>
			<div className={'viewbill__steps__card__actions__info__row'}>
				<Text strong>{t('bill.view.actions.info.send-back-label')}</Text>
				<Text>{t('bill.view.actions.info.send-back-text')}</Text>
			</div>
			<div className={'viewbill__steps__card__actions__info__row'}>
				<Text strong>{t('bill.view.actions.info.edit-label')}</Text>
				<Text>{t('bill.view.actions.info.edit-text')}</Text>
			</div>
			<div className={'viewbill__steps__card__actions__info__row'}>
				<Text strong>{t('bill.view.actions.info.delete-label')}</Text>
				<Text>{t('bill.view.actions.info.delete-text')}</Text>
			</div>
		</div>
	);
};

const buttonsToRender = (billStatus: BillStatus | null): string[] => {
	if (!billStatus) return [];
	if (billStatus === BillStatus.sent) {
		['accept', 'send-back', 'edit', 'delete'];
	}
	if (billStatus === BillStatus.edit) {
		return ['edit', 'delete'];
	}
	if (billStatus === BillStatus.archive) {
		return ['edit', 'delete'];
	}
	return ['accept', 'send-back', 'edit', 'delete'];
};

type Props = {
	billId: number;
	billStatus: BillStatus | null;
	edit: boolean;
	onEdit: () => void;
	onCancelEdit: () => void;
}

const ActionCard = ({billId, billStatus, edit, onEdit, onCancelEdit}: Props) => {
	const {t} = useTranslation();
	const navigate = useNavigate();

	const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false);
	const [sendBackFieldsOpen, setSendBackFieldsOpen] = useState<boolean>(false);
	const [sendBackDescription, setSendBackDescription] = useState<string>('');

	const {isLoading: isLoadingDelete, ...deleteBillById} = useDeleteBillById();
	const {isLoading: isLoadingReturnBillToUser, ...returnBillToUser} = useReturnBillToUser();
	const {isLoading: isLoadingArchiveBill, ...archiveBill} = useArchiveBill();

	const {role} = useUserRole();

	const onOpenDeleteConfirm = useCallback(() => setDeleteConfirmOpen(true), [setDeleteConfirmOpen]);
	const onCloseDeleteConfirm = useCallback(() => setDeleteConfirmOpen(false), [setDeleteConfirmOpen]);
	const onSendBackDetails = useCallback(() => setSendBackFieldsOpen(true), [setSendBackFieldsOpen]);
	const closeSendBackDetails = useCallback(() => setSendBackFieldsOpen(false), [setSendBackFieldsOpen]);
	const onSendBackDescriptionChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
		setSendBackDescription(event.target.value);
	}, [setSendBackDescription]);

	const onArchive = useCallback(() => {
		archiveBill.mutate({billId});
	}, [archiveBill, billId]);

	const onArchiveAndReturnToList = useCallback(async () => {
		await archiveBill.mutateAsync({billId});
		navigate('/bills/new');
	}, [archiveBill, billId, navigate]);

	const onDelete = useCallback(() => {
		deleteBillById.mutate({billId}, {
			onSuccess: () => {
				navigate(-1);
				onCloseDeleteConfirm();
			},
		});
	}, [billId, deleteBillById, navigate, onCloseDeleteConfirm]);

	const onSendBack = useCallback(() => {
		returnBillToUser.mutate({billId, description: sendBackDescription}, {
			onSuccess: closeSendBackDetails,
		});
	}, [billId, returnBillToUser, closeSendBackDetails, sendBackDescription]);

	const buttons = useMemo(() => buttonsToRender(billStatus), [billStatus]);

	const billRefNum = useMemo(() => refNum(billId), [billId]);

	return (
		<div className={'viewbill__steps__card'}>
			<Icon status={'success'} />

			<ComponentCard>
				<div className={'viewbill__steps__card__actions'}>
					{role === 'view' || billStatus === BillStatus.locked ? (
						<Text>{billStatus === BillStatus.locked ? t('bill.view.actions.bill-locked-info') : t('bill.view.actions.no-rights-for-actions')}</Text>
					) : (
						<>
							{edit ? (
								<div className={'viewbill__steps__card__actions__buttons'}>
									<Form.Item name={'submit-edit'} className={'viewbill__steps__formitem button'}>
										<Button htmlType={'submit'} type={'primary'}>
											{t('bill.view.actions.save')}
										</Button>
									</Form.Item>
									<Button onClick={onCancelEdit} type={'primary'} ghost>
										{t('bill.view.actions.cancel-save')}
									</Button>
								</div>
							) : (
								<>
									<div className={'viewbill__steps__card__actions__buttons'}>
										{buttons.includes('accept') &&
											<>
												<Button
													onClick={onArchive}
													type={'primary'}
													className={'button__accept'}
													loading={isLoadingArchiveBill}
												>
													{t('bill.view.actions.accept')}
												</Button>
												<Button
													onClick={onArchiveAndReturnToList}
													className={'button__acceptandreturntolist'}
													loading={isLoadingArchiveBill}
												>
													{t('bill.view.actions.accept-and-return-to-list')}
												</Button>
											</>
										}
										{buttons.includes('send-back') &&
											<Button onClick={onSendBackDetails} type={'primary'}>
												{t('bill.view.actions.send-back')}
											</Button>
										}
										{buttons.includes('edit') &&
											<Button onClick={onEdit} className={'button__edit'} type={'primary'}>
												{t('bill.view.actions.edit')}
											</Button>
										}
										{buttons.includes('delete') &&
											<Popconfirm
												placement={'topRight'}
												open={deleteConfirmOpen}
												title={t('bill.view.actions.delete-verify', {num: billRefNum || billId})}
												okText={t('bill.view.actions.delete-yes')}
												onConfirm={onDelete}
												okButtonProps={{danger: true, loading: isLoadingDelete}}
												cancelText={t('bill.view.actions.delete-no')}
												onCancel={onCloseDeleteConfirm}
											>
												<Button onClick={onOpenDeleteConfirm} type={'primary'} danger loading={isLoadingDelete}>
													{t('bill.view.actions.delete')}
												</Button>
											</Popconfirm>
										}
									</div>

									<div className={`viewbill__steps__card__actions__sendback ${sendBackFieldsOpen ? 'visible' : 'hidden'}`}>
										<Text strong>{t('bill.view.actions.send-back-description-label')}</Text>
										<TextArea value={sendBackDescription} onChange={onSendBackDescriptionChange} placeholder={t('bill.view.actions.send-back-description-placeholder')} />
										<Button onClick={onSendBack} loading={isLoadingReturnBillToUser}>
											{t('bill.view.actions.send-back-save')}
										</Button>
									</div>

									<Alert type={'info'} message={t('bill.view.actions.info.title')} description={<InfoContent />} />
								</>
							)}
						</>
					)}
				</div>
			</ComponentCard>
		</div>
	);
};

export default ActionCard;