import React, {useMemo, useCallback, useState} from 'react';
import {Form, Steps, StepProps} from 'antd';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {FormFinishInfo} from 'rc-field-form/lib/FormContext';

import {FormData} from './types';
import OrganisationInformation from './Components/Sections/OrganisationInformation';
import InvoicingInformation from './Components/Sections/InvoicingInformation';
import UserInformation from './Components/Sections/UserInformation';
import RegistrationInformation from './Components/Sections/RegistrationInformation';
import Summary from './Components/Sections/Summary';
import Confirmation from './Components/Sections/Confirmation';
import ContentCard from '../../components/ContentCard';
import Carousel from './Components/Carousel';
import LanguageSelect from '../../components/MenuWrapper/components/LanguageSelect';
import {useWindowDimensions, windowBreakpoints} from '../../utils/screenSizes';

const CreateAccount = () => {
	const {t} = useTranslation();
	const [currentStep, setCurrentStep] = useState<number>(0);
	const {width: screenWidth} = useWindowDimensions();

	const [userHasToRegister, setUserHasToRegister] = useState<boolean>(true);
	const [formData, setFormData] = useState<FormData | null>(null);

	const nextStep = useCallback((skip?: number) => {
		setCurrentStep(currentStep + 1 + (skip || 0));
	}, [currentStep]);

	const previousStep = useCallback((skip?: number) => {
		setCurrentStep(currentStep - 1 - (skip || 0));
	}, [currentStep]);

	const items: StepProps[] = useMemo(() => {
		return [
			{title: t('create-account.organisation.title')},
			{title: t('create-account.invoicing.title')},
			{title: t('create-account.user.title')},
		];
	}, [t]);

	const stepsDirection = useMemo(() => {
		if (screenWidth <= windowBreakpoints.small) return 'vertical';
		return 'horizontal';
	}, [screenWidth]);

	const handleFormSubmit = useCallback(async (name: string, {forms}: FormFinishInfo) => {
		const organisation = forms.organisation.getFieldsValue(true);
		const invoicing = forms.invoicing.getFieldsValue(true);
		const user = forms.user.getFieldsValue(true);
		const register = forms.register?.getFieldsValue(true) || {};

		setFormData({organisation, invoicing, user, register});
	}, []);

	return (
		<div className={'createaccount'}>
			<div className={'createaccount__languageselect'}>
				<LanguageSelect />
			</div>

			<div className={'createaccount__logo'}>
				<img src={'/kululaskut_logo.png'} />
			</div>
			<div className={'createaccount__container'}>
				<ContentCard className={'createaccount__content'}>
					<div className={'createaccount__steps'}>
						<Steps
							current={currentStep === 3 && userHasToRegister ? 2 : currentStep}
							items={items}
							direction={stepsDirection}
						/>
					</div>
					<Form.Provider onFormFinish={handleFormSubmit}>
						<div className={'createaccount__carousel__provider'}>
							<Carousel currentIndex={currentStep}>
								<OrganisationInformation
									nextStep={nextStep}
									focused={currentStep === 0}
								/>
								<InvoicingInformation
									nextStep={nextStep}
									previousStep={previousStep}
									focused={currentStep === 1}
								/>
								<UserInformation
									focused={currentStep === 2}
									nextStep={nextStep}
									previousStep={previousStep}
									setUserHasToRegister={setUserHasToRegister}
								/>
								<RegistrationInformation
									userHasToRegister={userHasToRegister}
									focused={currentStep === 3}
									nextStep={nextStep}
									previousStep={previousStep}
								/>
								<Summary
									formData={formData}
									userHasToRegister={userHasToRegister}
									focused={currentStep === 4}
									nextStep={nextStep}
									previousStep={previousStep}
								/>
								<Confirmation
									focused={currentStep === 5}
									newUser={userHasToRegister}
								/>
							</Carousel>
						</div>
					</Form.Provider>
				</ContentCard>
			</div>
			<Link to="/">{t('create-account.back-to-login')}</Link>
		</div>
	);
};

export default CreateAccount;