import {UseMutationResult, useMutation, useQueryClient} from '@tanstack/react-query';
import {AxiosError, AxiosResponse} from 'axios';
import {useTranslation} from 'react-i18next';

import Api from './api';
import {useLoadingMessage} from '../utils/useMessage';
import {emptyData, UserInfoData} from './useGetUserInfo';

type UpdateUserEmailData = {
	email: string;
	password: string;
};

type UpdateUserEmailDataResult = {
	userEmail: string;
	userEmailNotifications: number;
	userEmailNotificationsArchive: number;
	userId: number;
	userName: string;
};

const updateUserEmail = async (body: UpdateUserEmailData) => {
	const data: AxiosResponse<UpdateUserEmailDataResult> = await Api.put(
		'/user/update-email',
		body,
		{withCredentials: true}
	);
	return data;
};

export const useUpdateUserEmail = (): UseMutationResult<AxiosResponse<UpdateUserEmailDataResult>, unknown, UpdateUserEmailData, unknown> => {
	const {t} = useTranslation();
	const loading = useLoadingMessage();
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (data) => {
			loading.start();
			return updateUserEmail(data);
		},
		onSuccess: ({data}) => {
			queryClient.setQueryData(['user-info'],
				(oldData: UserInfoData | undefined) => {
					if (!oldData) return {...emptyData, ...data};
					return {...oldData, ...data};
				},
			);
			loading.success();
		},
		onError: (err) => {
			console.error(err);
			if (err instanceof AxiosError) {
				if (err.response?.status === 403) {
					return loading.error(t('user-information.change-email.password-not-correct'));
				}
			}
			loading.error();
		}
	});
};