import React, {useCallback, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {Form, Checkbox, Button, Typography} from 'antd';

import ContentCard from '../../../components/ContentCard';
import Input from '../../../components/Input';
import {useGetUserInfo} from '../../../queries/useGetUserInfo';
import {useUpdateUser} from '../../../queries/useUpdateUser';

const {Text} = Typography;

type FormValues = {
	name: string;
	userEmailNotifications: boolean;
	userEmailNotificationsArchive: boolean;
}

const ChangeInformation = () => {
	const {t} = useTranslation();
	const {data} = useGetUserInfo();
	const {isLoading, ...updateUser} = useUpdateUser();

	const [form] = Form.useForm();

	useEffect(() => {
		form.setFieldValue('name', data.userName || '');
		form.setFieldValue('userEmailNotifications', data.userEmailNotifications === 1);
		form.setFieldValue('userEmailNotificationsArchive', data.userEmailNotificationsArchive === 1);
	}, [data, form]);

	const onSubmit = useCallback((values: FormValues) => {
		updateUser.mutate(values);
	}, [updateUser]);

	return (
		<ContentCard title={t('user-information.change-information.title')}>
			<Form form={form} className={'userinformation__cards__form'} onFinish={onSubmit}>
				<Form.Item name={'name'} required={true} rules={[{required: true, message: t('user-information.change-information.name-not-valid')}]}>
					<Input
						label={t('user-information.change-information.name')}
						placeholder={t('user-information.change-information.name')}
						className={'userinformation__cards__input'}
					/>
				</Form.Item>

				<Form.Item name={'userEmailNotifications'} valuePropName={'checked'}>
					<Checkbox>
						<Text strong>{t('user-information.change-information.email-notification-new-bills')}</Text>
					</Checkbox>
				</Form.Item>

				<Form.Item name={'userEmailNotificationsArchive'} valuePropName={'checked'}>
					<Checkbox>
						<Text strong>{t('user-information.change-information.email-notification-accepted-bills')}</Text>
					</Checkbox>
				</Form.Item>

				<Form.Item name={'submit-info'} className={'userinformation__cards__submit'}>
					<Button htmlType={'submit'} type={'primary'} loading={isLoading}>
						{t('user-information.save-changes')}
					</Button>
				</Form.Item>
			</Form>
		</ContentCard>
	);
};

export default ChangeInformation;