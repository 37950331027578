import {useCallback} from 'react';
import {useQuery, useQueryClient} from '@tanstack/react-query';
import {ArgsProps} from 'antd/es/message';
import {useTranslation} from 'react-i18next';


type UseMessage = {
	options: ArgsProps | null;
	open: (options: ArgsProps) => void;
}

export const useMessage = (): UseMessage => {
	const queryClient = useQueryClient();

	const query = useQuery({
		queryKey: ['use-message'],
		queryFn: () => null,
		initialData: null,
		cacheTime: 0,
	});

	const open = useCallback((options: ArgsProps) => {
		// Very small timeout in order to prevent firing two setQueryDatas at the same time
		// This prevents this one being dismissed
		setTimeout(() => {
			queryClient.setQueryData(['use-message'], {key: Date.now(), ...options});
		}, 1);
	}, [queryClient]);

	return {
		options: query.data,
		open,
	};
};

type UseLoadingMessage = {
	start: () => void;
	success: (content?: string) => void;
	error: (content?: string) => void;
};

export const useLoadingMessage = (): UseLoadingMessage => {
	const {t} = useTranslation();
	const message = useMessage();

	const start = useCallback(() => {
		message.open({
			key: 'save',
			type: 'loading',
			content: t('message.saving-changes'),
		});
	}, [message, t]);

	const success = useCallback((content?: string) => {
		message.open({
			key: 'save',
			type: 'success',
			content: content || t('message.saved-changes'),
			duration: 4,
		});
	}, [message, t]);

	const error = useCallback((content?: string) => {
		message.open({
			key: 'save',
			type: 'error',
			content: content || t('errors.something-went-wrong'),
			duration: 5,
		});
	}, [message, t]);

	return {
		start,
		success,
		error,
	};
};