import {useGetUserInfo} from '../queries/useGetUserInfo';

type UserUserRole = {
	role: 'admin' | 'view';
	isLoading: boolean;
}

export const useUserRole = (): UserUserRole => {
	const {data, isLoading} = useGetUserInfo();

	const {accessLevel} = data;

	if (accessLevel === 1) {
		return {
			role: 'admin',
			isLoading,
		};
	}

	return {
		role: 'view',
		isLoading,
	};
};