import {useMemo} from 'react';
import {Navigate} from 'react-router-dom';
import {useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import FadeIn from '../FadeIn';
import {useLoginValidate} from '../../queries/useLoginValidate';
import {useIsLoggedIn} from '../../utils/useIsLoggedIn';
import {logOut} from '../../utils/logOut';
import {useMessage} from '../../utils/useMessage';

export const loginRoutes = ['/login', '/reset-password', '/register', '/set-password', '/activate-user'];

type Props = {
    children: React.ReactNode;
};

const JWTValidator = ({children}: Props) => {
	const {t} = useTranslation();
	const loggedIn = useIsLoggedIn();
	const {pathname} = useLocation();
	const message = useMessage();

	const {data, isError, isLoading} = useLoginValidate(loggedIn);
	const {authorized} = data;

	const doNothing = useMemo(() => {
		return pathname === '/create-account';
	}, [pathname]);

	const shouldMoveToApp = useMemo(() => {
		if (doNothing) return false;
		return loginRoutes.includes(pathname) && loggedIn && authorized && !isLoading;
	}, [doNothing, pathname, loggedIn, authorized, isLoading]);

	const shouldLogout = useMemo(() => {
		if (doNothing) return false;
		return !loginRoutes.includes(pathname) && (!loggedIn || (isError && !isLoading));
	}, [doNothing, pathname, loggedIn, isError, isLoading]);

	// Open the app, if user is authenticated and is in one of the login routes
	if (shouldMoveToApp) {
		return <Navigate replace to={'/'} />;
	}

	// Open login if user isn't authenticated and is trying to open something
	if (shouldLogout) {
		if (loggedIn) {
			logOut(true);
			message.open({
				type: 'error',
				content: t('errors.session-expired'),
			});
		}
		return <Navigate replace to={'/login'} />;
	}

	return (
		<FadeIn>
			{children}
		</FadeIn>
	);
};

export default JWTValidator;