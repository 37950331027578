import React, {useMemo, useCallback} from 'react';
import {Form, FormInstance} from 'antd';
import {useTranslation} from 'react-i18next';

import StepCard from './StepCards';
import ActionCard from './ActionCard';
import ImagePreview from './ImagePreview';
import {InvoiceType} from '../types';
import {BillStatus} from '../../../queries/useGetBills';
import {useImagePreview} from '../hooks/useImagePreview';

type Props = {
	invoice: InvoiceType;
	billStatus: BillStatus | null;
	edit: boolean;
	setEdit: (val: boolean) => void;
	// No type for form values since they are created dynamically
	// eslint-disable-next-line
	saveBill: (values: any) => void;
	form: FormInstance;
};

const Steps = ({invoice, billStatus, edit, setEdit, saveBill, form}: Props) => {
	const {t} = useTranslation();

	const {
		images,
		index: imageIndex,
		openImageModal,
		closeImageModal,
		changeImage,
	} = useImagePreview();

	const onEdit = useCallback(() => {
		form.resetFields();
		setEdit(true);
	}, [setEdit, form]);

	const onCancelEdit = useCallback(() => {
		setEdit(false);
		form.resetFields();
	}, [setEdit, form]);

	const validateMessages = useMemo(() => ({
		required: t('bill.view.edit-errors.field-required'),
		types: {
			email: t('bill.view.edit-errors.field-not-valid'),
		},
	}), [t]);

	return (
		<Form
			className={'viewbill__steps'}
			validateTrigger={'onBlur'}
			validateMessages={validateMessages}
			onFinish={saveBill}
			form={form}
		>
			<ImagePreview images={images} index={imageIndex} closeFileModal={closeImageModal} changeImage={changeImage} />

			<StepCard invoice={invoice} openFileModal={openImageModal} edit={edit} formInstance={form} />
			<ActionCard onEdit={onEdit} onCancelEdit={onCancelEdit} billId={invoice.id} edit={edit} billStatus={billStatus} />
		</Form>
	);
};

export default Steps;