import React, {useMemo} from 'react';
import {Breadcrumb as AntdBreadcrumb} from 'antd';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';

import {Bill} from '../../../queries/useGetBillById';

type Props = {
	billRefnum: string | number;
	data: Bill;
};

const Breadcrumb = ({billRefnum, data}: Props) => {
	const {t} = useTranslation();

	const [billStatus, billViewLink] = useMemo(() => {
		const {billStatus} = data;
		if (billStatus === 'sent' || billStatus === 'edit') return ['new', '/bills/new'];
		if (billStatus === 'archive') return ['archived', '/bills/archive'];
		return ['locked-accounting-periods', '/bills/locked'];
	}, [data]);

	const breadcumbItems = useMemo(() => {
		return [
			{title: t('bill.breadcrumb.bills')},
			{title: <Link to={billViewLink}>{t(`bill.breadcrumb.${billStatus}`)}</Link>},
			...(billStatus === 'locked-accounting-periods' && data.fiscalYearName ?
				[{title: <Link to={`/bills/locked/${data.fiscalYearId}`}>{data.fiscalYearName}</Link>}]
				:
				[]
			),
			{title: t('bill.breadcrumb.bill-id', {id: billRefnum})},
		];
	}, [t, data, billRefnum, billStatus, billViewLink]);

	return (
		<AntdBreadcrumb items={breadcumbItems} />
	);
};

export default Breadcrumb;