import {UseMutationResult, useMutation} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';

import Api from './api';

export type CheckExistingUserData = {
	email: string;
};

export type CheckExistingUserResponse = {
	existing: boolean;
}

const checkExistingUser = async (body: CheckExistingUserData) => {
	const data: AxiosResponse<CheckExistingUserResponse> = await Api.post(
		'/user/existing',
		body,
	);
	return data;
};

export const useCheckExistingUser = (): UseMutationResult<AxiosResponse<CheckExistingUserResponse>, unknown, CheckExistingUserData, unknown> => {
	return useMutation({
		mutationFn: (data) => {
			return checkExistingUser(data);
		},
	});
};