import {UseMutationResult, useMutation, useQueryClient} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';

import Api from './api';
import {useLoadingMessage} from '../utils/useMessage';
import {AccessesData} from './useGetAccesses';

type DeleteAccessData = {
	userId: number;
};

type DeleteAccessDataResult = {
	status: string;
	userId: number;
};

const deleteAccess = async ({userId}: DeleteAccessData) => {
	const data: AxiosResponse<DeleteAccessDataResult> = await Api.delete(`/accesses/${userId}`);
	return data;
};

export const useDeleteAccess = (): UseMutationResult<AxiosResponse<DeleteAccessDataResult>, unknown, DeleteAccessData, unknown> => {
	const queryClient = useQueryClient();
	const loading = useLoadingMessage();

	return useMutation({
		mutationFn: (data) => {
			loading.start();
			return deleteAccess(data);
		},
		onSuccess: ({data}) => {
			queryClient.setQueryData(['account-accesses'], (oldData: AccessesData[] | undefined) => {
				if (!oldData) {
					return [];
				}
				return [...oldData].filter((access) => access.userId !== data.userId);
			});
			loading.success();
		},
		onError: (err) => {
			console.error(err);
			loading.error();
		}
	});
};