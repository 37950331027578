import React, {useCallback, useMemo, useState} from 'react';
import {Select, Typography, Button} from 'antd';
import {useTranslation} from 'react-i18next';
import {Navigate} from 'react-router-dom';

import ContentCard from '../../../components/ContentCard';
import {useGetFiscalYears} from '../../../queries/useGetFiscalYears';

const {Text} = Typography;

type Props = {
	fiscalYear: number | null;
	setFiscalYear: (val: number | null) => void;
};

const LockedSelect = ({fiscalYear, setFiscalYear}: Props) => {
	const {t} = useTranslation();

	const [selectedYear, setSelectedYear] = useState<number | null>(fiscalYear);

	const {data, isLoading} = useGetFiscalYears();

	const options = useMemo(() => {
		return data.map((year) => ({
			value: year.yearId,
			label: year.yearName,
		}));
	}, [data]);

	const validFiscalYearId = useMemo(() => {
		if (fiscalYear !== null && !isLoading) {
			const yearIdList = data.map(({yearId}) => yearId);
			return yearIdList.includes(fiscalYear);
		}
		return true;
	}, [data, fiscalYear, isLoading]);

	const onSelect = useCallback((val: number) => {
		setSelectedYear(val);
		setFiscalYear(null);
	}, [setSelectedYear, setFiscalYear]);

	const onBrowse = useCallback(() => {
		setFiscalYear(selectedYear);
	}, [setFiscalYear, selectedYear]);

	if (!validFiscalYearId) {
		return <Navigate replace to={'/404'} />;
	}

	return (
		<ContentCard className={'bills__lockedselect'} title={t('bill.locked-select.title')}>
			{!isLoading && data.length === 0 ? (
				<Text italic>{t('bill.locked-select.no-fiscal-years')}</Text>
			) : (
				<>
					<Text>{t('bill.locked-select.label')}</Text>
					<Select
						className={'bills__lockedselect__select'}
						options={options}
						placeholder={t('bill.locked-select.select-placeholder')}
						defaultValue={fiscalYear}
						onSelect={onSelect}
					/>

					<Button
						type={'primary'}
						disabled={selectedYear === null}
						className={'bills__lockedselect__button'}
						onClick={onBrowse}
					>
						{t('bill.locked-select.browse')}
					</Button>
				</>
			)}
		</ContentCard>
	);
};

export default LockedSelect;