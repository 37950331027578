import {UseMutationResult, useMutation} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';

import Api from './api';
import {useLoadingMessage} from '../utils/useMessage';
import {Language} from './types';

export type RegisterUser = {
	email: string;
	name: string;
	password: string;
	recaptchaToken: string;
	userLanguage: Language;
};

type RegisterUserResponse = {
	status: 'success',
};

const registerUser = async (body: RegisterUser) => {
	const data: AxiosResponse<RegisterUserResponse> = await Api.post(
		'/user/register',
		body,
	);
	return data;
};

export const useRegisterUser = (): UseMutationResult<AxiosResponse<RegisterUserResponse>, unknown, RegisterUser, unknown> => {
	const loading = useLoadingMessage();

	return useMutation({
		mutationFn: (data) => {
			return registerUser(data);
		},
		onError: (err) => {
			console.error(err);
			loading.error();
		}
	});
};