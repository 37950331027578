import React, {useState, useEffect, useMemo, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowUpRightFromSquare, faBars} from '@fortawesome/free-solid-svg-icons';
import {QuestionCircleOutlined} from '@ant-design/icons';
import {Menu as AntdMenu, Button, Divider} from 'antd';
import type {MenuInfo} from 'rc-menu/lib/interface';

import UserInfo from './components/UserInfo';
import LanguageSelect from './components/LanguageSelect';
import {useWindowDimensions, windowBreakpoints} from '../../utils/screenSizes';
import {useMenuContent} from './content';
import {useGetAccountInfo} from '../../queries/useGetAccountInfo';
import {useUpdateUserActiveAccount} from '../../queries/useUpdateUserActiveAccount';
import {logOut} from '../../utils/logOut';
import {useMessage} from '../../utils/useMessage';
import {useUserRole} from '../../utils/useUserRole';
import {loginRoutes} from '../JWTValidator';

type Props = {
    children: React.ReactNode;
};

const Menu = ({children}: Props) => {
	const {t} = useTranslation();
	const {pathname} = useLocation();
	const navigate = useNavigate();

	const message = useMessage();
	const {width: screenWidth} = useWindowDimensions();
	const [menuOpen, setMenuOpen] = useState<boolean>(screenWidth > windowBreakpoints.medium + 220);

	const {data: accountInfo} = useGetAccountInfo();
	const updateUserActiveAccount = useUpdateUserActiveAccount();
	const {role} = useUserRole();

	const menuContent = useMenuContent(role === 'admin');

	// Listen for screen size changes and open/close menu based on the screen size
	useEffect(() => {
		if (screenWidth <= windowBreakpoints.small + 230) return setMenuOpen(false);
		return setMenuOpen(true);
	}, [screenWidth, setMenuOpen]);

	const openPublicForm = useCallback(() => {
		if (accountInfo.accountSlug) return window.open(`https://${accountInfo.accountSlug}.kululaskut.fi/`);
		return message.open({
			type: 'error',
			content: t('errors.something-went-wrong'),
			duration: 5,
		});
	}, [accountInfo, message, t]);

	const onChangeAccount = useCallback((accountId: number) => {
		updateUserActiveAccount.mutate({accountId}, {
			onSuccess: () => {
				location.replace('/bills/new');
			},
		});
	}, [updateUserActiveAccount]);

	const toggleMenu = useCallback(() => setMenuOpen(!menuOpen), [setMenuOpen, menuOpen]);

	const shouldHideMenu = useMemo(() => {
		return loginRoutes.includes(pathname) || pathname === '/create-account';
	}, [pathname]);

	const onClick = useCallback(({key}: MenuInfo) => {
		// Close menu when it is positioned on top of the content
		if (screenWidth <= windowBreakpoints.small + 220) {
			setMenuOpen(false);
		}
		if (key === '/logout') {
			return logOut();
		}
		navigate(key);
	}, [screenWidth, setMenuOpen, navigate]);

	// Hide menu wrapper if user is on login page
	if (shouldHideMenu) {
		return <>{children}</>;
	}

	return (
		<>
			<div className={'menu__topbar'}>
				<div className={'menu__topbar__logo'}>
					<img className={'menu__topbar__logo'} src={'/kululaskut_logo.png'} />
				</div>
				<div className={'menu__topbar__iconbutton'} onClick={toggleMenu}>
					<FontAwesomeIcon icon={faBars} size={'xl'} />
				</div>
				<LanguageSelect />
				<div className={'menu__topbar__openform'} onClick={openPublicForm}>
					<FontAwesomeIcon icon={faArrowUpRightFromSquare} />
					{t('go-to-public-form')}
				</div>
			</div>

			<div className={'menu__bottomsection'}>
				<div className={`menu__sidebar ${menuOpen ? 'open' : 'closed'}`}>
					<UserInfo onChangeAccount={onChangeAccount} />
					<Divider />
					<AntdMenu
						selectedKeys={menuContent.selectedKeys}
						className={'menu__sidebar__antd'}
						onClick={onClick}
						defaultOpenKeys={menuContent.defaultOpenKeys}
						mode={'inline'}
						items={menuContent.items}
					/>
					<Divider />
					<Button type={'link'} icon={<QuestionCircleOutlined />} onClick={() => window.open('https://kululaskut.fi/tuki', '_blank')}>
						{t('menu.support')}
					</Button>
				</div>
				<div className={'menu__appcontent'}>
					{children}
				</div>
			</div>
		</>
	);
};

export default Menu;