import {UseMutationResult, useMutation} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';

import Api from './api';

export type CheckAccountSlugData = {
	accountSlug: string;
};

export type CheckAccoutSlugResponse = {
	reserved: boolean;
}

const checkAccountSlug = async (body: CheckAccountSlugData) => {
	const data: AxiosResponse<CheckAccoutSlugResponse> = await Api.post(
		'/account/check-slug',
		body,
	);
	return data;
};

export const useCheckAccountSlug = (): UseMutationResult<AxiosResponse<CheckAccoutSlugResponse>, unknown, CheckAccountSlugData, unknown> => {
	return useMutation({
		mutationFn: (data) => {
			return checkAccountSlug(data);
		},
	});
};