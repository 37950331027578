import {UseMutationResult, useMutation, useQueryClient} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';
import {useTranslation} from 'react-i18next';

import Api from './api';
import {useLoadingMessage} from '../utils/useMessage';
import {Bill, emptyData} from './useGetBillById';

export type ArchiveBillData = {
	billId: number;
};

const archiveBill = async (body: ArchiveBillData) => {
	const {billId} = body;
	const data: AxiosResponse<Bill> = await Api.put(
		`/bill/archive/${billId}`,
		{},
	);
	return data;
};

export const useArchiveBill = (): UseMutationResult<AxiosResponse<Bill>, unknown, ArchiveBillData, unknown> => {
	const loading = useLoadingMessage();
	const {t} = useTranslation();
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (data) => {
			loading.start();
			return archiveBill(data);
		},
		onSuccess: ({data}) => {
			queryClient.setQueryData(['bill', data.billId],
				(oldData: Bill | undefined) => {
					if (!oldData) return {...emptyData};
					return {
						...oldData,
						...data,
					};
				},
			);
			loading.success(t('bill.view.actions.bill-accepted'));
		},
		onError: (err) => {
			console.error(err);
			loading.error();
		}
	});
};