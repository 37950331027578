import React from 'react';
import {Trans} from 'react-i18next';

type Props = {
	translationKey: string;
}

const TranslatedText = ({translationKey}: Props) => {
	return (
		<Trans
			i18nKey={translationKey}
		/>
	);
};

export default TranslatedText;