import React, {useCallback, useMemo} from 'react';
import {Button, Modal} from 'antd';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMagnifyingGlass,faDownload, faArrowLeft, faArrowRight} from '@fortawesome/free-solid-svg-icons';

import {downloadFile} from '../../../utils/downloadFile';

type Props = {
	images: string[] | false;
	index: number | null;
	closeFileModal: () => void;
	changeImage: {
		canGoPrevious: boolean;
		canGoNext: boolean;
		goPrevious: () => void;
		goNext: () => void;
	};
}

const ImagePreview = ({images, index, closeFileModal, changeImage}: Props) => {
	const {canGoPrevious, canGoNext, goPrevious, goNext} = changeImage;
	const {t} = useTranslation();

	const image = useMemo(() => {
		if (images === false || index === null) return null;
		return images[index];
	}, [images, index]);

	const openFile = useCallback(() => {
		if (image === null) return;
		window.open(image);
	}, [image]);

	return (
		<Modal
			open={image !== null}
			footer={null}
			onCancel={closeFileModal}
		>
			<div className={'viewbill__steps__filemodal'}>
				{image && <img src={image} key={image} />}
				<div>
					<div className={'viewbill__steps__filemodal__nextprev'}>
						<Button type={'dashed'} onClick={goPrevious} disabled={!canGoPrevious}>
							<FontAwesomeIcon icon={faArrowLeft} size={'sm'} />
						</Button>
						<Button type={'dashed'} onClick={goNext} disabled={!canGoNext}>
							<FontAwesomeIcon icon={faArrowRight} size={'sm'} />
						</Button>
					</div>
					<Button onClick={() => downloadFile(image)}>
						<FontAwesomeIcon icon={faDownload} size={'sm'} />
						{t('bill.view.invoice.download-file')}
					</Button>
					<Button onClick={openFile}>
						<FontAwesomeIcon icon={faMagnifyingGlass} size={'sm'} />
						{t('bill.view.invoice.open-file')}
					</Button>
					<Button type={'primary'} onClick={closeFileModal}>
						OK
					</Button>
				</div>
			</div>
		</Modal>
	);
};

export default ImagePreview;