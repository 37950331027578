import React from 'react';
import {Alert} from 'antd';
import {Trans} from 'react-i18next';
import {Link} from 'react-router-dom';

import {formatIban} from '../../../utils/formatIban';

type Props = {
	iban: string;
};

const Message = ({iban}: Props) => {
	return (
		<Trans
			i18nKey={'bill.view.new-iban-warning.text'}
			values={{iban: formatIban(iban)}}
			components={{
				info: <Link to={'https://kululaskut.fi/tuki/'} target={'_blank'}></Link>,
			}}
		/>
	);
};

const NewIbanWarning = ({iban}: Props) => {
	return (
		<Alert
			type={'warning'}
			message={<Message iban={iban} />}
			showIcon
		/>
	);
};

export default NewIbanWarning;