import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import Api from './queries/api';
import {getLanguage} from './queries/types';
import translationFI from './locales/fi/translation.json';
import translationEN from './locales/en/translation.json';
import translationSV from './locales/sv/translation.json';

declare module 'i18next' {
	interface CustomTypeOptions {
		returnNull: false;
	}
}

const resources = {
	fi: {
		translation: translationFI,
	},
	en: {
		translation: translationEN,
	},
	sv: {
		translation: translationSV,
	},
};

i18n
	// Pass the i18n instance to react-i18next.
	.use(initReactI18next)
	.use(LanguageDetector)
	// Init i18next
	.init({
		resources,
		fallbackLng: 'fi',
		debug: process.env.NODE_ENV === 'development',
		returnNull: false,
		detection: {
			order: ['localStorage'],
		},
	});

// Update user language to DB
i18n.on('languageChanged', (lng) => {
	const loggedIn = localStorage.getItem('loggedIn') === 'true';
	if (loggedIn) {
		Api.put('/user/update/language', {language: getLanguage(lng)});
	}
});

export default i18n;