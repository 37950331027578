import {UseMutationResult, useMutation} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';

import Api from './api';
import {useLoadingMessage} from '../utils/useMessage';
import {useGetAccountInfo} from './useGetAccountInfo';

type UpdateAccountData = {
	accountName: string;
	accountBusinessId: string;
	accountIban: string;
	accountHelpText: string;
	accountFormPassword: string;
};

type UpdateAccountDataResult = {
	accountName: string;
	accountBusinessId: string;
	accountIban: string;
	accountHelpText: string;
	accountFormPassword: string | null;
};

const updateAccount = async (body: UpdateAccountData) => {
	const data: AxiosResponse<UpdateAccountDataResult> = await Api.put(
		'/account',
		body,
		{withCredentials: true}
	);
	return data;
};

export const useUpdateAccountInfo = (): UseMutationResult<AxiosResponse<UpdateAccountDataResult>, unknown, UpdateAccountData, unknown> => {
	const loading = useLoadingMessage();
	const {updateData} = useGetAccountInfo();

	return useMutation({
		mutationFn: (data) => {
			loading.start();
			return updateAccount(data);
		},
		onSuccess: ({data}) => {
			updateData(data);
			loading.success();
		},
		onError: (err) => {
			console.error(err);
			loading.error();
		}
	});
};