import React from 'react';
import {Modal, Typography} from 'antd';
import {useTranslation} from 'react-i18next';

const {Text} = Typography;

type Props = {
	open: boolean;
	onSubmit: () => void;
	onCancel: () => void;
	isLoading: boolean;
};

const ConfirmModal = ({open, onSubmit, onCancel, isLoading}: Props) => {
	const {t} = useTranslation();

	return (
		<Modal
			open={open}
			title={t('tools.lock.confirm-ending-period')}
			okText={t('tools.lock.submit')}
			onOk={onSubmit}
			cancelText={t('tools.lock.cancel')}
			onCancel={onCancel}
			okButtonProps={{loading: isLoading}}
		>
			<Text>{t('tools.lock.confirm-ending-period-text')}</Text>
		</Modal>
	);
};

export default ConfirmModal;