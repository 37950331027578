import React from 'react';
import {useTranslation} from 'react-i18next';

import RouteContainer from '../../components/RouteContainer';
import ChangeInformation from './Components/ChangeInformation';
import ChangePassword from './Components/ChangePassword';
import ChangeEmail from './Components/ChangeEmail';
import {useGetUserInfo} from '../../queries/useGetUserInfo';


const Categories = () => {
	const {t} = useTranslation();
	const {isLoading} = useGetUserInfo();

	return (
		<RouteContainer title={t('user-information.title')} isLoading={isLoading}>
			<div className={'userinformation__content'}>
				<div className={'userinformation__cards'}>
					<ChangeInformation />
					<ChangeEmail />
					<ChangePassword />
				</div>
			</div>
		</RouteContainer>
	);
};

export default Categories;