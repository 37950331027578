import React, {useMemo} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
	faInfo,
	faMinus,
	faPlus,
	faCheck,
} from '@fortawesome/free-solid-svg-icons';

import {InvoiceStatus} from '../types';

type IconProps = {
	status: InvoiceStatus;
}

const Icon = ({status}: IconProps) => {
	const color = useMemo(() => {
		const className = 'viewbill__steps__card__icon__';
		if (status === 'invoice') return className + 'red';
		if (status === 'income') return className + 'green';
		return className + 'blue';
	}, [status]);

	const icon = useMemo(() => {
		if (status === 'info') return faInfo;
		if (status === 'invoice') return faMinus;
		if (status === 'income') return faPlus;
		return faCheck;
	}, [status]);

	return (
		<div className={`viewbill__steps__card__icon ${color}`}>
			<FontAwesomeIcon
				icon={icon}
				color={'white'}
			/>
		</div>
	);
};

export default Icon;