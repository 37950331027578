import {UseMutationResult, useMutation, useQueryClient} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';

import Api from './api';
import {useLoadingMessage} from '../utils/useMessage';
import {Bill, emptyData} from './useGetBillById';

export type ReturnBillToUserData = {
	billId: number;
	description: string;
};

const returnBillToUser = async (body: ReturnBillToUserData) => {
	const {billId, description} = body;
	const data: AxiosResponse<Bill> = await Api.put(
		`/bill/return-to-user/${billId}`,
		{description},
	);
	return data;
};

export const useReturnBillToUser = (): UseMutationResult<AxiosResponse<Bill>, unknown, ReturnBillToUserData, unknown> => {
	const loading = useLoadingMessage();
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (data) => {
			loading.start();
			return returnBillToUser(data);
		},
		onSuccess: ({data}) => {
			queryClient.setQueryData(['bill', data.billId],
				(oldData: Bill | undefined) => {
					if (!oldData) return {...emptyData};
					return {
						...oldData,
						billStatus: data.billStatus,
						billEditHash: data.billEditHash,
					};
				},
			);
			loading.success();
		},
		onError: (err) => {
			console.error(err);
			loading.error();
		}
	});
};