import React, {useMemo} from 'react';
import {Typography} from 'antd';

const {Title} = Typography;

type Props = {
    title?: string;
	titleLevel?: 1 | 2 | 3 | 4;
    children: React.ReactNode;
	className?: string;
}

const ContentCard = ({title, titleLevel = 4, className, children}: Props) => {
	const classes = useMemo(() => {
		return ['contentcard', className].join(' ');
	}, [className]);

	return (
		<div className={classes}>
			{title && <Title level={titleLevel}>{title}</Title>}
			{children}
		</div>
	);
};

export default ContentCard;