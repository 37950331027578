import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {MenuProps, Dropdown, Button, Space} from 'antd';
import {DownOutlined} from '@ant-design/icons';

const LanguageSelect = () => {
	const {t, i18n} = useTranslation();

	const onChangeLanguage = useCallback((lngKey: string) => {
		i18n.changeLanguage(lngKey);
	}, [i18n]);

	const items: MenuProps['items'] = useMemo(() => {
		return [
			{
				key: 'fi',
				label: 'Suomeksi',
				onClick: () => onChangeLanguage('fi'),
			},
			{
				key: 'en',
				label: 'In English',
				onClick: () => onChangeLanguage('en'),
			},
			{
				key: 'sv',
				label: 'På svenska',
				onClick: () => onChangeLanguage('sv'),
			}
		];
	}, [onChangeLanguage]);

	return (
		<div className={'menu__topbar__languageselect'}>
			<Dropdown
				menu={{items, selectedKeys: [i18n.language], selectable: true, defaultSelectedKeys: [i18n.language]}}
				placement={'bottomRight'}
				arrow={true}
				overlayClassName={'menu__topbar__languageselect__dropdown'}
			>
				<Button size={'small'} ghost>
					<Space>
						{t('menu.language-select')}
						<DownOutlined />
					</Space>
				</Button>
			</Dropdown>
		</div>
	);
};

export default LanguageSelect;