import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import RouteContainer from '../../components/RouteContainer';
import AccountInformation from './Components/AccountInformation';
import Users from './Components/Users';
import AddUser from './Components/AddUser';
import {useGetAccountInfo} from '../../queries/useGetAccountInfo';
import {useGetAccesses} from '../../queries/useGetAccesses';
import {useGetUserInfo} from '../../queries/useGetUserInfo';
import {useUserRole} from '../../utils/useUserRole';

const Categories = () => {
	const {t} = useTranslation();
	const {isLoading: isLoadingAccountInfo} = useGetAccountInfo();
	const {isLoading: isLoadingAccesses} = useGetAccesses();
	const {isLoading: isLoadingUserInfo} = useGetUserInfo();
	const {role} = useUserRole();

	const isLoading = useMemo(() =>
		isLoadingAccountInfo || isLoadingAccesses || isLoadingUserInfo,
	[isLoadingAccountInfo, isLoadingAccesses, isLoadingUserInfo]);

	return (
		<RouteContainer title={t('account.title')} isLoading={isLoading}>
			<div className={'accountinformation__content'}>
				<AccountInformation />
				<Users />
				{role === 'admin' && <AddUser />}
			</div>
		</RouteContainer>
	);
};

export default Categories;