import {useQuery} from '@tanstack/react-query';

import Api from './api';
import {AxiosResponse} from 'axios';

export type MileageTypesUsedData = {
	used: boolean;
};

type MileageTypesUsedResponse = {
	data: MileageTypesUsedData;
	isLoading: boolean;
	isSuccess: boolean;
	isError: boolean;
	error: unknown;
}

const emptyData = {
	used: false,
};

const getMileageTypesUsed = async (id: number) => {
	const {data}: AxiosResponse<MileageTypesUsedData> = await Api.get(`/mileage-types/used/${id}`);
	return data;
};

export const useGetMileageTypesUsed = (id: number | null): MileageTypesUsedResponse => {
	const query = useQuery({
		queryKey: ['mileage-types-used', id],
		queryFn: () => getMileageTypesUsed(id || 0),
		cacheTime: 60 * 60 * 1000,
		enabled: id !== null,
	});

	return {
		data: query.data || emptyData,
		isLoading: query.isLoading,
		isSuccess: query.isSuccess,
		isError: query.isError,
		error: query.error,
	};
};