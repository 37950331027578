import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Form, Radio, Button, RadioChangeEvent, Typography, Checkbox} from 'antd';
import {CheckboxChangeEvent} from 'antd/es/checkbox';
import moment from 'moment';
import queryString from 'query-string';

import RouteContainer from '../../components/RouteContainer';
import ContentCard from '../../components/ContentCard';
import Filter from './Components/Filter';
import {generateCSV} from '../../utils/generateCSV';
import {useExportBills, BillStatus, Format, ExportData} from '../../queries/useExportBills';
import {useMessage} from '../../utils/useMessage';

const {Text} = Typography;

type DatePickerValue = {
	$d: Date;
}

type FormData = {
	format: Format;
	status: Array<'new' | 'archive' | 'locked'>;
	'type_accounting-number-end'?: number;
	'type_accounting-number-start'?: number;
	'type_reference-number-end'?: number;
	'type_reference-number-start'?: number;
	type_date?: [DatePickerValue, DatePickerValue];
};

const getStatusArray = (status: FormData['status']): BillStatus[] => {
	return status.reduce((p, c) => {
		if (c === 'new') return [...p, 'sent', 'edit'];
		return [...p, c];
	}, [] as BillStatus[]);
};

const Exports = () => {
	const {t, i18n} = useTranslation();
	const [form] = Form.useForm();
	const message = useMessage();

	const format = Form.useWatch('format', form);

	const {isLoading, ...exportBills} = useExportBills();

	const [selectedType, setSelectedType] = useState<'date' | 'reference-number' | 'accounting-number'>('date');
	const [includeFiles, setIncludeFiles] = useState<boolean>(false);

	const onChangeType = useCallback((e: RadioChangeEvent) => {
		setSelectedType(e.target.value);
	}, []);

	const onChangeIncludeFiles = useCallback((e: CheckboxChangeEvent) => {
		setIncludeFiles(e.target.checked);
	}, []);

	const onFormSubmit = useCallback((values: FormData) => {
		const data: ExportData = {
			format: values.format,
			includeFiles,
			status: getStatusArray(values.status),
		};

		if (selectedType === 'date') {
			if (!values.type_date) {
				return message.open({
					type: 'error',
					content: t('tools.exports.errors.invalid-dates'),
					duration: 5,
				});
			}
			data.createDateStart = moment(values.type_date[0].$d).format('YYYY-MM-DD');
			data.createDateEnd = moment(values.type_date[1].$d).format('YYYY-MM-DD');
		} else if (selectedType === 'accounting-number') {
			const start = values['type_accounting-number-start'];
			const end = values['type_accounting-number-end'];
			if (!start || !end || isNaN(start) || isNaN(end)) {
				return message.open({
					type: 'error',
					content: t('tools.exports.errors.invalid-accounting-numbers'),
					duration: 5,
				});
			}
			data.accountingNumberStart = Number(start);
			data.accountingNumberEnd = Number(end);
		} else if (selectedType === 'reference-number') {
			const start = values['type_reference-number-start'];
			const end = values['type_reference-number-end'];
			if (!start || !end || isNaN(start) || isNaN(end)) {
				return message.open({
					type: 'error',
					content: t('tools.exports.errors.invalid-reference-numbers'),
					duration: 5,
				});
			}
			data.referenceNumberStart = Number(start);
			data.referenceNumberEnd = Number(end);
		}

		if (values.status.length === 0) {
			message.open({
				type: 'error',
				content: t('tools.exports.errors.invalid-status'),
				duration: 5,
			});
		}

		if (values.format === 'print') {
			const devServer = process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : '';
			return window.open(`${devServer}/api/export/print/${i18n.language.split('-').at(0)}?${queryString.stringify(data)}`);
		}

		exportBills.mutate(data, {
			onSuccess: ({data}) => {
				generateCSV(data);
			},
		});
	}, [message, includeFiles, t, exportBills, selectedType, i18n]);

	return (
		<RouteContainer title={t('tools.exports.title')}>
			<ContentCard title={t('tools.exports.title-card')} className={'exports'}>
				<Form onFinish={onFormSubmit} className={'exports__form'} form={form}>
					<Radio.Group onChange={onChangeType} className={'exports__type__select'} value={selectedType}>
						<Radio value={'date'}>{t('tools.exports.type.date')}</Radio>
						<Radio value={'reference-number'}>{t('tools.exports.type.reference-number')}</Radio>
						<Radio value={'accounting-number'}>{t('tools.exports.type.accounting-number')}</Radio>
					</Radio.Group>

					<Filter type={selectedType} form={form} />

					<Text strong>{t('tools.exports.status.label')}</Text>
					<Form.Item name={'status'} initialValue={['new', 'archive', 'locked']}>
						<Checkbox.Group className={'exports__status__select'}>
							<Checkbox value={'new'}>{t('tools.exports.status.new')}</Checkbox>
							<Checkbox value={'archive'}>{t('tools.exports.status.archive')}</Checkbox>
							<Checkbox value={'locked'}>{t('tools.exports.status.locked')}</Checkbox>
						</Checkbox.Group>
					</Form.Item>

					<Text strong>{t('tools.exports.format.label')}</Text>
					<Form.Item name={'format'} initialValue={'print'}>
						<Radio.Group className={'exports__format__select'}>
							<Radio value={'print'}>{t('tools.exports.format.print')}</Radio>
							{format === 'print' && (
								<Checkbox
									checked={includeFiles}
									onChange={onChangeIncludeFiles}
									className={'exports__format__includefiles'}
								>
									{t('tools.exports.format.include-files')}
								</Checkbox>
							)}
							<Radio value={'csv'}>{t('tools.exports.format.csv')}</Radio>
						</Radio.Group>
					</Form.Item>

					<Form.Item name={'submit'} className={'exports__submit'}>
						<Button htmlType={'submit'} type={'primary'} loading={isLoading}>
							{t('tools.exports.create-export')}
						</Button>
					</Form.Item>

				</Form>
			</ContentCard>
		</RouteContainer>
	);
};

export default Exports;