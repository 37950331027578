import React from 'react';
import {
	BrowserRouter,
	Navigate,
	Route,
	Routes,
} from 'react-router-dom';

import MenuWrapper from './components/MenuWrapper';
import JWTValidator from './components/JWTValidator';
import LoadingIndicator from './components/LoadingIndicator';
import PageTitle from './components/PageTitle';
import Message from './components/Message';
import {
	Login,
	ResetPassword,
	Register,
	SetPassword,
	ActivateUser,
	CreateAccount,
	NotFound,
	Bills,
	ViewBill,
	Categories,
	Mileages,
	UserInformation,
	AccountInformation,
	Exports,
	LockAccountingPeriod,
} from './routes';
import {useGetAccountInfo} from './queries/useGetAccountInfo';
import {useIsLoggedIn} from './utils/useIsLoggedIn';
import {BillStatus} from './queries/useGetBills';

const Router = () => {
	const {isLoading} = useGetAccountInfo();
	const loggedIn = useIsLoggedIn();

	return (
		<BrowserRouter>
			<JWTValidator>
				<MenuWrapper>
					<LoadingIndicator isLoading={isLoading && loggedIn} />
					<PageTitle />
					<Message />
					<Routes>
						<Route path={'/login'} element={<Login />} />
						<Route path={'/reset-password'} element={<ResetPassword />} />
						<Route path={'/register'} element={<Register />} />
						<Route path={'/set-password'} element={<SetPassword />} />
						<Route path={'/activate-user'} element={<ActivateUser />} />
						<Route path={'/create-account'} element={<CreateAccount />} />
						<Route path={'/'} element={<Navigate replace to={'/bills/new'} />} />
						<Route path={'/bills/new'} element={<Bills section={[BillStatus.sent, BillStatus.edit]} key={'bills_new'} />} />
						<Route path={'/bills/archive'} element={<Bills section={[BillStatus.archive]} key={'bills_archive'} />} />
						<Route path={'/bills/locked/*'} element={<Bills section={[BillStatus.locked]} key={'bills_locked'} />} />
						<Route path={'/bills/:id'} element={<ViewBill />} />
						<Route path={'/categories'} element={<Categories />} />
						<Route path={'/mileages/*'} element={<Mileages />} />
						<Route path={'/tools/export'} element={<Exports />} />
						<Route path={'/tools/lock'} element={<LockAccountingPeriod />} />
						<Route path={'/information/user'} element={<UserInformation />} />
						<Route path={'/information/account'} element={<AccountInformation />} />
						<Route path={'*'} element={<NotFound />} />
					</Routes>
				</MenuWrapper>
			</JWTValidator>
		</BrowserRouter>
	);
};

export default Router;
