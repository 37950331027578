import React from 'react';
import {Input as AntdInput, Typography} from 'antd';
import {InputProps} from 'antd/es/input';

const {Text} = Typography;

type Props = InputProps & {
	label?: string;
	className?: string;
};

const Input = ({label, className, ...props}: Props) => {
	if (label) {
		return (
			<div className={`input ${className || ''}`}>
				<Text strong>{label}</Text>
				<AntdInput {...props} />
			</div>
		);
	}

	return <AntdInput {...props} className={className} />;
};

export default Input;