import React, {useMemo, useCallback} from 'react';
import {FormInstance, Typography, Button, Space} from 'antd';
import {isArray} from 'lodash';
import {useTranslation} from 'react-i18next';
import {DownloadOutlined} from '@ant-design/icons';
import moment from 'moment';

import ContentCard from '../../../components/ContentCard';
import {InvoiceType, InvoiceRow, InvoiceValues} from '../types';
import Icon from './Icon';
import EditItem from './EditItem';
import EditFiles from './EditFiles';
import {formatNumber} from '../../../utils/formatNumber';
import {downloadFile} from '../../../utils/downloadFile';

const {Text, Link} = Typography;

type RowProps = InvoiceRow & {
	id: InvoiceType['id'];
	index: number;
	edit: boolean;
	openFileModal: (files: Array<InvoiceValues>, index: number) => void;
};

const RowValues = ({id, index, values, edit: editForm, openFileModal}: RowProps) => {
	const {t} = useTranslation();

	const render: InvoiceValues[] = useMemo(() => {
		if (isArray(values)) return values;
		return [values];
	}, [values]);

	const images = useMemo(() => {
		return render.filter((value) => value.type === 'file/image');
	}, [render]);

	const formattedValue = useCallback(({value, link, type}: InvoiceValues) => {
		if (type === 'date') return moment(value).format('D.M.YYYY');
		if (type === 'amount' && typeof value === 'number') return formatNumber(value, '€');
		if (type === 'link' && typeof value === 'string') {
			return (
				<Link
					href={link || value}
					target={'_blank'}
				>
					{value}
				</Link>
			);
		}

		if (type === 'file/image') {
			const index = images.findIndex((image) => image.link === link);
			return (
				<Space>
					<Link onClick={() => openFileModal(images || [], index)}>{value}</Link>
					<Button icon={<DownloadOutlined />} size="small" onClick={() => downloadFile(link || '')}>{t('bill.view.invoice.download-file')}</Button>
				</Space>
			);
		}
		if (type === 'file/pdf') {
			return (
				<Space>
					<Link onClick={() => window.open(link || '')}>{value}</Link>
					<Button icon={<DownloadOutlined />} size="small" onClick={() => downloadFile(link || '')}>{t('bill.view.invoice.download-file')}</Button>
				</Space>
			);
		}
		return value.toString();
	}, [openFileModal, t, images]);

	return (
		<>
			{render.map(({value, link, type, edit}, i) => {
				if (editForm && edit) {
					if (type === 'file/image' || type === 'file/pdf') {
						// In edit, render one single file upload form (below)
						return null;
					}
					return <EditItem key={`invoice_${id}_row_${index}_edit_${i}`} value={value} edit={edit} />;
				}
				return (
					<Text key={`invoice_${id}_row_${index}_value_${i}`}>{formattedValue({value, link, type})}</Text>
				);
			})}
		</>
	);
};

const Row = ({label, ...props}: RowProps) => {
	return (
		<div className={'viewbill__steps__card__content__row'}>
			{label && <Text strong>{label}</Text>}
			<div className={!label ? 'viewbill__steps__card__content__row__nolabel' : ''}>
				<RowValues {...props} />
			</div>
		</div>
	);
};

type Props = {
	invoice: InvoiceType;
	openFileModal: (files: Array<InvoiceValues>, index: number) => void;
	edit: boolean;
	formInstance: FormInstance;
};

const StepCards = ({invoice, openFileModal, edit, formInstance}: Props) => {
	const {id, content} = invoice;

	const getFiles = useCallback((rows: InvoiceRow[]) => {
		const row = rows.find((row) => row.fileEdit);
		if (!row) return [];
		if (isArray(row.values)) return row.values;
		return [row.values];
	}, []);

	if (!content.length) return null;

	return (
		<>
			{content.map((content, index) => (
				<div key={`invoice_${id}_${index}`} className={'viewbill__steps__card'}>
					<Icon status={content.status} />

					<ContentCard title={content.title}>
						<div className={'viewbill__steps__card__content'}>
							{content.rows && (
								<div>
									{content.rows.map((row, index) => (
										<Row
											key={`invoice_${id}_row_${index}`}
											id={id}
											index={index}
											openFileModal={openFileModal}
											edit={edit}
											{...row}
										/>
									))}
									{edit && (content.type === 'income' || content.type === 'invoice') &&
										<EditFiles
											files={getFiles(content.rows)}
											name={`expense-${content.id}-files`}
											formInstance={formInstance}
										/>
									}
								</div>
							)}
						</div>
					</ContentCard>
				</div>
			))}
		</>
	);
};

export default StepCards;