import React, {useEffect, useState, useMemo, useRef, useCallback} from 'react';
import {Carousel as AntdCarousel} from 'antd';
import {CarouselRef} from 'antd/es/carousel';
import {isArray} from 'lodash';

import HeightObserver from './HeightObserver';

type Props = {
	currentIndex: number;
	children: React.ReactNode;
};

const Carousel = ({currentIndex, children}: Props) => {
	const ref = useRef<CarouselRef>(null);

	const [prevIndex, setPrevIndex] = useState<number>(currentIndex);

	const [elem0, setElem0] = useState<number | null>(null);
	const [elem1, setElem1] = useState<number | null>(null);
	const [elem2, setElem2] = useState<number | null>(null);
	const [elem3, setElem3] = useState<number | null>(null);
	const [elem4, setElem4] = useState<number | null>(null);
	const [elem5, setElem5] = useState<number | null>(null);

	const setHeight = useCallback((height: number, index: number) => {
		if (index === 0) return setElem0(height);
		if (index === 1) return setElem1(height);
		if (index === 2) return setElem2(height);
		if (index === 3) return setElem3(height);
		if (index === 4) return setElem4(height);
		if (index === 5) return setElem5(height);
	}, []);

	const heights = useMemo(() => {
		return [elem0, elem1, elem2, elem3, elem4, elem5];
	}, [elem0, elem1, elem2, elem3, elem4, elem5]);

	useEffect(() => {
		ref.current?.goTo(currentIndex);
	}, [currentIndex]);

	const parentStyle = useMemo(() => {
		if (currentIndex !== prevIndex) {
			setTimeout(() => {
				setPrevIndex(currentIndex);
			}, 500);
			return {height: heights[currentIndex] || 'auto', transition: 'all 500ms'};
		}
		return {height: heights[currentIndex] || 'auto'};
	}, [heights, currentIndex, prevIndex]);

	return (
		<div style={parentStyle}>
			<AntdCarousel
				ref={ref}
				infinite={false}
				swipe={false}
				draggable={false}
				dots={false}
				easing={'ease'}
			>
				{isArray(children) && children.map((component, index) => {
					return (
						<HeightObserver key={`carousel_children_${component.displayName}_${index}`} setDivHeight={(height) => setHeight(height, index)}>
							{component}
						</HeightObserver>
					);
				})}
			</AntdCarousel>
		</div>
	);
};

export default Carousel;