import {useCallback} from 'react';
import {useGetAccountInfo} from '../../queries/useGetAccountInfo';
import {uniq} from 'lodash';
import {UpdateBillData, Expense} from '../../queries/useUpdateBill';

type UseFormatUpdateBillData = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	format: (bill: any) => any;
};

export const useFormatUpdateBillData = (): UseFormatUpdateBillData => {
	const {data: accountInfo} = useGetAccountInfo();
	const {accountSlug} = accountInfo;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const formatExpenses = useCallback((bill: any) => {
		const expenseFields = Object.keys(bill).filter((key) => key.includes('expense'));
		const expenseIds = uniq(expenseFields.map((key) => {
			const splitted = key.split('-');
			return splitted[1] || '';
		}));


		const result: Expense[] = expenseIds.map((id) => {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const files = (bill[`expense-${id}-files`] || []).map((file: any) => {
				return {
					id: file.response.id,
					url: file.response.url,
					status: file.response.status,
				};
			});
			return {
				expenseId: Number(id),
				expenseAmount: Number(bill[`expense-${id}-sum`]) || 0,
				expenseDescription: bill[`expense-${id}-description`] as string || '',
				expenseCategory: bill[`expense-${id}-category`] as string || '',
				expenseMileage: Number(bill[`expense-${id}-mileageDistance`]) || null,
				expenseMileageType: (bill[`expense-${id}-mileageCategory`] || '').toString() || null,
				files,
			};
		});

		return result;
	}, []);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const format = useCallback((bill: any): UpdateBillData => {
		const expenses = formatExpenses(bill);
		return {
			accountSlug,
			billId: bill.billId || 0,
			billCreatorName: bill.billCreatorName || '',
			billCreatorEmail: bill.billCreatorEmail || '',
			billCreatorPhone: bill.billCreatorPhone || '',
			billCreatorIban: bill.billCreatorIban || '',
			billCreatorIdentity: bill.billCreatorIdentity || '',
			billAccountingNum: bill.billAccountingNum || null,
			termsAccepted: true,
			expenses,
		};
	}, [accountSlug, formatExpenses]);

	return {
		format,
	};
};