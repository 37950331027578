import React, {useMemo} from 'react';
import {Table, Typography} from 'antd';
import type {TableProps} from 'antd/es/table/InternalTable';
import type  {ColumnsType} from 'antd/es/table';

import {useWindowDimensions, windowBreakpoints} from '../../utils/screenSizes';
import i18n from '../../i18n';

const {Text} = Typography;

/**
 * This component only handles responsive design. It only
 * passes the props down for Antd table component.
 */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Props = TableProps<any> & {
	smallScreenThreshold?: number;
};

const ResponsiveTable = ({columns, dataSource, smallScreenThreshold = windowBreakpoints.small, ...props}: Props) => {
	const {width: screenWidth} = useWindowDimensions();

	// Render row for small rows
	const xsScreenColumnType = useMemo(() => {
		if (screenWidth > smallScreenThreshold) return [];
		return [{
			title: '',
			key: 'sm_screen',
			// We expect the data object to have values for the matching label keys
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			render: (data: any) => {
				return (
					<div className={'responsivetable__smallscreencontent'}>
						{columns?.map(({title, key, render}, index) => {
							return (
								<div key={`table_row_${index}_${key}_${Math.random()}`}>
									{(typeof title === 'string' && !!title) &&
										<Text strong>{title === '#' ? i18n.t('bill.number') : title}: </Text>
									}
									{render ? (
										// @ts-ignore For some reason react-18next breaks the types of this
										<Text>{render && render(data[key || ''], data)}</Text>
									) : (
										// @ts-ignore For some reason react-18next breaks the types of this
										<Text>{data[key || '']}</Text>
									)}
								</div>
							);
						})}
					</div>
				);
			},
		}];
	}, [columns, screenWidth, smallScreenThreshold]);

	// Use default table layout for larger screens
	const responsiveColumns = useMemo(() => {
		if (screenWidth >= smallScreenThreshold) {
			const responsive = columns?.map((c) => ({
				responsive: ['md'],
				...c,
			})) || [];
			return [...responsive, ...xsScreenColumnType];
		}
		return xsScreenColumnType;
	}, [columns, xsScreenColumnType, screenWidth, smallScreenThreshold]);

	const dataWithKeys = useMemo(() => {
		return dataSource?.map((row) => ({
			...row,
			key: row.id || row.billId,
		}));
	}, [dataSource]);

	return (
		<div className={'responsivetable'}>
			{/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
			<Table {...props} columns={responsiveColumns as ColumnsType<any>} dataSource={dataWithKeys}/>
		</div>
	);
};

export default ResponsiveTable;