import {UseMutationResult, useMutation} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';

import Api from './api';
import {useLoadingMessage} from '../utils/useMessage';

export type BillStatus = 'sent' | 'edit' | 'archive' | 'locked';
export type Format = 'print' | 'csv';

export type ExportData = {
	format: Format;
	includeFiles: boolean;
	status: BillStatus[];
	accountingNumberStart?: number;
	accountingNumberEnd?: number;
	referenceNumberStart?: number;
	referenceNumberEnd?: number;
	createDateStart?: string;
	createDateEnd?: string;
};

export type ExportBill = {
	billRefnum: number;
	billTimestamp: string;
	billStatus: BillStatus;
	billCreatorName: string;
	billCreatorEmail: string;
	billCreatorPhone: string;
	billCreatorIban: string;
	billAccountingNum: string | null;
	expenseNum: number;
	expenseAmount: number;
	expenseDescription: string;
	expenseCategory: string;
	billArchivedTimestamp: string | null;
	billArchivedUserName: string | null;
	billPaidTimestamp: string | null;
	billPaidUserName: string | null;
	expenseMileage: number | null;
	mileageType: string | null;
	mileageAmount: number | null;
};

export type ExportResult = ExportBill[];

const exportBills = async (body: ExportData) => {
	const data: AxiosResponse<ExportResult> = await Api.post('/export', body);
	return data;
};

export const useExportBills = (): UseMutationResult<AxiosResponse<ExportResult>, unknown, ExportData, unknown> => {
	const loading = useLoadingMessage();

	return useMutation({
		mutationFn: (data) => {
			return exportBills(data);
		},
		onError: (err) => {
			console.error(err);
			loading.error();
		}
	});
};