import React, {useState, useCallback, useEffect, useMemo} from 'react';
import {
	Select,
	Typography,
	Button,
} from 'antd';
import {useTranslation} from 'react-i18next';
import {useNavigate, useLocation} from 'react-router-dom';

import ContentCard from '../../components/ContentCard';
import RouteContainer from '../../components/RouteContainer';
import CategoryTable, {MileageCategoryType} from './Components/CategoryTable';
import AddOrEditModal from './Components/AddOrEditModal';
import {useGetAccountInfo} from '../../queries/useGetAccountInfo';
import {useUpdateMileageOptions} from '../../queries/useUpdateMileageOptions';
import {useUserRole} from '../../utils/useUserRole';

const {Text} = Typography;

const Mileages = () => {
	const navigate = useNavigate();
	const {pathname} = useLocation();
	const {t} = useTranslation();

	const {data: accountInfo, isLoading} = useGetAccountInfo();
	const {accountMileages, accountCollectIdentities, mileageTypes} = accountInfo;
	const {isLoading: isLoadingMileageOptions, ...mileageOptionsPut} = useUpdateMileageOptions();
	const {role} = useUserRole();

	const [mileagesEnabled, setMileagesEnabled] = useState<boolean>(accountMileages === 1);
	const [ssnCollectionEnabled, setSsnCollectionEnabled] = useState<boolean>(accountCollectIdentities === 1);

	// Update values from DB to local state
	useEffect(() => {
		setMileagesEnabled(accountMileages === 1);
		setSsnCollectionEnabled(accountCollectIdentities === 1);
	}, [accountMileages, setMileagesEnabled, accountCollectIdentities, setSsnCollectionEnabled]);

	// Open modal depending on the pathname
	const [edit, mileageTypeId] = useMemo(() => {
		const edit = pathname.includes('/edit');
		if (edit) {
			const id = pathname.split('/').filter((x) => x).at(-1);
			return [edit, Number(id)];
		}
		return [edit, null];
	}, [pathname]);
	const add = useMemo(() => pathname.includes('/new'), [pathname]);

	const openMoreInformation = useCallback(() => window.open('https://kululaskut.fi/tuki/'), []);
	const onEdit = useCallback((record: MileageCategoryType) => navigate(`/mileages/edit/${record.id}`), [navigate]);
	const onNewCategory = useCallback(() => navigate('/mileages/new'), [navigate]);
	const onCloseModal = useCallback(() => navigate('/mileages'), [navigate]);

	const onSubmitMileageOptions = useCallback(() => {
		const data = {
			accountMileages: mileagesEnabled ? 1 : 0,
			accountCollectIdentities: ssnCollectionEnabled ? 1 : 0,
		};
		mileageOptionsPut.mutate(data);
	}, [mileageOptionsPut, mileagesEnabled, ssnCollectionEnabled]);

	const fieldDisabled = useMemo(() => {
		return role === 'view';
	}, [role]);

	return (
		<>
			<AddOrEditModal
				visible={edit || add}
				type={edit ? 'edit' : 'add'}
				onCloseModal={onCloseModal}
				editId={mileageTypeId}
			/>
			<RouteContainer title={t('mileages.title')} isLoading={isLoading}>
				<div className={'mileages__content'}>
					<ContentCard title={t('mileages.invoices.title')}>
						<div className={'mileages__invoices'}>
							<div className={'mileages__invoices__description'}>
								<Text>{t('mileages.invoices.description-1')}</Text>
								<Text>{t('mileages.invoices.description-2')}</Text>
							</div>
							<Select
								className={'mileages__invoices__select'}
								onChange={setMileagesEnabled}
								defaultValue={mileagesEnabled}
								value={mileagesEnabled}
								disabled={fieldDisabled}
								options={[
									{value: true, label: t('mileages.invoices.enabled')},
									{value: false, label: t('mileages.invoices.disabled')},
								]}
							/>
							<Select
								className={'mileages__invoices__select'}
								onChange={setSsnCollectionEnabled}
								defaultValue={ssnCollectionEnabled}
								value={ssnCollectionEnabled}
								disabled={fieldDisabled}
								options={[
									{value: true, label: t('mileages.invoices.ssn-collection-enabled')},
									{value: false, label: t('mileages.invoices.ssn-collection-disabled')},
								]}
							/>
							<Text
								onClick={openMoreInformation}
								className={'mileages__invoices__link'}
							>
								{t('mileages.invoices.more-info')}
							</Text>

							{role === 'admin' && (
								<Button
									onClick={onSubmitMileageOptions}
									type={'primary'}
									className={'mileages__invoices__save'}
									loading={isLoadingMileageOptions}
								>
									{t('mileages.invoices.save')}
								</Button>
							)}
						</div>
					</ContentCard>

					<ContentCard title={t('mileages.categories.title')}>
						<div className={'mileages__categories'}>
							<CategoryTable onEdit={onEdit} mileageTypes={mileageTypes} showEdit={role === 'admin'} />

							{role === 'admin' && (
								<Button
									onClick={onNewCategory}
									type={'primary'}
									className={'mileages__categories__new'}
								>
									{t('mileages.categories.new')}
								</Button>
							)}
						</div>
					</ContentCard>
				</div>
			</RouteContainer>
		</>
	);
};

export default Mileages;