import React from 'react';
import {Typography, Form, FormItemProps} from 'antd';

const {Text} = Typography;

type Props = FormItemProps & {
	info?: string | React.ReactNode;
	children: React.ReactNode;
}

const FormItemWithHelpText = ({children, info, ...formItemProps}: Props) => {
	return (
		<div className={'createaccount__form__item'}>
			<Form.Item {...formItemProps}>
				{children}
			</Form.Item>
			{info && (
				<Text type={'secondary'}>
					{info}
				</Text>
			)}
		</div>
	);
};

export default FormItemWithHelpText;