import {UseMutationResult, useMutation} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';

import Api from './api';
import {useLoadingMessage} from '../utils/useMessage';
import {useGetAccountInfo} from './useGetAccountInfo';

type DeleteMileageType = {
	mileageId: number;
};

type DeleteMileageTypeResult = {
	status: string;
	mileageAmount: number;
	mileageId: number;
	mileageInUse: number;
	mileageType: string;
};

const deleteMileageType = async ({mileageId}: DeleteMileageType) => {
	const data: AxiosResponse<DeleteMileageTypeResult> = await Api.delete(`/mileage-types/${mileageId}`);
	return data;
};

export const useDeleteMileageType = (): UseMutationResult<AxiosResponse<DeleteMileageTypeResult>, unknown, DeleteMileageType, unknown> => {
	const loading = useLoadingMessage();
	const {updateMileageTypesData} = useGetAccountInfo();

	return useMutation({
		mutationFn: (data) => {
			loading.start();
			return deleteMileageType(data);
		},
		onSuccess: ({data}) => {
			updateMileageTypesData(data, 'delete');
			loading.success();
		},
		onError: (err) => {
			console.error(err);
			loading.error();
		}
	});
};