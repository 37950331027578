import axios from 'axios';
import {refNum} from './refNum';

const formatFileName = (fileName?: string): string => {
	if (!fileName) return 'file.jpg';

	try {
		const [prefix, billId, ...rest] = fileName.split('_');
		return `${prefix}_${refNum(Number(billId))}_${rest.join('_')}`;
	} catch {
		return fileName;
	}
};

export const downloadFile = (file: string | null) => {
	if (file === null) return;
	return axios({
		url: file,
		method: 'GET',
		responseType: 'blob',
	}).then((response) => {
		const url = window.URL.createObjectURL(new Blob([response.data]));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', formatFileName(new URL(file).pathname.split('/').pop()));
		document.body.appendChild(link);
		link.click();
	});
};