import {UseMutationResult, useMutation} from '@tanstack/react-query';

import Api from './api';
import {AxiosResponse} from 'axios';
import {Language} from './types';

type LoginData = {
	email: string;
	password: string;
};

type LoginDataResult = {
	authorized: boolean;
	userLanguage: Language;
};

const login = async (body: LoginData) => {
	const data: AxiosResponse<LoginDataResult> = await Api.post('/login', body, {withCredentials: true});
	return data;
};

export const useLogin = (): UseMutationResult<AxiosResponse<LoginDataResult>, unknown, LoginData, unknown> => {
	return useMutation({
		mutationFn: login,
	});
};