import {UseMutationResult, useMutation} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';

import Api from './api';
import {useLoadingMessage} from '../utils/useMessage';
import {Language} from './types';

export type CreateAccountData = {
	invoicingMethod: 'email' | 'e-invoice';
	emailForInvoicing?: string;
	eInvoiceAddress?: string;
	eInvoiceProvider?: string;
	accountName: string;
	accountSlug: string;
	businessId: string;
	userEmail: string;
	userPassword: string;
	userName?: string;
	recaptchaToken: string;
	language: Language,
};

type CreateAccountResult = {
	status: 'success';
};

const createAccount = async (body: CreateAccountData) => {
	const data: AxiosResponse<CreateAccountResult> = await Api.post('/account/create', body);
	return data;
};

export const useCreateAccount = (): UseMutationResult<AxiosResponse<CreateAccountResult>, unknown, CreateAccountData, unknown> => {
	const loading = useLoadingMessage();

	return useMutation({
		mutationFn: (data) => {
			return createAccount(data);
		},
		onError: (err) => {
			console.error(err);
			loading.error();
		}
	});
};