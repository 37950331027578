import React, {useState, useCallback, useMemo} from 'react';
import {useNavigate, Link} from 'react-router-dom';
import {Input, Button, Typography} from 'antd';
import {useTranslation} from 'react-i18next';
import validator from 'validator';
import {AxiosError} from 'axios';

import {useResetPassword} from '../../queries/useResetPassword';
import {useMessage} from '../../utils/useMessage';

const {Text} = Typography;

const ResetPassword = () => {
	const {t} = useTranslation();
	const navigate = useNavigate();
	const message = useMessage();

	const {isLoading, ...resetPassword} = useResetPassword();

	const [email, setEmail] = useState<string>('');
	const [showError, setShowError] = useState<boolean>(false);

	const onEmailChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(event.target.value);
		if (showError) setShowError(false);
	}, [setEmail, showError, setShowError]);

	const isEmailValid = useMemo(() => {
		return validator.isEmail(email);
	}, [email]);

	const onFormSubmit = useCallback(() => {
		if (!isEmailValid) {
			message.open({
				type: 'error',
				content: t('login.errors.invalid-email'),
			});
			return setShowError(true);
		}

		resetPassword.mutate({email}, {
			onSuccess: () => {
				navigate('/login?action=reset_password');
			},
			onError: (err) => {
				if (err instanceof AxiosError) {
					const status = err.response?.status;
					if (status === 404) {
						setShowError(true);
					}
				}
			},
		});
	}, [resetPassword, isEmailValid, navigate, email, message, t]);

	return (
		<div className={'login__page'}>
			<div className={'login'}>
				<div className={'login__header'}>
					<h1>{t('login.reset-password.title')}</h1>
				</div>

				<div className={'login__form'}>
					<Text>{t('login.reset-password.reset-password-with-email')}</Text>
					<Input
						placeholder={t('login.email') as string}
						type={'email'}
						value={email}
						onChange={onEmailChange}
						status={showError ? 'error' : ''}
						onPressEnter={onFormSubmit}
					/>
				</div>

				<div className={'login__footer'}>
					<Button
						type={'primary'}
						className={'login__footer__button'}
						loading={isLoading}
						onClick={onFormSubmit}
					>
						{t('login.reset-password.reset-password')}
					</Button>

					<Link
						className={'login__footer__link'}
						to={'/login'}
					>
						{t('login.return-to-login')}
					</Link>
				</div>
			</div>
		</div>
	);
};

export default ResetPassword;