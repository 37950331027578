import {useQuery} from '@tanstack/react-query';

import Api from './api';
import {AxiosResponse} from 'axios';

export type PreviousAndNextBills = {
	previous: number | null;
	next: number | null;
};

type GetPreviousAndNextBills = {
	data: PreviousAndNextBills;
	isLoading: boolean;
	isSuccess: boolean;
	isError: boolean;
	error: unknown;
}

const getPreviousAndNextBills = async (billId: number) => {
	const {data}: AxiosResponse<PreviousAndNextBills> = await Api.get(`/bill/previous-and-next/${billId}`);
	return data;
};

export const useGetPreviousAndNextBills = (billId: number | null): GetPreviousAndNextBills => {
	const query = useQuery({
		queryKey: ['previous-and-next-bills', billId],
		queryFn: () => getPreviousAndNextBills(billId || 0),
		cacheTime: 60 * 60 * 1000,
		enabled: billId !== null,
	});

	return {
		data: query.data || {previous: null, next: null},
		isLoading: query.isLoading,
		isSuccess: query.isSuccess,
		isError: query.isError,
		error: query.error,
	};
};