import {UseMutationResult, useMutation} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';

import Api from './api';

export type ActivateUser = {
	userId: number;
	token: string;
};

type ActivateUserResponse = {
	status: 'success',
};

const activateUser = async (body: ActivateUser) => {
	const data: AxiosResponse<ActivateUserResponse> = await Api.put(
		'/user/activate',
		body,
	);
	return data;
};

export const useActivateUser = (): UseMutationResult<AxiosResponse<ActivateUserResponse>, unknown, ActivateUser, unknown> => {
	return useMutation({
		mutationFn: (data) => {
			return activateUser(data);
		},
		onError: (err) => {
			console.error(err);
		}
	});
};