import React, {useMemo, useCallback, useState, useRef} from 'react';
import {Modal, Typography} from 'antd';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPenToSquare} from '@fortawesome/free-solid-svg-icons';

import {useGetBills, BillColumns, SortDirection, BillStatus} from '../../../queries/useGetBills';
import {formatNumber} from '../../../utils/formatNumber';

const {Text, Paragraph} = Typography;

type Props = {
	selected: number[];
	setSelected: (val: number[]) => void;
};

const SelectBills = ({selected, setSelected}: Props) => {
	const {t} = useTranslation();

	const [modalOpen, setModalOpen] = useState<boolean>(false);

	const selectRef = useRef<HTMLSelectElement>(null);

	const {data} = useGetBills(true, {
		status: [BillStatus.archive],
		page: 0,
		sortBy: BillColumns.billRefnum,
		sortDirection: SortDirection.ASC
	});
	const {bills} = data;

	const options = useMemo(() => {
		return bills.map((bill) => {
			const label = [
				t('tools.lock.select-bills-modal.reference-number', {refNum: bill.billRefnum}),
				...(bill.billAccountingNum ? [t('tools.lock.select-bills-modal.accounting-num', {accountingNum: bill.billAccountingNum})] : []),
				moment(bill.billTimestamp).format('D.M.YYYY'),
				bill.billCreatorName,
				formatNumber(bill.billSum, '€'),
			].join(', ');
			return {
				value: bill.billId,
				label,
			};
		});
	}, [bills, t]);

	const getSelection = useCallback(() => {
		const ids = bills.map((bill, index) => {
			// @ts-ignore
			if (selectRef.current[index].selected) {
				return bill.billId;
			}
			return 0;
		}).filter((x) => x !== 0);
		return ids;
	}, [selectRef, bills]);

	const setValueAll = useCallback((value: boolean) => {
		bills.forEach((bill, index) => {
			// @ts-ignore
			selectRef.current[index].selected = value;
		});
	}, [selectRef, bills]);

	const closeModal = useCallback(() => {
		setModalOpen(false);
	}, []);

	const openModal = useCallback(() => {
		setModalOpen(true);
	}, []);

	const submit = useCallback(() => {
		setModalOpen(false);
		const selection = getSelection();
		setSelected(selection);
	}, [getSelection, setSelected]);

	const defaultValue = useMemo(() => {
		return selected.map((s) => s.toString());
	}, [selected]);

	return (
		<>
			<Modal
				open={modalOpen}
				title={t('tools.lock.select-bills-modal.title')}
				onCancel={closeModal}
				onOk={submit}
				okText={t('tools.lock.select-bills-modal.save')}
				cancelText={t('tools.lock.select-bills-modal.cancel')}
				width={800}
			>
				<div className={'lockaccountingperiod__selectbills__modal'}>
					<Paragraph>{t('tools.lock.select-bills-modal.info')}</Paragraph>
					<div className={'lockaccountingperiod__selectbills__modal__pickertools'}>
						<Text onClick={() => setValueAll(true)}>{t('tools.lock.select-bills-modal.select-all')}</Text>
						|
						<Text onClick={() => setValueAll(false)}>{t('tools.lock.select-bills-modal.remove-selection')}</Text>
					</div>
					<select multiple={true} ref={selectRef} defaultValue={defaultValue}>
						{options.map((option) => (
							<option value={option.value} key={`lock_period_select_bills_${option.value}`}>{option.label}</option>
						))}
					</select>
				</div>
			</Modal>

			<div className={'lockaccountingperiod__selectbills'} onClick={openModal}>
				<FontAwesomeIcon icon={faPenToSquare} size={'lg'} />
				<Text>{t('tools.lock.select-bills')}</Text>
			</div>

			{selected.length > 0 &&
				<Text italic className={'lockaccountingperiod__selectbills__numofselected'}>{t('tools.lock.num-of-selected-bills', {count: selected.length})}</Text>
			}
		</>
	);
};

export default SelectBills;