import {UseMutationResult, useMutation, useQueryClient} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';
import {useTranslation} from 'react-i18next';

import Api from './api';
import {useLoadingMessage} from '../utils/useMessage';

type DeleteBill = {
	billId: number;
};

type DeleteBillResult = {
	status: string;
	billId: number;
};

const deleteBillById = async ({billId}: DeleteBill) => {
	const data: AxiosResponse<DeleteBillResult> = await Api.delete(`/bill/${billId}`);
	return data;
};

export const useDeleteBillById = (): UseMutationResult<AxiosResponse<DeleteBillResult>, unknown, DeleteBill, unknown> => {
	const queryClient = useQueryClient();
	const loading = useLoadingMessage();
	const {t} = useTranslation();

	return useMutation({
		mutationFn: (data) => {
			loading.start();
			return deleteBillById(data);
		},
		onSuccess: () => {
			queryClient.refetchQueries(['bills']);
			loading.success(t('bill.view.actions.deleted'));
		},
		onError: (err) => {
			console.error(err);
			loading.error();
		}
	});
};