import {useCallback, useMemo, useState} from 'react';

import {InvoiceValues} from '../types';

export const useImagePreview = () => {
	const [images, setImages] = useState<string[] | false>(false);
	const [index, setIndex] = useState<number | null>(null);

	const openImageModal = useCallback((files: InvoiceValues[], index: number) => {
		const links = files.map(({link}) => link || '');
		setImages(links);
		setIndex(index);
	}, []);

	const closeImageModal = useCallback(() => setImages(false), []);

	const [canGoPrevious, canGoNext] = useMemo(() => {
		const canGoPrevious = index !== null && index > 0;
		const canGoNext = index !== null && images && index < images.length - 1;
		return [canGoPrevious, canGoNext];
	}, [images, index]);

	const goPrevious = useCallback(() => {
		if (index === null || !canGoPrevious) return;
		setIndex(index - 1);
	}, [index, canGoPrevious]);

	const goNext = useCallback(() => {
		if (index === null || !canGoNext) return;
		setIndex(index + 1);
	}, [index, canGoNext]);

	return {
		images,
		index,
		openImageModal,
		closeImageModal,
		changeImage: {
			canGoPrevious,
			canGoNext,
			goPrevious,
			goNext,
		}
	};
};