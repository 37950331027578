import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Form, Button, Select} from 'antd';

import ContentCard from '../../../components/ContentCard';
import Input from '../../../components/Input';
import {useAddAccess} from '../../../queries/useAddAccess';
import {useGetAccesses} from '../../../queries/useGetAccesses';

type FormValues = {
	email: string;
	accessLevel: 1 | 2;
};

const ChangePassword = () => {
	const {t} = useTranslation();
	const {isLoading, ...addAccess} = useAddAccess();
	const {data: accesses} = useGetAccesses();
	const [form] = Form.useForm();

	const onSubmit = useCallback(({email, accessLevel}: FormValues) => {
		addAccess.mutate({email, accessLevel}, {
			onSuccess: () => {
				form.setFieldValue('email', '');
			},
		});
	}, [addAccess, form]);

	const accessLevels = useMemo(() => [
		{value: 1, label: t('account.add-user.access-level-1')},
		{value: 2, label: t('account.add-user.access-level-2')},
	], [t]);

	const findExistingEmail = useCallback((email: string) => {
		const find = accesses.findIndex((access) => access.userEmail === email);
		return find >= 0;
	}, [accesses]);

	return (
		<ContentCard title={t('account.add-user.title')}>
			<Form form={form} className={'userinformation__cards__form'} onFinish={onSubmit}>
				<Form.Item
					name={'email'}
					required={true}
					rules={[
						{required: true, type: 'email', message: t('account.add-user.email-not-valid')},
						{
							validator: (_, value) => {
								if (findExistingEmail(value)) {
									return Promise.reject(t('account.add-user.user-already-invited'));
								}
								return Promise.resolve();
							},
						}
					]}
				>
					<Input type={'email'} placeholder={t('account.add-user.email')} />
				</Form.Item>

				<Form.Item
					name={'accessLevel'}
					required={true}
					initialValue={accessLevels[0].value}
				>
					<Select
						options={accessLevels}
					/>
				</Form.Item>

				<Form.Item name={'submit'} className={'userinformation__cards__submit'}>
					<Button htmlType={'submit'} type={'primary'} loading={isLoading}>
						{t('account.add-user.submit')}
					</Button>
				</Form.Item>
			</Form>
		</ContentCard>
	);
};

export default ChangePassword;