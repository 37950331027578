import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Typography, QRCode} from 'antd';
import moment from 'moment';

import ContentCard from '../../../components/ContentCard';
import {Bill} from '../../../queries/useGetBillById';
import {formatIban} from '../../../utils/formatIban';
import {useMarkBillAsPaid} from '../../../queries/useMarkBillAsPaid';
import {useGetAccountInfo} from '../../../queries/useGetAccountInfo';
import {useUserRole} from '../../../utils/useUserRole';
import {formatNumber} from '../../../utils/formatNumber';
import {getBicFromIban} from '../../../utils/getBicFromIban';
import {getVirtualBarcode} from '../../../utils/getVirtualBarcode';

const {Text, Title} = Typography;

type Props = {
	data: Bill;
};

const PaymentInformation = ({data}: Props) => {
	const {t} = useTranslation();

	const {isLoading, ...markBillAsPaid} = useMarkBillAsPaid();
	const {data: accountInfo} = useGetAccountInfo();
	const {role} = useUserRole();

	const [sum, formattedSum, sumNegative] = useMemo(() => {
		const negative = data.billSum < 0;
		return [Math.abs(data.billSum), formatNumber(Math.abs(data.billSum), '€'), negative];
	}, [data]);

	const barcode = useMemo(() => {
		if (sum <= 0 || (sumNegative && !accountInfo.accountIban)) return '';
		const barcodeData = {
			iban: sumNegative ? accountInfo.accountIban : data.billCreatorIban,
			sum,
			reference: data.billRefnum.toString(),
		};
		return getVirtualBarcode(barcodeData);
	}, [data, sum, sumNegative, accountInfo]);

	const bic = useMemo(() => {
		return getBicFromIban(data.billCreatorIban);
	}, [data]);

	const qrCode = useMemo(() => {
		if (!bic || sumNegative) return null;
		const parts = [
			'BCD',
			'001',
			'1',
			'SCT',
			bic,
			data.billCreatorName,
			data.billCreatorIban,
			`EUR${sum.toFixed(2)}`,
			'',
			data.billRefnum,
			'',
			`ReqdExctnDt/${moment().format('YYYY-MM-DD')}`
		];
		return parts.join('\n');
	}, [bic, sum, data, sumNegative]);

	const onMarkAsPaid = useCallback(() => {
		markBillAsPaid.mutate({billId: data.billId});
	}, [markBillAsPaid, data]);

	return (
		<ContentCard>
			<div className={'viewbill__paymentinformation'}>
				<div className={'viewbill__paymentinformation__content'}>
					<Title level={4}>{t('bill.view.payment-information.title')}</Title>
					{sumNegative ? (
						<>
							{accountInfo.accountIban.length ? (
								<Text className={'viewbill__paymentinformation__sumnegativeinfo'}>{t('bill.view.payment-information.information-sent-to-user')}</Text>
							) : (
								<Text strong className={'viewbill__paymentinformation__sumnegativeinfo'}>{t('bill.view.payment-information.information-not-sent-to-user')}</Text>
							)}
							<div className={'viewbill__paymentinformation__row'}>
								<Text strong>{t('bill.view.payment-information.recipient')}</Text>
								<Text>{accountInfo.accountName}</Text>
							</div>
							<div className={'viewbill__paymentinformation__row'}>
								<Text strong>{t('bill.view.payment-information.iban')}</Text>
								{accountInfo.accountIban.length ? (
									<Text>{formatIban(accountInfo.accountIban)}</Text>
								) : (
									<Text italic>{t('bill.view.payment-information.iban-missing')}</Text>
								)}
							</div>
							<div className={'viewbill__paymentinformation__row'}>
								<Text strong>{t('bill.view.payment-information.reference-number')}</Text>
								<Text>{data.billRefnum}</Text>
							</div>
							<div className={'viewbill__paymentinformation__row'}>
								<Text strong>{t('bill.view.payment-information.sum')}</Text>
								<Text>{formattedSum}</Text>
							</div>
							{(barcode && barcode.length > 0) &&
								<div className={'viewbill__paymentinformation__row barcode'}>
									<Text strong>{t('bill.view.payment-information.virtual-barcode')}</Text>
									<Text copyable={{tooltips: [t('bill.view.payment-information.copy'), t('bill.view.payment-information.copied')]}}>{barcode}</Text>
								</div>
							}
						</>
					) : (
						<>
							<div className={'viewbill__paymentinformation__row'}>
								<Text strong>{t('bill.view.payment-information.recipient')}</Text>
								<Text>{data.billCreatorName}</Text>
							</div>
							<div className={'viewbill__paymentinformation__row'}>
								<Text strong>{t('bill.view.payment-information.iban')}</Text>
								<Text>{formatIban(data.billCreatorIban)}</Text>
							</div>
							<div className={'viewbill__paymentinformation__row'}>
								<Text strong>{t('bill.view.payment-information.reference-number')}</Text>
								<Text>{data.billRefnum}</Text>
							</div>
							<div className={'viewbill__paymentinformation__row'}>
								<Text strong>{t('bill.view.payment-information.sum')}</Text>
								<Text>{formattedSum}</Text>
							</div>
							{(barcode && barcode.length > 0) &&
								<div className={'viewbill__paymentinformation__row barcode'}>
									<Text strong>{t('bill.view.payment-information.virtual-barcode')}</Text>
									<Text copyable={{tooltips: [t('bill.view.payment-information.copy'), t('bill.view.payment-information.copied')]}}>{barcode}</Text>
								</div>
							}
						</>
					)}
				</div>

				{qrCode &&
					<QRCode value={qrCode} />
				}

				{role === 'admin' && (
					<div className={'viewbill__paymentinformation__markaspaid'}>
						<Button type={'primary'} onClick={onMarkAsPaid} loading={isLoading}>
							{t('bill.view.payment-information.mark-as-paid')}
						</Button>
					</div>
				)}
			</div>
		</ContentCard>
	);
};

export default PaymentInformation;