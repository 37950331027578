import {UseMutationResult, useMutation} from '@tanstack/react-query';
import {AxiosResponse, AxiosError} from 'axios';
import {useTranslation} from 'react-i18next';

import Api from './api';
import {useLoadingMessage} from '../utils/useMessage';

export type ResetPassword = {
	email: string;
};

type ResetPasswordResponse = {
	status: 'success' | 'error',
};

const resetPassword = async (body: ResetPassword) => {
	const data: AxiosResponse<ResetPasswordResponse> = await Api.put(
		'/user/reset-password',
		body,
	);
	return data;
};

export const useResetPassword = (): UseMutationResult<AxiosResponse<ResetPasswordResponse>, unknown, ResetPassword, unknown> => {
	const loading = useLoadingMessage();
	const {t} = useTranslation();

	return useMutation({
		mutationFn: (data) => {
			return resetPassword(data);
		},
		onError: (err) => {
			console.error(err);
			if (err instanceof AxiosError) {
				const status = err.response?.status;
				if (status === 404) {
					return loading.error(t('login.errors.invalid-user'));
				}
			}
			loading.error();
		}
	});
};