import React, {useCallback, useMemo} from 'react';
import {Button, Result} from 'antd';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

type Props = {
	focused: boolean;
	newUser: boolean;
};

const Confirmation = ({focused, newUser}: Props) => {
	const {t} = useTranslation();
	const navigate = useNavigate();

	const goToConsole = useCallback(() => {
		localStorage.setItem('loggedIn', 'true');
		// Expires in one hour
		localStorage.setItem('logInExpires', (Date.now() + 60 * 60 * 1000).toString());
		navigate('/information/account');
	}, [navigate]);

	// This is set to prevent focus fields that are not visible by pressing tab button
	const focusProps = useMemo(() => {
		if (focused) return {};
		return {tabIndex: -1};
	}, [focused]);

	return (
		<div className={'createaccount__confirmation'}>
			<Result
				status={'success'}
				title={t('create-account.confirmation.title')}
				subTitle={t(`create-account.confirmation.info-${newUser ? 'new' : 'existing'}-user`)}
				extra={newUser ? [] : [
					<Button
						type={'primary'}
						onClick={goToConsole}
						key={'go-to-console-button'}
						{...focusProps}
					>
						{t('create-account.confirmation.go-to-console')}
					</Button>,
				]}
			/>
		</div>
	);
};

export default Confirmation;