import {useQuery} from '@tanstack/react-query';

import Api from './api';
import {AxiosResponse} from 'axios';

enum AccessLevel {
	FULL = 1,
	READ = 2,
	PENDING = 3,
}

export type AccessesData = {
	userEmail: string;
	userName: string;
	userId: number | null;
	accessLevel: AccessLevel; // 1 = full, 2 = only read, 3 = pending access
};

type AccessesDataResponse = {
	data: AccessesData[];
	isLoading: boolean;
	isSuccess: boolean;
	isError: boolean;
	error: unknown;
}

const getAccesses = async () => {
	const {data}: AxiosResponse<AccessesData[]> = await Api.get('/accesses');
	return data;
};

export const useGetAccesses = (): AccessesDataResponse => {
	const query = useQuery({
		queryKey: ['account-accesses'],
		queryFn: getAccesses,
		cacheTime: 60 * 60 * 1000,
	});

	return {
		data: query.data || [],
		isLoading: query.isLoading,
		isSuccess: query.isSuccess,
		isError: query.isError,
		error: query.error,
	};
};