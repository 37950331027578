import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {Form, Button, Typography} from 'antd';
import {AxiosError} from 'axios';

import ContentCard from '../../../components/ContentCard';
import Input from '../../../components/Input';
import {useGetUserInfo} from '../../../queries/useGetUserInfo';
import {useUpdateUserEmail} from '../../../queries/useUpdateUserEmail';

const {Text, Paragraph} = Typography;

type FormValues = {
	password: string;
	email: string;
};

const ChangeEmail = () => {
	const {t} = useTranslation();
	const {data} = useGetUserInfo();
	const {isLoading, ...updateUserEmail} = useUpdateUserEmail();

	const [form] = Form.useForm();

	const onSubmit = useCallback((values: FormValues) => {
		updateUserEmail.mutate(values, {
			onSuccess: () => {
				form.setFieldValue('password', '');
				form.setFieldValue('email', '');
			},
			onError: (err) => {
				if (err instanceof AxiosError) {
					if (err.response?.status === 403) {
						form.setFieldValue('password', '');
						form.validateFields();
					}
				}
			}
		});
	}, [updateUserEmail, form]);

	return (
		<ContentCard title={t('user-information.change-email.title')}>
			<Text strong>{t('user-information.change-email.current-email')}</Text>
			<Paragraph>{data.userEmail}</Paragraph>
			<Form form={form} className={'userinformation__cards__form'} onFinish={onSubmit}>
				<Form.Item
					name={'email'}
					required={true}
					rules={[{required: true, type: 'email', message: t('user-information.change-email.email-not-valid')}]}
				>
					<Input
						label={t('user-information.change-email.email')}
						className={'userinformation__cards__input'}
					/>
				</Form.Item>

				<Form.Item
					name={'password'}
					required={true}
					rules={[{required: true, message: t('user-information.change-email.current-password-not-valid')}]}
				>
					<Input
						type={'password'}
						label={t('user-information.change-email.current-password')}
						className={'userinformation__cards__input'}
					/>
				</Form.Item>

				<Form.Item name={'submit-email'} className={'userinformation__cards__submit'}>
					<Button htmlType={'submit'} type={'primary'} loading={isLoading}>
						{t('user-information.save-changes')}
					</Button>
				</Form.Item>
			</Form>
		</ContentCard>
	);
};

export default ChangeEmail;