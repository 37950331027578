import {UseMutationResult, useMutation, useQueryClient} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';
import {useTranslation} from 'react-i18next';

import Api from './api';
import {useLoadingMessage} from '../utils/useMessage';

type AddFiscalYear = {
	name: string;
	bills: number[];
};

type AddFiscalYearResult = {
	status: 'success';
};

const addFiscalYear = async (body: AddFiscalYear) => {
	const data: AxiosResponse<AddFiscalYearResult> = await Api.post('/fiscal-years/create', body);
	return data;
};

export const useAddFiscalYear = (): UseMutationResult<AxiosResponse<AddFiscalYearResult>, unknown, AddFiscalYear, unknown> => {
	const {t} = useTranslation();
	const queryClient = useQueryClient();
	const loading = useLoadingMessage();

	return useMutation({
		mutationFn: (data) => {
			loading.start();
			return addFiscalYear(data);
		},
		onSuccess: () => {
			queryClient.invalidateQueries(['fiscal-years']);
			loading.success(t('tools.lock.period-created'));
		},
		onError: (err) => {
			console.error(err);
			loading.error();
		}
	});
};