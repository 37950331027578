import {ExportResult, ExportBill} from '../queries/useExportBills';
import moment from 'moment';
import Papa from 'papaparse';

import i18n from '../i18n';
import {formatNumber} from './formatNumber';

const headers = [
	i18n.t('tools.exports.csv.reference-number'),
	i18n.t('tools.exports.csv.create-date'),
	i18n.t('tools.exports.csv.status'),
	i18n.t('tools.exports.csv.creator'),
	i18n.t('tools.exports.csv.email'),
	i18n.t('tools.exports.csv.phone'),
	i18n.t('tools.exports.csv.iban'),
	i18n.t('tools.exports.csv.accounting-number'),
	i18n.t('tools.exports.csv.row-number'),
	i18n.t('tools.exports.csv.row-sum'),
	i18n.t('tools.exports.csv.row-description'),
	i18n.t('tools.exports.csv.row-category'),
	i18n.t('tools.exports.csv.accepted'),
	i18n.t('tools.exports.csv.accepted-name'),
	i18n.t('tools.exports.csv.paid'),
	i18n.t('tools.exports.csv.paid-name'),
	i18n.t('tools.exports.csv.mileage-distance'),
	i18n.t('tools.exports.csv.mileage-category'),
	i18n.t('tools.exports.csv.mileage-amount'),
];

const getBillStatus = (bill: ExportBill): string => {
	if (bill.billPaidTimestamp) return i18n.t('tools.exports.csv.status-values.paid');
	return i18n.t(`tools.exports.csv.status-values.${bill.billStatus}`);
};

const dateFormat = 'YYYY-MM-DD HH:mm:ss';
const formatDate = (date: string | null): string => {
	if (date === null) return '';
	return moment(date).format(dateFormat);
};

// This wraps values into quotes so linebreaks wont break the CSV file
const formatString = (value: string | number | null): string => {
	if (!value) return '';
	return value.toString().replace(/(?:\r\n|\r|\n)/g, ' ');
};

export const generateCSV = (data: ExportResult) => {
	const rows = data.map((bill) => ([
		formatString(bill.billRefnum),
		formatDate(bill.billTimestamp),
		getBillStatus(bill),
		formatString(bill.billCreatorName),
		formatString(bill.billCreatorEmail),
		formatString(bill.billCreatorPhone),
		formatString(bill.billCreatorIban),
		formatString(bill.billAccountingNum),
		formatString(bill.expenseNum),
		formatNumber(bill.expenseAmount),
		formatString(bill.expenseDescription),
		formatString(bill.expenseCategory),
		formatDate(bill.billArchivedTimestamp),
		formatString(bill.billArchivedUserName),
		formatDate(bill.billPaidTimestamp),
		formatString(bill.billPaidUserName),
		formatNumber(bill.expenseMileage),
		formatString(bill.mileageType),
		formatNumber(bill.mileageAmount),
	]));

	const byteOrderMark = '\ufeff';

	const csv = Papa.unparse({
		fields: headers,
		data: rows,
	}, {
		delimiter: ';',
		skipEmptyLines: true,
	});

	const csvContent = `data:text/csv;charset=utf-8,${byteOrderMark}${csv}`;

	const encodedUri = encodeURI(csvContent);

	const link = document.createElement('a');
	link.setAttribute('href', encodedUri);

	const fileName = `kululaskut_${moment().format('DDMMYYYY')}.csv`;
	link.setAttribute('download', fileName);
	document.body.appendChild(link); // Required for FF

	link.click(); // This will download the data file named 'my_data.csv'.

	document.body.removeChild(link);
};