import React, {useCallback, useMemo, useState, useEffect} from 'react';
import {Form, Button, Alert} from 'antd';
import {useTranslation, Trans} from 'react-i18next';
import validator from 'validator';
import {Link} from 'react-router-dom';
import {AxiosError} from 'axios';

import Input from '../../../../components/Input';
import SectionInfo from '../SectionInfo';
import FormItemWithHelpText from '../FormItemWithHelpText';
import {validateMessages} from '../validateMessages';
import {useCheckExistingUser} from '../../../../queries/useCheckExistingUser';
import {useMessage} from '../../../../utils/useMessage';
import {useLogin} from '../../../../queries/useLogin';
import {useGetUserInfo} from '../../../../queries/useGetUserInfo';

type Props = {
	focused: boolean;
	nextStep: (skip?: number) => void;
	previousStep: () => void;
	setUserHasToRegister: (value: boolean) => void;
};

const UserInformation = ({focused, nextStep, previousStep, setUserHasToRegister}: Props) => {
	const {t} = useTranslation();
	const [form] = Form.useForm();
	const message = useMessage();

	const userEmail = Form.useWatch('userEmail', form);

	const [formType, setFormType] = useState<'login' | 'register' | null>(null);
	const [passwordErrorMsg, setPasswordErrorMsg] = useState<string | null>(null);

	const {isLoading: isLoadingExistingUser, ...checkExistingUser} = useCheckExistingUser();
	const {isLoading: isLoadingLogin, isSuccess: isSuccessLogin, ...login} = useLogin();
	const {isLoading: isLoadingUserInfo, data: userInfo} = useGetUserInfo(isSuccessLogin);

	const checkUserEmail = useCallback((email: string) => {
		if (email && validator.isEmail(email)) {
			checkExistingUser.mutate({email}, {
				onSuccess: ({data}) => {
					if (data.existing) {
						setUserHasToRegister(false);
						return setFormType('login');
					}
					setUserHasToRegister(true);
					return setFormType('register');
				},
				onError: () => {
					message.open({
						type: 'error',
						content: t('errors.something-went-wrong'),
						duration: 5,
					});
				},
			});
		} else {
			setFormType(null);
		}
	}, [checkExistingUser, message, t, setUserHasToRegister]);

	useEffect(() => {
		const timeout = setTimeout(() => {
			checkUserEmail(userEmail);
		}, 500);
		return () => clearTimeout(timeout);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userEmail]);

	const onLogin = useCallback(() => {
		const userPassword = form.getFieldValue('userPassword');
		if (!userEmail || !userPassword) {
			return message.open({
				type: 'error',
				content: t('create-account.errors.fill-missing-fields'),
				duration: 3,
			});
		}

		login.mutate({email: userEmail, password: userPassword}, {
			onSuccess: () => {
				setPasswordErrorMsg(null);
			},
			onError: (err) => {
				if (err instanceof AxiosError) {
					const status = err.response?.status;
					if (status === 403) {
						setPasswordErrorMsg(t('create-account.errors.wrong-password'));
					}
				}
				console.log(err);
			},
		});
	}, [login, userEmail, t, form, message]);

	const onNext = useCallback(async () => {
		if (formType === 'login') {
			try {
				await form.validateFields();
				form.setFieldValue('userName', userInfo.userName);
				form.submit();
				nextStep(1);
			} catch (err) {
				message.open({
					type: 'error',
					content: t('create-account.errors.fill-missing-fields'),
					duration: 5,
				});
			}
		} else {
			nextStep();
		}
	}, [formType, nextStep, form, message, t, userInfo]);

	const canContinue = useMemo(() => {
		if (formType === 'login' && isSuccessLogin) return true;
		if (formType === 'register') return true;
		return false;
	}, [formType, isSuccessLogin]);

	const isLoadingUserLogin = useMemo(() => {
		return isLoadingLogin || (isSuccessLogin && isLoadingUserInfo);
	}, [isLoadingLogin, isLoadingUserInfo, isSuccessLogin]);

	const showLoginForm = useMemo(() => {
		if (formType === 'login') {
			return !isSuccessLogin || isLoadingUserInfo;
		}
		return false;
	}, [formType, isSuccessLogin, isLoadingUserInfo]);

	const showLoginResult = useMemo(() => {
		if (formType === 'login') {
			return isSuccessLogin && !isLoadingUserInfo;
		}
		return false;
	}, [formType, isSuccessLogin, isLoadingUserInfo]);

	// This is set to prevent focus fields that are not visible by pressing tab button
	const focusProps = useMemo(() => {
		if (focused) return {};
		return {tabIndex: -1};
	}, [focused]);

	return (
		<div className={'createaccount__form'}>
			<SectionInfo info={t('create-account.register.info')} />

			<Form
				validateMessages={validateMessages}
				form={form}
				name={'user'}
				validateTrigger={'onBlur'}
			>
				<FormItemWithHelpText
					name={'userEmail'}
					rules={[
						{required: true},
						{type: 'email'},
					]}
					validateStatus={isLoadingExistingUser ? 'validating' : undefined}
					hasFeedback
					info={t('create-account.user.email-info')}
				>
					<Input
						label={t('create-account.user.email')}
						placeholder={t('create-account.user.email-placeholder')}
						type={'email'}
						{...focusProps}
					/>
				</FormItemWithHelpText>

				<div className={`createaccount__form__useritem__info ${showLoginForm ? 'visible' : 'hidden'}`}>
					<Alert
						type={'info'}
						message={
							<Trans
								i18nKey={'create-account.user.password-info'}
								components={{
									resetPassword: <Link to={'/reset-password'} target={'_blank'}></Link>,
								}}
							/>
						}
					/>
				</div>

				{/* User found --> password input */}
				<div className={`createaccount__form__useritem ${showLoginForm ? 'visible' : 'hidden'}`}>
					<Form.Item
						name={'userPassword'}
						rules={[{required: showLoginForm}]}
						help={passwordErrorMsg}
						validateStatus={passwordErrorMsg ? 'error' : undefined}
					>
						<Input
							label={t('create-account.user.password')}
							placeholder={t('create-account.user.password-placeholder')}
							type={'password'}
							{...focusProps}
						/>
					</Form.Item>
				</div>
				<div className={`createaccount__form__useritem login ${showLoginForm ? 'visible' : 'hidden'}`}>
					<Button
						type={'primary'}
						onClick={onLogin}
						className={'createaccount__form__submit'}
						loading={isLoadingUserLogin}
						{...focusProps}
					>
						{t('create-account.user.login')}
					</Button>
				</div>

				{/* Login success --> show user name */}
				<div className={`createaccount__form__loggedin ${showLoginResult ? 'visible' : 'hidden'}`}>
					<Alert
						type={'success'}
						message={t('create-account.user.user-logged-in-title', {name: userInfo.userName})}
						description={t('create-account.user.user-logged-in-message')}
						showIcon
					/>
				</div>

				<div className={'createaccount__form__buttons'}>
					<Button
						onClick={() => previousStep()}
						className={'createaccount__form__submit'}
						{...focusProps}
					>
						{t('create-account.back')}
					</Button>

					<Button
						type={'primary'}
						onClick={onNext}
						className={'createaccount__form__submit'}
						disabled={!canContinue}
						{...focusProps}
					>
						{t('create-account.next')}
					</Button>
				</div>
			</Form>

		</div>
	);
};

export default UserInformation;