import React, {useEffect, useMemo, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {Spin, Alert} from 'antd';
import {useTranslation} from 'react-i18next';
import qs from 'query-string';
import {isArray} from 'lodash';
import {AxiosError} from 'axios';

import {useActivateUser} from '../../queries/useActivateUser';

const ActivateUser = () => {
	const {t} = useTranslation();
	const {search} = useLocation();
	const navigate = useNavigate();

	const [invalidLink, setInvalidLink] = useState<boolean>(false);

	const {isLoading, isError, error, ...activateUser} = useActivateUser();

	useEffect(() => {
		const {verification_code: token, user_id: userId} = qs.parse(search);

		if (!token || isArray(token) || !userId || isArray(userId)) {
			return setInvalidLink(true);
		}

		const data = {
			token,
			userId: Number(userId),
		};

		activateUser.mutate(data, {
			onSuccess: () => {
				navigate('/login?action=user_activated');
			},
		});
	// eslint-disable-next-line
	}, []);

	const errorCode = useMemo(() => {
		if (isError) {
			if (error instanceof AxiosError) {
				return error.response?.status;
			}
		}
		return null;
	}, [isError, error]);

	return (
		<div className={'activateuser'}>
			{isLoading && <Spin size={'large'} />}
			{isError && <Alert message={errorCode === 404 ? t('login.activate-user.invalid-link') : t('errors.something-went-wrong')} type={'error'} showIcon={true} />}
			{invalidLink && <Alert message={t('login.activate-user.invalid-link')} type={'error'} showIcon={true} />}
		</div>
	);
};

export default ActivateUser;