import {useQuery} from '@tanstack/react-query';
import qs from 'qs';

import Api from './api';
import {AxiosResponse} from 'axios';
import {useIsLoggedIn} from '../utils/useIsLoggedIn';

export enum BillStatus {
	sent = 'sent',
	edit = 'edit',
	archive = 'archive',
	locked = 'locked',
}

export enum BillColumns {
	billId = 'billId',
	billAccountingNum = 'billAccountingNum',
	billRefnum = 'billRefnum',
	billTimestamp = 'billTimestamp',
	billCreatorName = 'billCreatorName',
	billSum = 'billSum',
	billStatus = 'billStatus',
	billPaidTimestamp = 'billPaidTimestamp',
	action = 'action',
}

export enum SortDirection {
	ASC = 'ASC',
	DESC = 'DESC',
}

type GetBills = {
	status: BillStatus[];
	page: number;
	sortBy: BillColumns;
	sortDirection: SortDirection;
	fiscalYear?: number | null;
};

export type Bill = {
	billId: number;
	billRefnum: number;
	billTimestamp: string;
	billCreatorName: string;
	billStatus: BillStatus;
	billAccountingNum: string;
	billPaidTimestamp: string;
	billSum: number;
};

type BillsData = {
	bills: Bill[];
	count: number;
};

type BillsDataResponse = {
	data: BillsData;
	isLoading: boolean;
	isSuccess: boolean;
	isError: boolean;
	error: unknown;
}

const getBills = async (query: GetBills) => {
	const queryString = qs.stringify(query, {skipNulls: true});
	const {data}: AxiosResponse<BillsData> = await Api.get(`/bills?${queryString}`);
	return data;
};

export const useGetBills = (enabled: boolean, {status, page, sortBy, sortDirection, fiscalYear}: GetBills): BillsDataResponse => {
	const loggedIn = useIsLoggedIn();

	const query = useQuery({
		queryKey: ['bills', status, page, sortBy, sortDirection, fiscalYear],
		queryFn: () => getBills({status, page, sortBy, sortDirection, fiscalYear}),
		cacheTime: 60 * 60 * 1000,
		keepPreviousData: true,
		enabled: enabled && loggedIn,
	});

	return {
		data: query.data || {bills: [], count: 0},
		isLoading: query.isLoading,
		isSuccess: query.isSuccess,
		isError: query.isError,
		error: query.error,
	};
};