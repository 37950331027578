import {UseMutationResult, useMutation, useQueryClient} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';

import Api from './api';
import {useLoadingMessage} from '../utils/useMessage';
import {emptyData, UserInfoData} from './useGetUserInfo';

type UpdateUserData = {
	name: string;
	userEmailNotifications: boolean;
	userEmailNotificationsArchive: boolean;
};

type UpdateUserDataResult = {
	userEmail: string;
	userEmailNotifications: number;
	userEmailNotificationsArchive: number;
	userId: number;
	userName: string;
};

const updateUser = async (body: UpdateUserData) => {
	const data: AxiosResponse<UpdateUserDataResult> = await Api.put(
		'/user/update',
		body,
		{withCredentials: true}
	);
	return data;
};

export const useUpdateUser = (): UseMutationResult<AxiosResponse<UpdateUserDataResult>, unknown, UpdateUserData, unknown> => {
	const loading = useLoadingMessage();
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (data) => {
			loading.start();
			return updateUser(data);
		},
		onSuccess: ({data}) => {
			queryClient.setQueryData(['user-info'],
				(oldData: UserInfoData | undefined) => {
					if (!oldData) return {...emptyData, ...data};
					return {...oldData, ...data};
				},
			);
			loading.success();
		},
		onError: (err) => {
			console.error(err);
			loading.error();
		}
	});
};