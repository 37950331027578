import React from 'react';
import {Typography} from 'antd';
import {TextProps} from 'antd/es/typography/Text';

const {Link, Text} = Typography;

type RowText = {
	value: string;
	type: 'text';
	containerClass?: string;
	props?: TextProps;
};

type RowLink = {
	value: string;
	link: string;
	type: 'link';
	containerClass?: string;
};

type RowNode = {
	value: React.FC;
	type: 'node';
	containerClass?: string;
};

export type RowItem = RowText | RowLink | RowNode;

type ItemProps = {
	item: RowItem;
};

const Item = ({item}: ItemProps) => {
	if (item.type === 'text') {
		return <Text className={`accountinformation__table__row__item__text ${!item.value ? 'hidden' : ''}`} {...item.props}>{item.value}</Text>;
	}
	if (item.type === 'link') {
		return <Link href={item.link} target={'_blank'}>{item.value}</Link>;
	}
	return <item.value/>;
};

type RowProps = {
	row: RowItem[];
};

const Row = ({row}: RowProps) => {
	return (
		<div className={'accountinformation__table__row'}>
			{row.map((item, index) => (
				<div className={`accountinformation__table__row__item ${item.type} ${item.containerClass  || ''}`} key={`account_row_item_${item.value}_${index}`}><Item item={item} /></div>
			))}
		</div>
	);
};

export type Rows = Array<RowItem[]>;

type Props = {
	rows: Rows;
};

const Table = ({rows}: Props) => {
	return (
		<div className={'accountinformation__table'}>
			{rows.map((row, index) => <Row row={row} key={`account_row_${index}`} />)}
		</div>
	);
};

export default Table;