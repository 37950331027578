import React, {useState, useMemo, useCallback} from 'react';
import {Form, Button, Radio, RadioChangeEvent, Typography} from 'antd';
import {useTranslation, Trans} from 'react-i18next';
import {Link} from 'react-router-dom';

import Input from '../../../../components/Input';
import SectionInfo from '../SectionInfo';
import {validateMessages} from '../validateMessages';
import {useMessage} from '../../../../utils/useMessage';

const {Text} = Typography;

type Props = {
	nextStep: () => void;
	previousStep: () => void;
	focused: boolean;
};

const InvoicingInformation = ({nextStep, previousStep, focused}: Props) => {
	const {t} = useTranslation();
	const [form] = Form.useForm();
	const message = useMessage();

	const [invoicingMethod, setInvoicingMethod] = useState<'email' | 'e-invoice' | null>(null);

	const onChangeInvoicingMethod = useCallback((e: RadioChangeEvent) => {
		setInvoicingMethod(e.target.value);
	}, []);

	const onNext = useCallback(async () => {
		try {
			await form.validateFields();
			if (invoicingMethod === 'email') {
				form.resetFields(['eInvoiceAddress', 'eInvoiceProvider']);
			} else {
				form.resetFields(['emailForInvoicing']);
			}
			nextStep();
		} catch (err) {
			console.log(err);
			message.open({
				type: 'error',
				content: t('create-account.errors.fill-missing-fields'),
				duration: 5,
			});
		}
	}, [nextStep, form, message, t, invoicingMethod]);

	// This is set to prevent focus fields that are not visible by pressing tab button
	const focusProps = useMemo(() => {
		if (focused) return {};
		return {tabIndex: -1};
	}, [focused]);

	return (
		<div className={'createaccount__form'}>
			<SectionInfo info={t('create-account.invoicing.info')} />

			<Form
				validateMessages={validateMessages}
				form={form}
				validateTrigger={'onBlur'}
				name={'invoicing'}
			>
				<Text strong className={'createaccount__form__label'}>{t('create-account.invoicing.invoicing-method')}</Text>
				<Radio.Group
					className={'createaccount__form__select'}
					onChange={onChangeInvoicingMethod}
					value={invoicingMethod}
					buttonStyle={'solid'}
				>
					<Radio.Button value={'email'} {...focusProps}>{t('create-account.invoicing.email')}</Radio.Button>
					<Radio.Button value={'e-invoice'} {...focusProps}>{t('create-account.invoicing.e-invoice')}</Radio.Button>
				</Radio.Group>

				{/* Show email for invoicing */}
				<div className={`createaccount__form__einvoiceitem ${invoicingMethod === 'email' ? 'visible' : 'hidden'}`}>
					<Form.Item
						name={'emailForInvoicing'}
						rules={[
							{required: invoicingMethod === 'email'},
							{type: invoicingMethod === 'email' ? 'email' : undefined},
						]}
						hasFeedback
					>
						<Input
							label={t('create-account.invoicing.email-for-invoicing')}
							placeholder={t('create-account.invoicing.email-for-invoicing')}
							type={'email'}
							{...focusProps}
						/>
					</Form.Item>
				</div>

				{/* Show e-invoice fields */}
				<div className={`createaccount__form__einvoiceitem ${invoicingMethod === 'e-invoice' ? 'visible' : 'hidden'}`}>
					<Form.Item
						name={'eInvoiceAddress'}
						rules={[{required: invoicingMethod === 'e-invoice'}]}
						hasFeedback
					>
						<Input
							label={t('create-account.invoicing.e-invoice-address')}
							placeholder={t('create-account.invoicing.e-invoice-address')}
							{...focusProps}
						/>
					</Form.Item>
				</div>
				<div className={`createaccount__form__einvoiceitem ${invoicingMethod === 'e-invoice' ? 'visible' : 'hidden'}`}>
					<Form.Item
						name={'eInvoiceProvider'}
						rules={[{required: invoicingMethod === 'e-invoice'}]}
						hasFeedback
					>
						<Input
							label={t('create-account.invoicing.e-invoice-provider')}
							placeholder={t('create-account.invoicing.e-invoice-provider')}
							{...focusProps}
						/>
					</Form.Item>
				</div>
			</Form>

			<Text type={'secondary'} className={'createaccount__form__invoicing__helptext'}>
				<Trans
					i18nKey={'create-account.invoicing.help'}
					components={{
						externalLink: <Link to={'https://kululaskut.fi/'} target={'_blank'}></Link>,
					}}
				/>
			</Text>

			<div className={'createaccount__form__buttons'}>
				<Button
					onClick={() => previousStep()}
					className={'createaccount__form__submit'}
					{...focusProps}
				>
					{t('create-account.back')}
				</Button>

				<Button
					type={'primary'}
					onClick={onNext}
					className={'createaccount__form__submit'}
					disabled={invoicingMethod === null}
					{...focusProps}
				>
					{t('create-account.next')}
				</Button>
			</div>
		</div>
	);
};

export default InvoicingInformation;