import {UseMutationResult, useMutation} from '@tanstack/react-query';
import {AxiosError, AxiosResponse} from 'axios';
import {useTranslation} from 'react-i18next';

import Api from './api';
import {useLoadingMessage} from '../utils/useMessage';

type UpdateUserActiveAccountData = {
	accountId: number;
};

type UpdateUserActiveAccountDataResult = {
	accountId: number;
	status: 'success';
};

const updateUserActiveAccount = async (body: UpdateUserActiveAccountData) => {
	const data: AxiosResponse<UpdateUserActiveAccountDataResult> = await Api.put(
		`/user/active-account/${body.accountId}`,
		{},
	);
	return data;
};

export const useUpdateUserActiveAccount = (): UseMutationResult<AxiosResponse<UpdateUserActiveAccountDataResult>, unknown, UpdateUserActiveAccountData, unknown> => {
	const {t} = useTranslation();
	const loading = useLoadingMessage();

	return useMutation({
		mutationFn: (data) => {
			loading.start();
			return updateUserActiveAccount(data);
		},
		onSuccess: () => {
			loading.success();
		},
		onError: (err) => {
			console.error(err);
			if (err instanceof AxiosError) {
				if (err.response?.status === 403) {
					return loading.error(t('errors.unauthorized'));
				}
			}
			loading.error();
		}
	});
};