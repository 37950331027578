import React, {useState, useCallback, useMemo, useEffect, useRef} from 'react';
import {Form, Button} from 'antd';
import {useTranslation, Trans} from 'react-i18next';
import {Link} from 'react-router-dom';

import Input from '../../../../components/Input';
import SectionInfo from '../SectionInfo';
import FormItemWithHelpText from '../FormItemWithHelpText';
import {validateMessages} from '../validateMessages';
import {useMessage} from '../../../../utils/useMessage';
import {useCheckAccountSlug} from '../../../../queries/useCheckAccountSlug';

type ValidateStatus = Parameters<typeof Form.Item>[0]['validateStatus'];

type Props = {
	nextStep: () => void;
	focused: boolean;
};

const OrganisationInformation = ({nextStep, focused}: Props) => {
	const {t} = useTranslation();
	const [form] = Form.useForm();
	const message = useMessage();

	const ref = useRef<HTMLDivElement | null>(null);

	const checkAccountSlug = useCheckAccountSlug();

	const accountSlug = Form.useWatch('accountSlug', form);
	const [accountSlugState, setAccountSlugState] = useState<{
		value: string;
		errorMsg?: string;
		validateStatus?: ValidateStatus;
	}>({value: ''});

	const onNext = useCallback(async () => {
		try {
			await form.validateFields();
			if (accountSlugState.validateStatus !== 'success') {
				throw new Error('accountSlug not valid');
			}
			nextStep();
		} catch (err) {
			message.open({
				type: 'error',
				content: t('create-account.errors.fill-missing-fields'),
				duration: 5,
			});
		}
	}, [nextStep, form, message, t, accountSlugState]);

	const onChangeAccountSlug = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		if (/^[A-Za-z0-9]*$/.test(e.target.value) !== true) {
			return setAccountSlugState({value: e.target.value, errorMsg: t('create-account.errors.invalid-account-slug'), validateStatus: 'error'});
		}
		if (e.target.value === '') {
			return setAccountSlugState({value: e.target.value, errorMsg: t('create-account.errors.field-required'), validateStatus: 'error'});
		}
		return setAccountSlugState({value: e.target.value, errorMsg: undefined, validateStatus: 'success'});
	}, [t]);

	const onCheckAccountSlug = useCallback((accountSlug: string) => {
		if (!accountSlug || accountSlugState.validateStatus === 'error') return;

		setAccountSlugState({...accountSlugState, validateStatus: 'validating'});

		checkAccountSlug.mutate({accountSlug}, {
			onSuccess: ({data}) => {
				if (data.reserved) {
					return setAccountSlugState({...accountSlugState, validateStatus: 'error', errorMsg: t('create-account.errors.account-slug-reserved')});
				}
				return setAccountSlugState({...accountSlugState, validateStatus: 'success', errorMsg: undefined});
			},
			onError: () => {
				return setAccountSlugState({...accountSlugState, validateStatus: 'error', errorMsg: t('create-account.errors.account-slug-reserved')});
			},
		});
	}, [checkAccountSlug, accountSlugState, t]);

	useEffect(() => {
		const timeout = setTimeout(() => {
			onCheckAccountSlug(accountSlug);
		}, 500);
		return () => clearTimeout(timeout);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accountSlug]);

	// This is set to prevent focus fields that are not visible by pressing tab button
	const focusProps = useMemo(() => {
		if (focused) return {};
		return {tabIndex: -1};
	}, [focused]);

	return (
		<div className={'createaccount__form'} ref={ref}>
			<SectionInfo info={t('create-account.organisation.info')} />

			<Form
				className={'createaccount__form__content'}
				name={'organisation'}
				validateTrigger={'onBlur'}
				validateMessages={validateMessages}
				form={form}
			>
				<FormItemWithHelpText
					name={'accountName'}
					rules={[{required: true}]}
					hasFeedback
				>
					<Input
						label={t('create-account.organisation.name')}
						placeholder={t('create-account.organisation.name-placeholder')}
						{...focusProps}
					/>
				</FormItemWithHelpText>

				<FormItemWithHelpText
					name={'accountSlug'}
					info={t('create-account.organisation.slug-info')}
					validateStatus={accountSlugState.validateStatus}
					help={accountSlugState.errorMsg}
					hasFeedback
				>
					<Input
						onChange={onChangeAccountSlug}
						addonBefore={'https://'}
						addonAfter={'.kululaskut.fi'}
						label={t('create-account.organisation.slug')}
						placeholder={t('create-account.organisation.slug-placeholder')}
						{...focusProps}
					/>
				</FormItemWithHelpText>

				<FormItemWithHelpText
					name={'businessId'}
					rules={[{required: true}]}
					info={
						<Trans
							i18nKey={'create-account.organisation.business-id-info'}
							components={{
								externalLink: <Link to={'https://yhdistysrekisteri.prh.fi/'} target={'_blank'}></Link>,
							}}
						/>
					}
					hasFeedback
				>
					<Input
						label={t('create-account.organisation.business-id')}
						placeholder={t('create-account.organisation.business-id-placeholder')}
						{...focusProps}
					/>
				</FormItemWithHelpText>

			</Form>

			<Button
				type={'primary'}
				onClick={onNext}
				className={'createaccount__form__submit'}
				{...focusProps}
			>
				{t('create-account.next')}
			</Button>
		</div>
	);
};

export default OrganisationInformation;