import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {Form, Button} from 'antd';
import {AxiosError} from 'axios';

import ContentCard from '../../../components/ContentCard';
import Input from '../../../components/Input';
import {useUpdateUserPassword} from '../../../queries/useUpdateUserPassword';

type FormValues = {
	currentPassword: string;
	newPassword: string;
	newPasswordAgain: string;
};

const ChangePassword = () => {
	const {t} = useTranslation();
	const {isLoading, ...updateUserPassword} = useUpdateUserPassword();

	const [form] = Form.useForm();

	const onSubmit = useCallback((values: FormValues) => {
		const {currentPassword, newPassword, newPasswordAgain} = values;
		if (newPassword !== newPasswordAgain) return;

		const data = {
			oldPassword: currentPassword,
			newPassword,
		};
		updateUserPassword.mutate(data, {
			onSuccess: () => {
				form.setFieldValue('currentPassword', '');
				form.setFieldValue('newPassword', '');
				form.setFieldValue('newPasswordAgain', '');
			},
			onError: (err) => {
				if (err instanceof AxiosError) {
					if (err.response?.status === 403) {
						form.setFieldValue('currentPassword', '');
						form.validateFields();
					}
				}
			}
		});
	}, [updateUserPassword, form]);

	return (
		<ContentCard title={t('user-information.change-password.title')}>
			<Form form={form} className={'userinformation__cards__form'} onFinish={onSubmit}>
				<Form.Item
					name={'currentPassword'}
					required={true}
					rules={[{required: true, message: t('user-information.change-password.current-password-not-valid')}]}
				>
					<Input
						type={'password'}
						label={t('user-information.change-password.current-password')}
						className={'userinformation__cards__input'}
					/>
				</Form.Item>

				<Form.Item
					name={'newPassword'}
					required={true}
					rules={[{required: true, message: t('user-information.change-password.password-not-valid'), min: 8}]} // TODO
				>
					<Input
						type={'password'}
						label={t('user-information.change-password.new-password')}
						className={'userinformation__cards__input'}
					/>
				</Form.Item>

				<Form.Item
					name={'newPasswordAgain'}
					required={true}
					rules={[{
						validator: (_, value) => {
							const newPassword = form.getFieldValue('newPassword');
							if (value && newPassword !== value) {
								return Promise.reject(t('user-information.change-password.passwords-not-matching'));
							} else {
								return Promise.resolve();
							}
						},
					}]}
				>
					<Input
						type={'password'}
						label={t('user-information.change-password.new-password-again')}
						className={'userinformation__cards__input'}
					/>
				</Form.Item>

				<Form.Item name={'submit-password'} className={'userinformation__cards__submit'}>
					<Button htmlType={'submit'} type={'primary'} loading={isLoading}>
						{t('user-information.change-password.submit')}
					</Button>
				</Form.Item>
			</Form>
		</ContentCard>
	);
};

export default ChangePassword;