import React, {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Form, Input, Select} from 'antd';
import {FormInstance} from 'rc-field-form/lib/interface';
import {RuleObject} from 'antd/es/form';
import validator from 'validator';
import i18n from '../../../i18n';

import {useGetAccountInfo} from '../../../queries/useGetAccountInfo';
import {Edit, Rule} from '../types';
import {formatNumber} from '../../../utils/formatNumber';

const {TextArea} = Input;

type EditProps = {
	value: string | number;
	edit: Edit;
};

const getRule = (rule: Rule): RuleObject => {
	if (rule === 'required') return {required: true};
	if (rule === 'email') {
		return {
			validator: (_, value) => {
				if (value && !validator.isEmail(value as string)) {
					return Promise.reject(i18n.t('bill.view.edit-errors.email-not-valid'));
				} else {
					return Promise.resolve();
				}
			},
		};
	}
	if (rule === 'phone') {
		return {
			validator: (_, value) => {
				if (value && !validator.isMobilePhone(value as string)) {
					return Promise.reject(i18n.t('bill.view.edit-errors.phone-not-valid'));
				} else {
					return Promise.resolve();
				}
			},
		};
	}
	if (rule === 'iban') {
		return {
			validator: (_, value) => {
				if (value && !validator.isIBAN(value as string)) {
					return Promise.reject(i18n.t('bill.view.edit-errors.iban-not-valid'));
				} else {
					return Promise.resolve();
				}
			},
		};
	}
	return {};
};

const EditItem = ({value, edit}: EditProps) => {
	const {t} = useTranslation();
	const {data} = useGetAccountInfo();
	const {mileageTypes} = data;

	const updateTotalMileageSum = useCallback((name: string, value: number, form: FormInstance) => {
		const type = name.includes('mileageDistance') ? 'distance' : name.includes('mileageCategory') ? 'category' : null;
		if (type !== null) {
			const [prefix, expenseId] = name.split('-');
			if (expenseId) {
				const distance = type === 'distance' ? value : Number(form.getFieldValue(`${prefix}-${expenseId}-mileageDistance`));
				const category = type === 'category' ? value : form.getFieldValue(`${prefix}-${expenseId}-mileageCategory`);
				const mileage = mileageTypes.find((mileage) => mileage.mileageId === category);
				if (!mileage) return;
				const total = (distance * mileage.mileageAmount).toFixed(2);
				form.setFieldValue(`${prefix}-${expenseId}-sum`, total);
			}
		}
	}, [mileageTypes]);

	const validateNumber = useCallback((
		name: string,
		value: string,
		form: FormInstance,
	) => {
		if (value === undefined) return Promise.resolve();
		const val = value.replace(/,/g, '.');
		if (isNaN(Number(val)) || Number(val) === 0) return Promise.reject(new Error(t('bill.view.edit-errors.field-not-valid')));
		const rounded = Number(val).toFixed(2);
		form.setFieldValue(edit.name, rounded);
		updateTotalMileageSum(name, Number(rounded), form);
		return Promise.resolve();
	}, [updateTotalMileageSum, edit, t]);

	const validateMileageCategory = useCallback((
		name: string,
		value: number,
		form: FormInstance,
	) => {
		updateTotalMileageSum(name, value, form);
		return Promise.resolve();
	}, [updateTotalMileageSum]);

	const mileageOptions = useMemo(() => {
		if (edit.type !== 'mileageCategory') return [];
		return mileageTypes.map((mileage) => ({
			value: mileage.mileageId,
			label: `${mileage.mileageType} (${formatNumber(mileage.mileageAmount, '€')}€/km)`,
		}));
	}, [mileageTypes, edit]);

	const rules = useMemo(() => {
		if (!edit.rules || edit.rules.length === 0) return [];
		return edit.rules.map(getRule);
	}, [edit]);

	if (edit.type === 'textarea') {
		return (
			<Form.Item
				name={edit.name}
				initialValue={edit.initialValue !== undefined ? edit.initialValue : value}
				className={'viewbill__steps__formitem'}
				rules={rules}
			>
				<TextArea placeholder={edit.placeholder} />
			</Form.Item>
		);
	}

	if (edit.type === 'distance' || edit.type === 'amount') {
		return (
			<Form.Item
				name={edit.name}
				initialValue={edit.initialValue !== undefined ? edit.initialValue : value}
				className={'viewbill__steps__formitem'}
				rules={[
					{required: true},
					(form) => ({
						validator: (_, value) => validateNumber(edit.name, value, form),
					}),
				]}
			>
				<Input placeholder={edit.placeholder} addonAfter={edit.type === 'amount' ? '€' : 'km'} disabled={edit.disabled} />
			</Form.Item>
		);
	}

	if (edit.type === 'mileageCategory') {
		return (
			<Form.Item
				name={edit.name}
				className={'viewbill__steps__formitem'}
				required={false}
				initialValue={edit.initialValue}
				validateTrigger={'onChange'}
				rules={[
					{required: true},
					(form) => ({
						validator: (_, value) => validateMileageCategory(edit.name, value, form),
					}),
				]}
			>
				<Select
					popupMatchSelectWidth={false}
					options={mileageOptions}
				/>
			</Form.Item>
		);
	}

	return (
		<Form.Item
			name={edit.name}
			initialValue={edit.initialValue !== undefined ? edit.initialValue : value}
			className={'viewbill__steps__formitem'}
			rules={rules}
		>
			<Input placeholder={edit.placeholder} />
		</Form.Item>
	);
};

export default EditItem;