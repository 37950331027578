import {useCallback, useEffect, useState} from 'react';
import {FormInstance} from 'antd';
import isEqual from 'lodash/isEqual';

type UseFormValuesChanged = {
	// No type for form values since they are created dynamically
	// eslint-disable-next-line
	changed: (values: any) => boolean;
};

export const useFormValuesChanged = (edit: boolean, form: FormInstance): UseFormValuesChanged => {
	const [formOriginalState, setFormOriginalState] = useState<object | null>(null);

	useEffect(() => {
		if (edit && formOriginalState === null) {
			setFormOriginalState(form.getFieldsValue());
		}
		if (!edit) {
			setFormOriginalState(null);
		}
	}, [edit, formOriginalState, form]);

	// No type for form values since they are created dynamically
	// eslint-disable-next-line
	const changed = useCallback((values: any) => {
		if (!edit) return false;
		return !isEqual(formOriginalState, values);
	}, [edit, formOriginalState]);

	return {
		changed,
	};
};