import React, {useCallback, useMemo} from 'react';
import {Typography} from 'antd';
import type {ColumnsType} from 'antd/es/table';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPen} from '@fortawesome/free-solid-svg-icons';

import i18n from '../../../i18n';
import {MileageTypesData} from '../../../queries/useGetAccountInfo';
import ResponsiveTable from '../../../components/ResponsiveTable';
import {formatNumber} from '../../../utils/formatNumber';

const {Text} = Typography;

export interface MileageCategoryType {
	id: number;
	name: string;
	sum: number;
}

const generateColumns = (showEdit: boolean): ColumnsType<MileageCategoryType> => {
	const columns = [
		{
			dataIndex: 'name',
			key: 'name',
		},
		{
			dataIndex: 'sum',
			key: 'sum',
			render: (val: number) => `${formatNumber(val, '€')}/km`,
		},
		{
			dataIndex: 'inUse',
			key: 'inUse',
			render: (val: number) => val === 0 ? <Text italic>{i18n.t('mileages.categories.not-in-use')}</Text> : '',
		},
	];

	if (showEdit) {
		columns.push({
			key: 'action',
			dataIndex: '123',
			render: () => (
				<div className={'mileages__categories__table__edit'}>
					<FontAwesomeIcon icon={faPen} size={'xs'} />
					<Text>{i18n.t('mileages.categories.edit')}</Text>
				</div>
			),
		});
	}

	return columns;
};

type Props = {
	onEdit: (record: MileageCategoryType) => void;
	mileageTypes: MileageTypesData[];
	showEdit: boolean;
};

const CategoryTable = ({onEdit, mileageTypes, showEdit}: Props) => {
	const dataSource: MileageCategoryType[] = useMemo(() => {
		const data = mileageTypes.map((type) => ({
			id: type.mileageId,
			name: type.mileageType,
			sum: type.mileageAmount,
			inUse: type.mileageInUse,
		}));
		return data;
	}, [mileageTypes]);

	const columns = useMemo(() => {
		return generateColumns(showEdit);
	}, [showEdit]);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const onRowClick = useCallback((record: any) => {
		if (showEdit) onEdit(record);
	}, [onEdit, showEdit]);

	return (
		<ResponsiveTable
			rowKey={(record) => record.id.toString()}
			className={'mileages__categories__table'}
			dataSource={dataSource}
			columns={columns}
			showHeader={false}
			pagination={false}
			// @ts-ignore Typescript doesn't like adding new methods here
			onRow={record => ({onClick: () => onRowClick(record)})}
		/>
	);
};

export default CategoryTable;