import React, {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faXmark} from '@fortawesome/free-solid-svg-icons';
import {Button, Popconfirm, Typography} from 'antd';

import ContentCard from '../../../components/ContentCard';
import {useGetAccountInfo} from '../../../queries/useGetAccountInfo';
import {useGetAccesses} from '../../../queries/useGetAccesses';
import {useGetUserInfo} from '../../../queries/useGetUserInfo';
import {useUserRole} from '../../../utils/useUserRole';
import {useDeleteAccess} from '../../../queries/useDeleteAccess';
import {useDeletePendingAccess} from '../../../queries/useDeletePendingAccess';
import Table, {Rows, RowItem} from './Table';

const {Paragraph} = Typography;

type DeleteButtonProps = {
	userName: string;
	userEmail: string;
	userId: number | null;
};

const DeleteButton = ({userName, userEmail, userId}: DeleteButtonProps) => {
	const {t} = useTranslation();
	const {isLoading: isLoadingDeleteAccess, ...deleteAccess} = useDeleteAccess();
	const {isLoading: isLoadingDeletePendingAccess, ...deletePendingAccess} = useDeletePendingAccess();
	const {data: accountInfo} = useGetAccountInfo();
	const {accountId} = accountInfo;

	const [open, setOpen] = useState<boolean>(false);

	const isLoading = useMemo(() => isLoadingDeleteAccess || isLoadingDeletePendingAccess, [isLoadingDeleteAccess, isLoadingDeletePendingAccess]);

	const onOpen = useCallback(() => setOpen(true), [setOpen]);
	const onClose = useCallback(() => setOpen(false), [setOpen]);

	const onDelete = useCallback(() => {
		if (userId) {
			deleteAccess.mutate({userId}, {
				onSuccess: onClose,
			});
		} else {
			deletePendingAccess.mutate({userEmail, accountId}, {
				onSuccess: onClose,
			});
		}
	}, [userId, deleteAccess, accountId, deletePendingAccess, onClose, userEmail]);


	return (
		<Popconfirm
			placement={'topRight'}
			open={open}
			title={t('account.users.delete-verify', {name: userName})}
			okText={t('account.users.delete-yes')}
			onConfirm={onDelete}
			okButtonProps={{danger: true, loading: isLoading}}
			cancelText={t('account.users.delete-no')}
			onCancel={onClose}
		>
			<Button className={'accountinformation__users__delete'} type={'link'} danger={true} size={'small'} onClick={onOpen}>
				<FontAwesomeIcon icon={faXmark} />
				{t('account.users.delete-access')}
			</Button>
		</Popconfirm>
	);
};

const ChangeInformation = () => {
	const {t} = useTranslation();
	const {data} = useGetAccesses();
	const {data: userInfo} = useGetUserInfo();
	const {role} = useUserRole();

	const accessLevelTexts = useMemo(() => ({
		1: t('account.users.access-level-1'),
		2: t('account.users.access-level-2'),
		3: t('account.users.access-level-3'),
	}), [t]);

	const accesses: Rows = useMemo(() => {
		if (data.length > 0) {
			return data.map((access) => {
				const columns: RowItem[] = [
					{value: access.userName, type: 'text'},
					{value: access.userEmail, link: `mailto:${access.userEmail}`, type: 'link'},
					{value: accessLevelTexts[access.accessLevel], type: 'text', props: {italic: true}},
				];
				if (role === 'admin') {
					if (userInfo.userId === access.userId) {
						columns.push({value: () => <></>, type: 'node', containerClass: 'userdelete'});
					} else {
						columns.push({
							value: () => <DeleteButton userName={access.userName} userId={access.userId} userEmail={access.userEmail} />,
							type: 'node',
							containerClass: 'userdelete'
						});
					}
				}
				return columns;
			});
		}
		return [[]];
	}, [data, accessLevelTexts, userInfo, role]);

	return (
		<ContentCard title={t('account.users.title')}>
			<Table rows={accesses} />
			<Paragraph className={'accountinformation__users__info'}>{t('account.users.info')}</Paragraph>
		</ContentCard>
	);
};

export default ChangeInformation;