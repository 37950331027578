import React from 'react';
import {Typography, Divider} from 'antd';

const {Title} = Typography;

type Props = {
    title: string;
    children: React.ReactNode;
	isLoading?: boolean;
};

const RouteContainer = ({title, children, isLoading}: Props) => {
	return (
		<div className={'routecontainer'}>
			<div className={'routecontainer__title'}>
				<Title level={3}>{title}</Title>
				<Divider className={'routecontainer__divider'} />
			</div>
			<div className={`routecontainer__content ${isLoading ? 'hidden' : 'visible'}`}>
				{children}
			</div>
		</div>
	);
};

export default RouteContainer;