import {UseMutationResult, useMutation, useQueryClient} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';

import Api from './api';
import {useLoadingMessage} from '../utils/useMessage';
import {Bill, emptyData} from './useGetBillById';

type File = {
	id: number;
	url: string;
	status: string;
};

export type Expense = {
	expenseId: number;
	expenseAmount: number;
	expenseDescription: string;
	expenseCategory: string;
	expenseMileage: number | null;
	expenseMileageType: string | null;
	files: File[];
};

export type UpdateBillData = {
	accountSlug: string;
	billId: number;
	billCreatorName: string;
	billCreatorEmail: string;
	billCreatorPhone: string;
	billCreatorIban: string;
	billCreatorIdentity: string;
	billAccountingNum: string | null;
	termsAccepted: boolean;
	expenses: Expense[];
};

const updateBill = async (body: UpdateBillData) => {
	const {billId, ...otherBody} = body;
	const data: AxiosResponse<Bill> = await Api.put(
		`/bill/edit/${billId}`,
		otherBody,
	);
	return data;
};

export const useUpdateBill = (): UseMutationResult<AxiosResponse<Bill>, unknown, UpdateBillData, unknown> => {
	const loading = useLoadingMessage();
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (data) => {
			loading.start();
			return updateBill(data);
		},
		onSuccess: ({data}) => {
			queryClient.setQueryData(['bill', data.billId],
				(oldData: Bill | undefined) => {
					if (!oldData) return {...emptyData};
					return {
						...oldData,
						...data,
						expenses: oldData.expenses.map((expense) => {
							const find = data.expenses.find((resExpense) => resExpense.expenseId === expense.expenseId);
							return {
								...expense,
								...find,
							};
						}),
					};
				},
			);
			loading.success();
		},
		onError: (err) => {
			console.error(err);
			loading.error();
		}
	});
};