import React from 'react';
import {Alert, Typography} from 'antd';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';

const {Text} = Typography;

const Description = () => {
	const {t} = useTranslation();

	return (
		<>
			<Text>{t('bill.info-archive')} </Text>
			<Link to={'/bills/locked'}>{t('bill.info-archive-link')}</Link>
		</>
	);
};

const ArchiveInfo = () => {
	return (
		<Alert description={<Description />} />
	);
};

export default ArchiveInfo;