import React, {useEffect} from 'react';
import {message} from 'antd';
import {useTranslation} from 'react-i18next';

type Props = {
	isLoading: boolean;
}

const LoadingIndicator = ({isLoading}: Props) => {
	const {t} = useTranslation();
	const [messageApi, contextHolder] = message.useMessage();

	useEffect(() => {
		if (isLoading) {
			messageApi.open({
				type: 'loading',
				content: t('message.data-loading'),
			});
		} else {
			setTimeout(() => {
				messageApi.destroy();
			}, 500);
		}
	}, [isLoading, messageApi, t]);

	return (
		<>
			{contextHolder}
		</>
	);
};

export default LoadingIndicator;