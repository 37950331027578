import {useQuery} from '@tanstack/react-query';

import Api from './api';
import {AxiosResponse, AxiosError} from 'axios';
import {BillStatus} from './useGetBills';

type File = {
	format: 'pdf' | 'jpeg' | 'jpg' | 'png';
	url: string;
	name: string;
	id: string;
};

export type Expense = {
	expenseId: number;
	expenseNum: number;
	expenseAmount: number;
	expenseDescription: string;
	expenseCategory: string;
	expenseMileage: number;
	expenseMileageType: string;
	files: File[];
};

export type Bill = {
	billId: number;
	billRefnum: number;
	billTimestamp: string;
	billStatus: BillStatus | null;
	billCreatorName: string;
	billCreatorEmail: string;
	billCreatorPhone: string;
	billCreatorIban: string;
	billCreatorIdentity: string;
	billAccountingNum: string | null;
	billEditHash: string | null;
	billArchivedTimestamp: string;
	billArchivedUserId: string;
	billArchivedUserName: string;
	billPaidTimestamp: string;
	billPaidUserId: string;
	billPaidUserName: string;
	newIban: boolean;
	fiscalYearName: string | null;
	fiscalYearId: number | null;
	billSum: number;
	expenses: Expense[];
};

type BillResponse = {
	data: Bill;
	isLoading: boolean;
	isSuccess: boolean;
	isError: boolean;
	error: AxiosError | null;
}

export const emptyData = {
	billId: 0,
	billRefnum: 0,
	billTimestamp: '',
	billStatus: null,
	billCreatorName: '',
	billCreatorEmail: '',
	billCreatorPhone: '',
	billCreatorIban: '',
	billCreatorIdentity: '',
	billAccountingNum: null,
	billEditHash: null,
	billArchivedTimestamp: '',
	billArchivedUserId: '',
	billArchivedUserName: '',
	billPaidTimestamp: '',
	billPaidUserId: '',
	billPaidUserName: '',
	newIban: false,
	fiscalYearName: null,
	fiscalYearId: null,
	billSum: 0,
	expenses: [],
};

const getBillById = async (id: number) => {
	const {data}: AxiosResponse<Bill> = await Api.get(`/bill/${id}`);
	return data;
};

export const useGetBillById = (id: number | null): BillResponse => {
	const query = useQuery({
		queryKey: ['bill', id],
		queryFn: () => getBillById(id || 0),
		cacheTime: 60 * 60 * 1000,
		enabled: id !== null,
		retry: (count, error: AxiosError) => {
			if (error.response?.status === 404) return false;
			return true;
		},
	});

	return {
		data: query.data || emptyData,
		isLoading: query.isLoading,
		isSuccess: query.isSuccess,
		isError: query.isError,
		error: query.error,
	};
};