import {useQuery} from '@tanstack/react-query';

import Api from './api';
import {AxiosResponse} from 'axios';

export type FiscalYear = {
	yearId: number;
	yearName: string;
	yearStartDate: string;
	yearEndDate: string;
};

type GetFiscalYears = {
	data: FiscalYear[];
	isLoading: boolean;
	isSuccess: boolean;
	isError: boolean;
	error: unknown;
}

const getFiscalYears = async () => {
	const {data}: AxiosResponse<FiscalYear[]> = await Api.get('/fiscal-years');
	return data;
};

export const useGetFiscalYears = (): GetFiscalYears => {
	const query = useQuery({
		queryKey: ['fiscal-years'],
		queryFn: getFiscalYears,
		cacheTime: 60 * 60 * 1000,
	});

	return {
		data: query.data || [],
		isLoading: query.isLoading,
		isSuccess: query.isSuccess,
		isError: query.isError,
		error: query.error,
	};
};