import {UseMutationResult, useMutation, useQueryClient} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';

import Api from './api';
import {useLoadingMessage} from '../utils/useMessage';
import {AccessesData} from './useGetAccesses';

type DeletePendingAccessData = {
	userEmail: string;
	accountId: number;
};

type DeletePendingAccessDataResult = {
	status: string;
	email: string;
};

const deletePendingAccess = async ({userEmail, accountId}: DeletePendingAccessData) => {
	const data: AxiosResponse<DeletePendingAccessDataResult> = await Api.delete(`pending-access/${accountId}/${userEmail}`);
	return data;
};

export const useDeletePendingAccess = (): UseMutationResult<AxiosResponse<DeletePendingAccessDataResult>, unknown, DeletePendingAccessData, unknown> => {
	const queryClient = useQueryClient();
	const loading = useLoadingMessage();

	return useMutation({
		mutationFn: (data) => {
			loading.start();
			return deletePendingAccess(data);
		},
		onSuccess: ({data}) => {
			loading.success();
			queryClient.setQueryData(['account-accesses'], (oldData: AccessesData[] | undefined) => {
				if (!oldData) {
					return [];
				}
				return [...oldData].filter((access) => access.userEmail !== data.email);
			});
		},
		onError: (err) => {
			console.error(err);
			loading.error();
		}
	});
};