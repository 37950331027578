import {UseMutationResult, useMutation} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';

import Api from './api';
import {useLoadingMessage} from '../utils/useMessage';
import {useGetAccountInfo} from './useGetAccountInfo';

type MileageTypesData = {
	accountMileages: number;
	accountCollectIdentities: number;
};

type MileageTypesDataResult = {
	accountMileages: number;
	accountCollectIdentities: number;
};

const updateMileageOptions = async (body: MileageTypesData) => {
	const data: AxiosResponse<MileageTypesDataResult> = await Api.put('/account/mileage-options', body, {withCredentials: true});
	return data;
};

export const useUpdateMileageOptions = (): UseMutationResult<AxiosResponse<MileageTypesDataResult>, unknown, MileageTypesData, unknown> => {
	const loading = useLoadingMessage();
	const {updateData} = useGetAccountInfo();

	return useMutation({
		mutationFn: (data) => {
			loading.start();
			return updateMileageOptions(data);
		},
		onSuccess: ({data}) => {
			updateData(data);
			loading.success();
		},
		onError: (err) => {
			console.error(err);
			loading.error();
		}
	});
};