import {UseMutationResult, useMutation} from '@tanstack/react-query';
import {AxiosResponse} from 'axios';

import Api from './api';
import {useLoadingMessage} from '../utils/useMessage';
import {useGetAccountInfo} from './useGetAccountInfo';

type NewMileageTypes = {
	mileageAmount: number;
	mileageType: string;
};

type NewMileageTypesResult = {
	mileageId: number;
	mileageInUse: number;
	mileageAmount: number;
	mileageType: string;
};

const addMilageType = async (body: NewMileageTypes) => {
	const data: AxiosResponse<NewMileageTypesResult> = await Api.post('/mileage-types', body, {withCredentials: true});
	return data;
};

export const useAddMileageType = (): UseMutationResult<AxiosResponse<NewMileageTypesResult>, unknown, NewMileageTypes, unknown> => {
	const loading = useLoadingMessage();
	const {updateMileageTypesData} = useGetAccountInfo();

	return useMutation({
		mutationFn: (data) => {
			loading.start();
			return addMilageType(data);
		},
		onSuccess: ({data}) => {
			updateMileageTypesData(data, 'add');
			loading.success();
		},
		onError: (err) => {
			console.error(err);
			loading.error();
		}
	});
};