import React from 'react';
import {Result, Button} from 'antd';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

const NotFound = () => {
	const {t} = useTranslation();
	const navigate = useNavigate();

	return (
		<div className={'notfound'}>
			<Result
				status={'404'}
				title={'404'}
				subTitle={t('errors.page-not-found')}
				extra={<Button type={'primary'} onClick={() => navigate('/bills/new')}>{t('errors.back-to-home')}</Button>}
			/>
		</div>
	);
};

export default NotFound;