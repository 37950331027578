import React, {useCallback, useMemo, useState} from 'react';
import {SorterResult} from 'antd/es/table/interface';
import {useTranslation} from 'react-i18next';
import {isArray} from 'lodash';
import {useNavigate, useLocation} from 'react-router-dom';
import queryString from 'query-string';

import ArchiveInfo from './Components/ArchiveInfo';
import LockedSelect from './Components/LockedSelect';
import ContentCard from '../../components/ContentCard';
import RouteContainer from '../../components/RouteContainer';
import ResponsiveTable from '../../components/ResponsiveTable';
import {useGetBills, BillStatus, BillColumns, SortDirection, Bill} from '../../queries/useGetBills';
import {generateColumns} from './generateColumns';

type Props = {
	section: BillStatus[];
};

const Bills = ({section}: Props) => {
	const {t} = useTranslation();
	const navigate = useNavigate();
	const {pathname, search} = useLocation();
	const [sortBy, setSortBy] = useState<BillColumns>(BillColumns.billTimestamp);
	const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.DESC);

	const page = useMemo(() => {
		const {page: pageNumber} = queryString.parse(search);
		if (!pageNumber || Array.isArray(pageNumber)) return 1;
		if (isNaN(Number(pageNumber))) return 1;
		return Number(pageNumber);
	}, [search]);

	const yearIdFromUrl = useMemo(() => {
		if (pathname.includes('/bills/locked/')) {
			const yearId = pathname.split('/').at(-1);
			if (!isNaN(Number(yearId))) {
				return Number(yearId);
			}
		}
		return null;
	}, [pathname]);

	const [fiscalYear, setFiscalYear] = useState<number | null>(yearIdFromUrl);

	const billsQueryEnabled = useMemo(() => {
		if (section.includes(BillStatus.locked)) {
			return fiscalYear !== null;
		}
		return true;
	}, [section, fiscalYear]);

	const {data, isLoading} = useGetBills(billsQueryEnabled, {status: section, page, sortBy, sortDirection, fiscalYear});
	const {bills, count} = data;

	const title = useMemo(() => {
		return t(`bill.title-${section}`);
	}, [section, t]);

	const hasReturnedBills: boolean = useMemo(() => {
		return !!bills.find((bill) => bill.billStatus === BillStatus.edit);
	}, [bills]);

	const columns = useMemo(() => {
		return generateColumns({section, hasReturnedBills});
	}, [section, hasReturnedBills]);

	const getSortKey = useCallback((key: React.Key) => {
		if (key === BillColumns.billCreatorName) return BillColumns.billCreatorName;
		if (key === BillColumns.billRefnum) return BillColumns.billRefnum;
		if (key === BillColumns.billSum) return BillColumns.billSum;
		return BillColumns.billTimestamp;
	}, []);

	const onSorterChange = useCallback((sorter: SorterResult<Bill> | SorterResult<Bill>[]) => {
		if (!isArray(sorter) && sorter.columnKey && sorter.order) {
			setSortBy(getSortKey(sorter.columnKey));
			setSortDirection(sorter.order === 'ascend' ? SortDirection.ASC : SortDirection.DESC);
		}
	}, [setSortBy, setSortDirection, getSortKey]);

	const onPageChange = useCallback((newPage: number) => {
		navigate(`${pathname}?page=${newPage}`, {replace: true});
	}, [navigate, pathname]);

	const onSelectFiscalYear = useCallback((yearId: number | null) => {
		setFiscalYear(yearId);
		if (yearId !== null) {
			navigate(`/bills/locked/${yearId}`);
		}
	}, [navigate]);

	return (
		<RouteContainer title={title} isLoading={isLoading && billsQueryEnabled}>
			<div className={'bills'}>
				{section.includes(BillStatus.archive) && <ArchiveInfo />}
				{section.includes(BillStatus.locked) && <LockedSelect fiscalYear={fiscalYear} setFiscalYear={onSelectFiscalYear} />}
				{billsQueryEnabled && (
					<ContentCard>
						<ResponsiveTable
							smallScreenThreshold={1000}
							columns={columns}
							dataSource={bills}
							onChange={(pagination, filters, sorter) => onSorterChange(sorter)}
							pagination={{pageSize: 20, onChange: onPageChange, total: count, showSizeChanger: false, current: page}}
							loading={isLoading}
							size={'small'}
							locale={{
								triggerDesc: '',
								triggerAsc: '',
								cancelSort: '',
								emptyText: t('bill.no-bills'),
							}}
						/>
					</ContentCard>
				)}
			</div>
		</RouteContainer>
	);
};

export default Bills;