import {useMemo} from 'react';
import {useLocation} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
	faBook,
	faRightFromBracket,
	faDatabase,
	faCarAlt,
	faWrench,
	faUser,
} from '@fortawesome/free-solid-svg-icons';
import type {MenuProps} from 'antd';
import {useTranslation} from 'react-i18next';

type MenuItem = Required<MenuProps>['items'][number];

const getItem = (
	label: React.ReactNode,
	key: React.Key,
	icon?: React.ReactNode,
	children?: MenuItem[],
	type?: 'group',
): MenuItem => ({
	key,
	icon,
	children,
	label,
	type,
} as MenuItem);

type MenuContent = {
	items: MenuProps['items'];
	defaultOpenKeys: string[];
	selectedKeys: string[];
};

export const useMenuContent = (admin: boolean): MenuContent => {
	const {t} = useTranslation();
	const {pathname} = useLocation();

	const selectedKeys = useMemo(() => {
		const paths = pathname.split('/').filter(x => x);
		if (paths.length > 1) {
			if (paths[0] === 'bills' && paths[1] === 'locked') {
				return [
					'/bills',
					'/bills/locked',
				];
			}
			return [
				`/${paths[0]}`,
				`/${paths[1]}`,
				pathname,
			];
		}
		return [pathname];
	}, [pathname]);

	const items = useMemo(() => ([
		getItem(t('menu.cost-invoices'), '/bills', <FontAwesomeIcon icon={faBook} />, [
			getItem(t('menu.cost-invoices-new'), '/bills/new'),
			getItem(t('menu.cost-invoices-accepted'), '/bills/archive'),
			getItem(t('menu.cost-invoices-closed-accounting-periods'), '/bills/locked'),
		]),
		getItem(t('menu.categories'), '/categories', <FontAwesomeIcon icon={faDatabase} />),
		getItem(t('menu.mileages'), '/mileages', <FontAwesomeIcon icon={faCarAlt} />),
		getItem(t('menu.tools'), '/tools', <FontAwesomeIcon icon={faWrench} />, [
			getItem(t('menu.prints-and-aggregations'), '/tools/export'),
			...(admin ? [getItem(t('menu.end-accounting-period'), '/tools/lock')] : []),
		]),
		getItem(t('menu.your-information'), '/information', <FontAwesomeIcon icon={faUser} />, [
			getItem(t('menu.user-information'), '/information/user'),
			getItem(t('menu.account-information'), '/information/account'),
		]),
		getItem(t('menu.logout'), '/logout', <FontAwesomeIcon icon={faRightFromBracket} />),
	]), [t, admin]);

	const defaultOpenKeys = useMemo(() => {
		if (pathname.includes('/bills')) return ['/bills'];
		if (pathname.includes('/tools')) return ['/tools'];
		if (pathname.includes('/information')) return ['/information'];
		return ['/bills'];
	}, [pathname]);

	return {items, defaultOpenKeys, selectedKeys};
};