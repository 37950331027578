import axios, {Axios, AxiosError, AxiosRequestConfig, AxiosResponse} from 'axios';

class Api {
	private service: Axios;

	constructor() {
		const service = axios.create({
			baseURL: '/api',
			withCredentials: true,
		});

		service.interceptors.response.use(this.handleSuccess, this.handleError);

		this.service = service;
	}

	handleSuccess(response: AxiosResponse) {
		return response;
	}

	handleError(error: AxiosError) {
		return Promise.reject(error);
	}

	get(url: string, config?: AxiosRequestConfig) {
		return this.service.get(url, {withCredentials: true, ...config});
	}

	post(url: string, data: unknown, config?: AxiosRequestConfig) {
		return this.service.post(url, data, {withCredentials: true, ...config});
	}

	put(url: string, data: unknown, config?: AxiosRequestConfig) {
		return this.service.put(url, data, {withCredentials: true, ...config});
	}

	delete(url: string, config?: AxiosRequestConfig) {
		return this.service.delete(url, {withCredentials: true, ...config});
	}
}

export default new Api();