export enum Language {
	fi = 'fi',
	en = 'en',
	sv = 'sv',
}

export const getLanguage = (lng: string): Language => {
	if (lng.includes(Language.en)) return Language.en;
	if (lng.includes(Language.sv)) return Language.sv;
	return Language.fi;
};