import React, {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Form, Input, Button} from 'antd';
import {Link, useNavigate, useLocation} from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';

import {useRegisterUser} from '../../queries/useRegisterUser';
import i18n from '../../i18n';
import {getLanguage} from '../../queries/types';

type RegisterForm = {
	name: string;
	email: string;
	password: string;
	passwordAgain: string;
};

const Register = () => {
	const {t} = useTranslation();
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const prefilledEmail = new URLSearchParams(useLocation().search).get('email');

	const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);

	const {isLoading, ...registerUser} = useRegisterUser();

	const validateMessages = useMemo(() => {
		return {
			required: t('login.register.field-required'),
			types: {
				email: t('login.register.email-not-valid'),
			},
		};
	}, [t]);

	const onFormFinish = useCallback((values: RegisterForm) => {
		try {
			form.validateFields();
		} catch (err) {
			console.log(err);
		}

		const data = {
			email: values.email,
			name: values.name,
			password: values.password,
			recaptchaToken: recaptchaToken || '',
			userLanguage: getLanguage(i18n.language),
		};

		registerUser.mutate(data, {
			onSuccess: () => {
				navigate('/login?action=user_created');
			},
		});
	}, [form, registerUser, navigate, recaptchaToken]);

	return (
		<div className={'login__page'}>

			<Form
				form={form}
				onFinish={onFormFinish}
				validateMessages={validateMessages}
				initialValues={{email: prefilledEmail || ''}}
				className={'login'}
			>

				<div className={'login__header'}>
					<h1>{t('login.register.title')}</h1>
				</div>

				<div className={'login__form'}>
					<Form.Item name={'name'} rules={[{required: true}]}>
						<Input placeholder={t('login.register.name') as string} />
					</Form.Item>

					<Form.Item name={'email'} rules={[{required: true}, {type: 'email'}]}>
						<Input
							placeholder={t('login.register.email') as string}
							type={'email'}
							disabled={!!prefilledEmail}
						/>
					</Form.Item>

					<Form.Item
						name={'password'}
						rules={[
							{required: true},
							{
								validator: (_, value) => {
									if (value && value.length < 8) {
										return Promise.reject(t('login.register.password-not-valid'));
									} else {
										return Promise.resolve();
									}
								},
							},
						]}
					>
						<Input
							placeholder={t('login.register.password') as string}
							type={'password'}
						/>
					</Form.Item>

					<Form.Item
						name={'passwordAgain'}
						rules={[
							{required: true},
							{
								validator: (_, value) => {
									const newPassword = form.getFieldValue('password');
									if (value && newPassword !== value) {
										return Promise.reject(t('login.register.passwords-do-not-match'));
									} else {
										return Promise.resolve();
									}
								},
							},
						]}
					>
						<Input
							placeholder={t('login.register.password-again') as string}
							type={'password'}
						/>
					</Form.Item>
				</div>

				<div className={'login__footer'}>
					<div className={'login__footer__recaptcha'}>
						<ReCAPTCHA
							sitekey={process.env.REACT_APP_RECAPTCHA_KEY || '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'}
							onChange={setRecaptchaToken}
						/>
					</div>

					<Form.Item name={'submit'}>
						<Button
							type={'primary'}
							className={'login__footer__button'}
							htmlType={'submit'}
							disabled={!recaptchaToken}
							loading={isLoading}
						>
							{t('login.register.create-user')}
						</Button>
					</Form.Item>

					<Link
						className={'login__footer__link'}
						to={'/login'}
					>
						{t('login.return-to-login')}
					</Link>
				</div>

			</Form>

		</div>
	);
};

export default Register;