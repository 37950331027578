import {useCallback} from 'react';
import {useQuery, useQueryClient} from '@tanstack/react-query';

import Api from './api';
import {AxiosResponse} from 'axios';
import {useIsLoggedIn} from '../utils/useIsLoggedIn';

export type MileageTypesData = {
	mileageAmount: number;
	mileageId: number;
	mileageInUse: number;
	mileageType: string;
}

export type AccountInfoData = {
	accountId: number;
	accountName: string;
	accountSlug: string;
	accountBusinessId: string;
	accountIban: string;
	accountExpenseCategories: string;
	accountIncomeCategories: string;
	accountMileages: number;
	accountCollectIdentities: number;
	accountHelpText: string;
	accountInvoiceEmail: string | null;
	accountFormPassword: string | null;
	mileageTypes: MileageTypesData[];
};

type AccountInfoResponse = {
	data: AccountInfoData;
	isLoading: boolean;
	isSuccess: boolean;
	isError: boolean;
	error: unknown;
	updateData: (data: Partial<AccountInfoData>, type?: 'mileageTypes') => void;
	updateMileageTypesData: (data: MileageTypesData, type: 'add' | 'update' | 'delete') => void;
}

const emptyData = {
	accountId: 0,
	accountName: '',
	accountSlug: '',
	accountBusinessId: '',
	accountIban: '',
	accountExpenseCategories: '',
	accountIncomeCategories: '',
	accountMileages: 0,
	accountCollectIdentities: 0,
	accountHelpText: '',
	accountInvoiceEmail: null,
	accountFormPassword: null,
	mileageTypes: [],
};

const getAccountInfo = async () => {
	const {data}: AxiosResponse<AccountInfoData> = await Api.get('/account-info');
	return data;
};

const getMileageTypes = (oldData: MileageTypesData[], newData: MileageTypesData, action: 'add' | 'update' | 'delete') => {
	if (action === 'add') {
		return [...oldData, newData];
	} else if (action === 'update') {
		return [...oldData.map((type) => {
			if (type.mileageId === newData.mileageId) {
				return newData;
			}
			return type;
		})];
	} else if (action === 'delete') {
		return [...oldData.filter((type) => (type.mileageId !== newData.mileageId))];
	}
	return [...oldData];
};

export const useGetAccountInfo = (): AccountInfoResponse => {
	const queryClient = useQueryClient();
	const loggedIn = useIsLoggedIn();

	const query = useQuery({
		queryKey: ['account-info'],
		queryFn: getAccountInfo,
		cacheTime: 60 * 60 * 1000,
		enabled: loggedIn,
	});

	const updateData = useCallback((data: Partial<AccountInfoData>) => {
		queryClient.setQueryData(
			['account-info'],
			(oldData: AccountInfoData | undefined) => {
				if (!oldData) {
					return {...emptyData, ...data};
				} else {
					return {...oldData, ...data};
				}
			}
		);
	}, [queryClient]);

	const updateMileageTypesData = useCallback((data: MileageTypesData, type: 'add' | 'update' | 'delete') => {
		queryClient.setQueryData(
			['account-info'],
			(oldData: AccountInfoData | undefined) => {
				if (!oldData) {
					return {...emptyData, mileageTypes: [data]};
				} else {
					const mileageTypes = getMileageTypes(oldData.mileageTypes, data, type);
					return {...oldData, ...data, mileageTypes};
				}
			}
		);
	}, [queryClient]);

	return {
		data: query.data || emptyData,
		isLoading: query.isLoading,
		isSuccess: query.isSuccess,
		isError: query.isError,
		error: query.error,
		updateData,
		updateMileageTypesData,
	};
};