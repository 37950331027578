import React, {useState, useCallback, useMemo} from 'react';
import {useNavigate, Link, useLocation} from 'react-router-dom';
import {Input, Button, Typography} from 'antd';
import {useTranslation} from 'react-i18next';
import qs from 'query-string';
import {isArray} from 'lodash';

import {useSetPassword} from '../../queries/useSetPassword';
import {useMessage} from '../../utils/useMessage';

const {Text} = Typography;

const SetPassword = () => {
	const {t} = useTranslation();
	const navigate = useNavigate();

	const {isLoading, ...setNewPassword} = useSetPassword();
	const message = useMessage();
	const {search} = useLocation();

	const [password, setPassword] = useState<string>('');
	const [passwordAgain, setPasswordAgain] = useState<string>('');
	const [showError, setShowError] = useState<string>('');

	const onPasswordChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		setPassword(event.target.value);
		if (showError) setShowError('');
	}, [setPassword, showError, setShowError]);

	const onPasswordAgainChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		setPasswordAgain(event.target.value);
		if (showError) setShowError('');
	}, [setPasswordAgain, showError, setShowError]);

	const isPasswordValid = useMemo(() => {
		if (password.length < 8) return t('login.set-password.password-does-not-meet-requirements');
		if (password !== passwordAgain) return t('login.set-password.passwords-do-not-match');
		return '';
	}, [password, passwordAgain, t]);

	const onFormSubmit = useCallback(() => {
		const {token = null, email = null} = qs.parse(search, {decode: false});

		if (!token || isArray(token) || !email || isArray(email)) {
			return message.open({
				key: 'set-password-error',
				type: 'error',
				content: t('login.set-password.link-not-valid'),
				duration: 5,
			});
		}

		const passwordValid = isPasswordValid;
		if (passwordValid !== '') {
			return setShowError(passwordValid);
		}

		setNewPassword.mutate({token, email, password}, {
			onSuccess: () => {
				navigate('/login?action=new_password_set');
			},
		});
	}, [setNewPassword, isPasswordValid, navigate, message, password, search, t]);

	return (
		<div className={'login__page'}>
			<div className={'login'}>
				<div className={'login__header'}>
					<h1>{t('login.set-password.title')}</h1>
				</div>

				<div className={'login__form'}>
					<Text>{t('login.set-password.new-password')}</Text>
					<Input
						placeholder={t('login.set-password.new-password') as string}
						type={'password'}
						value={password}
						onChange={onPasswordChange}
						status={showError !== '' ? 'error' : ''}
					/>
				</div>

				<div className={'login__form setpasswordagain'}>
					<Input
						placeholder={t('login.set-password.new-password-again') as string}
						type={'password'}
						value={passwordAgain}
						onChange={onPasswordAgainChange}
						status={showError !== '' ? 'error' : ''}
					/>
					{showError !== '' && <Text type={'danger'} className={'login__form__error'}>{showError}</Text>}
				</div>

				<div className={'login__footer'}>
					<Button
						type={'primary'}
						className={'login__footer__button'}
						loading={isLoading}
						onClick={onFormSubmit}
					>
						{t('login.set-password.title')}
					</Button>

					<Link
						className={'login__footer__link'}
						to={'/login'}
					>
						{t('login.return-to-login')}
					</Link>
				</div>
			</div>
		</div>
	);
};

export default SetPassword;