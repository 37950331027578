import React, {useMemo, useCallback} from 'react';
import {Form, Button} from 'antd';
import {useTranslation} from 'react-i18next';

import Input from '../../../../components/Input';
import SectionInfo from '../SectionInfo';
import {validateMessages} from '../validateMessages';
import {useMessage} from '../../../../utils/useMessage';

type Props = {
	userHasToRegister: boolean;
	focused: boolean;
	nextStep: () => void;
	previousStep: () => void;
};

const RegistrationInformation = ({userHasToRegister, focused, nextStep, previousStep}: Props) => {
	const {t} = useTranslation();
	const [form] = Form.useForm();
	const message = useMessage();

	const onNext = useCallback(async () => {
		try {
			await form.validateFields();
			form.submit();
			nextStep();
		} catch (err) {
			message.open({
				type: 'error',
				content: t('create-account.errors.fill-missing-fields'),
				duration: 5,
			});
		}
	}, [nextStep, form, message, t]);

	// This is set to prevent focus fields that are not visible by pressing tab button
	const focusProps = useMemo(() => {
		if (focused) return {};
		return {tabIndex: -1};
	}, [focused]);

	return (
		<div className={'createaccount__form'} style={!userHasToRegister ? {display: 'none', width: 0} : {}}>
			<SectionInfo info={t('create-account.register.info')} />

			<Form
				validateMessages={validateMessages}
				form={form}
				name={'register'}
				validateTrigger={'onBlur'}
			>
				<Form.Item
					name={'userName'}
					rules={[{required: true}]}
					hasFeedback
				>
					<Input
						label={t('create-account.register.name')}
						placeholder={t('create-account.register.name-placeholder')}
						{...focusProps}
					/>
				</Form.Item>

				<Form.Item
					name={'newPassword'}
					rules={[{required: true}, {min: 8, message: t('create-account.errors.password-not-valid')}]}
					hasFeedback
				>
					<Input
						label={t('create-account.register.new-password')}
						placeholder={t('create-account.register.new-password-placeholder')}
						type={'password'}
						{...focusProps}
					/>
				</Form.Item>
				<Form.Item
					name={'newPasswordAgain'}
					rules={[
						{required: true},
						{
							validator: (_, value) => {
								const newPassword = form.getFieldValue('newPassword');
								if (value && newPassword !== value) {
									return Promise.reject(t('create-account.errors.passwords-do-not-match'));
								} else {
									return Promise.resolve();
								}
							},
						},
					]}
					hasFeedback
				>
					<Input
						placeholder={t('create-account.register.new-password-again-placeholder')}
						type={'password'}
						{...focusProps}
					/>
				</Form.Item>

				<div className={'createaccount__form__buttons'}>
					<Button
						onClick={() => previousStep()}
						className={'createaccount__form__submit'}
						{...focusProps}
					>
						{t('create-account.back')}
					</Button>

					<Button
						type={'primary'}
						onClick={onNext}
						className={'createaccount__form__submit'}
						{...focusProps}
					>
						{t('create-account.next')}
					</Button>
				</div>
			</Form>

		</div>
	);
};

export default RegistrationInformation;