import React, {useCallback, useMemo, useState} from 'react';
import {useParams, useNavigate, Navigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Button, Modal, Form} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleLeft, faAngleRight} from '@fortawesome/free-solid-svg-icons';

import RouteContainer from '../../components/RouteContainer';
import Steps from './Components/Steps';
import PaymentInformation from './Components/PaymentInformation';
import NewIbanWarning from './Components/NewIbanWarning';
import Breadcrumb from './Components/Breadcrumb';
import {useGetBillById} from '../../queries/useGetBillById';
import {useGenerateExpenses} from './generateData';
import {useGetPreviousAndNextBills} from '../../queries/useGetPreviousAndNextBills';
import {useUpdateBill} from '../../queries/useUpdateBill';
import {useFormatUpdateBillData} from './formatUpdateBillData';
import {useFormValuesChanged} from './useFormValuesChanged';
import {refNum} from '../../utils/refNum';

const ViewBill = () => {
	const {t} = useTranslation();
	const navigate = useNavigate();
	const {id} = useParams();

	const [form] = Form.useForm();
	const [modal, modalContextHolder] = Modal.useModal();

	const [edit, setEdit] = useState<boolean>(false);

	const formValues = useFormValuesChanged(edit, form);

	const billId: number | null = useMemo(() => {
		if (isNaN(Number(id))) return null;
		return Number(id);
	}, [id]);

	const {data, isLoading, isError, error} = useGetBillById(billId);
	const invoice = useGenerateExpenses(data);

	const {data: previousAndNextBills} = useGetPreviousAndNextBills(billId);

	const showPaymentInformation = useMemo(() => {
		return data.billStatus === 'archive' && !data.billPaidTimestamp;
	}, [data]);

	const {format} = useFormatUpdateBillData();
	const updateBill = useUpdateBill();
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const saveBill = useCallback(async (values: any) => {
		const data = format({...values, billId: invoice.id});
		await updateBill.mutateAsync(data, {
			onSuccess: () => {
				setEdit(false);
			},
		});
	}, [invoice, updateBill, setEdit, format]);

	const saveAndMoveToBill = useCallback(async (id: number) => {
		await saveBill(form.getFieldsValue());
		navigate(`/bills/${id}`);
	}, [navigate, form, saveBill]);

	const discardAndMoveToBill = useCallback((id: number) => {
		setEdit(false);
		form.resetFields();
		navigate(`/bills/${id}`);
	}, [navigate, setEdit, form]);

	const moveToPreviousBill = useCallback(() => {
		if (!previousAndNextBills.previous) return;
		if (formValues.changed(form.getFieldsValue())) {
			return modal.confirm({
				title: t('bill.view.actions.unsaved-changes.title'),
				content: t('bill.view.actions.unsaved-changes.info'),
				okText: t('bill.view.actions.unsaved-changes.save'),
				cancelText: t('bill.view.actions.unsaved-changes.discard'),
				onOk: () => saveAndMoveToBill(previousAndNextBills.previous as number),
				onCancel: () => discardAndMoveToBill(previousAndNextBills.previous as number),
			});
		} else {
			setEdit(false);
			form.resetFields();
			return navigate(`/bills/${previousAndNextBills.previous}`);
		}
	}, [previousAndNextBills, navigate, form, formValues, t, modal, saveAndMoveToBill, discardAndMoveToBill]);

	const moveToNextBill = useCallback(() => {
		if (!previousAndNextBills.next) return;
		if (formValues.changed(form.getFieldsValue())) {
			return modal.confirm({
				title: t('bill.view.actions.unsaved-changes.title'),
				content: t('bill.view.actions.unsaved-changes.info'),
				okText: t('bill.view.actions.unsaved-changes.save'),
				cancelText: t('bill.view.actions.unsaved-changes.discard'),
				onOk: () => saveAndMoveToBill(previousAndNextBills.next as number),
				onCancel: () => discardAndMoveToBill(previousAndNextBills.next as number),
			});
		} else {
			setEdit(false);
			form.resetFields();
			return navigate(`/bills/${previousAndNextBills.next}`);
		}
	}, [previousAndNextBills, navigate, form, formValues, t, modal, saveAndMoveToBill, discardAndMoveToBill]);

	const status = useMemo(() => {
		if (isError) {
			return error?.response?.status;
		}
	}, [isError, error]);

	const billRefnum = useMemo(() => {
		return data.billRefnum || data.billId || refNum(billId || 0);
	}, [data, billId]);

	if (billId === null || status === 404) return <Navigate replace to={'/404'} />;

	return (
		<RouteContainer title={t('bill.title-bill', {id: billRefnum})} isLoading={isLoading}>
			{modalContextHolder}
			<div className={'viewbill'}>
				<div className={'viewbill__toprow'}>
					<div>
						<Breadcrumb billRefnum={billRefnum} data={data} />
					</div>

					<div className={'viewbill__back'}>
						<Button
							type={'default'}
							onClick={moveToPreviousBill}
							disabled={!previousAndNextBills.previous}
						>
							<FontAwesomeIcon icon={faAngleLeft} size={'xs'} />
							{t('bill.previous-bill')}
						</Button>
						<Button
							type={'default'}
							onClick={moveToNextBill}
							disabled={!previousAndNextBills.next}
						>
							{t('bill.next-bill')}
							<FontAwesomeIcon icon={faAngleRight} size={'xs'} />
						</Button>
					</div>
				</div>

				{data.newIban && <NewIbanWarning iban={data.billCreatorIban} />}

				{showPaymentInformation && <PaymentInformation data={data} />}

				<Steps
					invoice={invoice}
					saveBill={saveBill}
					edit={edit}
					setEdit={setEdit}
					billStatus={data.billStatus}
					form={form}
				/>
			</div>
		</RouteContainer>
	);
};

export default ViewBill;