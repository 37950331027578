import React, {useState, useCallback, useMemo, useEffect} from 'react';
import {Modal, Spin, Typography, Select, Button} from 'antd';
import {useTranslation} from 'react-i18next';

import Input from '../../../components/Input';
import {useAddMileageType} from '../../../queries/useAddMileageType';
import {useGetMileageTypesUsed} from '../../../queries/useGetMileageTypesUsed';
import {useUpdateMileageType} from '../../../queries/useUpdateMileageType';
import {useDeleteMileageType} from '../../../queries/useDeleteMileageType';
import {useMessage} from '../../../utils/useMessage';
import {useGetAccountInfo} from '../../../queries/useGetAccountInfo';

const {Title, Text, Paragraph} = Typography;

type ModalTitleProps = {
	text: string;
};

const ModalTitle = ({text}: ModalTitleProps) => (
	<Title level={3}>{text}</Title>
);

type ModalButtonsProps = {
	onDelete: () => void;
	onSubmit: () => void;
	onCancel: () => void;
	submitLoading: boolean;
	canDelete: boolean;
	deleteLoading: boolean;
};

const ModalButtons = ({
	onDelete,
	onSubmit,
	onCancel,
	submitLoading,
	canDelete,
	deleteLoading,
}: ModalButtonsProps) => {
	const {t} = useTranslation();

	return (
		<div className={`mileages__modal__footer ${canDelete ? 'delete' : ''}`}>
			{canDelete &&
				<Button danger type={'primary'} onClick={onDelete} loading={deleteLoading}>
					{t('mileages.categories.delete-button')}
				</Button>
			}
			<div>
				<Button onClick={onCancel}>
					{t('mileages.categories.cancel')}
				</Button>
				<Button type={'primary'} onClick={onSubmit} loading={submitLoading}>
					{t('mileages.categories.save')}
				</Button>
			</div>
		</div>
	);
};

type Props = {
	type: 'edit' | 'add';
	visible: boolean;
	onCloseModal: () => void;
	editId: number | null;
};

const AddOrEditModal = ({
	visible,
	type,
	onCloseModal,
	editId,
}: Props) => {
	const {t} = useTranslation();
	const message = useMessage();

	const [name, setName] = useState<string>('');
	const [nameError, setNameError] = useState<boolean>(false);
	const [amount, setAmount] = useState<string>('');
	const [amountError, setAmountError] = useState<boolean>(false);

	const [mileageTypeEnabled, setMileageTypeEnabled] = useState<number>(1);

	const {data: accountInfoData} = useGetAccountInfo();
	const {mileageTypes} = accountInfoData;

	const {isLoading: isLoadingAddMileageType, ...mileageTypesPost} = useAddMileageType();
	const {isLoading: isLoadingUpdateMileageType, ...updateMileageType} = useUpdateMileageType();
	const {isLoading: isLoadingGetMileageTypesUsed, data: mileageTypesUsedData} = useGetMileageTypesUsed(editId);
	const {isLoading: isLoadingDeleteMileageType, ...deleteMileageType} = useDeleteMileageType();

	const isLoading = useMemo(() => {
		return isLoadingAddMileageType || isLoadingUpdateMileageType;
	}, [isLoadingAddMileageType, isLoadingUpdateMileageType]);

	const editMileage = useMemo(() => {
		if (type === 'edit') {
			return mileageTypes.find((x) => x.mileageId === editId);
		}
		return null;
	}, [type, mileageTypes, editId]);

	useEffect(() => {
		if (editMileage) {
			setName(editMileage.mileageType);
			setAmount(editMileage.mileageAmount.toFixed(2));
			setMileageTypeEnabled(editMileage.mileageInUse);
		}
	}, [editMileage, setName, setAmount, setMileageTypeEnabled]);

	// Empty modal fields when it's closed
	useEffect(() => {
		if (!visible) {
			setName('');
			setNameError(false);
			setAmount('');
			setAmountError(false);
		}
	}, [visible, setName, setNameError, setAmount, setAmountError]);

	const title = useMemo(() => {
		if (type === 'edit') return t('mileages.categories.edit-title');
		return t('mileages.categories.new');
	}, [type, t]);

	const mileageTypeEnabledOptions = useMemo(() => ([
		{value: 1, label: t('mileages.categories.enabled.true')},
		{value: 0, label: t('mileages.categories.enabled.false')},
	]), [t]);

	const validateName = useCallback((value: string): boolean => {
		if (value.trim() === '') {
			setNameError(true);
			return false;
		} else {
			setNameError(false);
			return true;
		}
	}, [setNameError]);

	const onNameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		validateName(event.target.value);
		setName(event.target.value);
	}, [setName, validateName]);

	const validateAmount = useCallback((value: string): boolean => {
		if (value.trim() === '' || isNaN(Number(value)) || Number(value) <= 0) {
			setAmountError(true);
			return false;
		} else {
			setAmountError(false);
			return true;
		}
	}, [setAmountError]);

	const onAmountChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value.replace(/,/g, '.');
		validateAmount(value);
		setAmount(value);
	}, [setAmount, validateAmount]);

	const onSubmit = useCallback(() => {
		const nameValid = validateName(name);
		const amountValid = validateAmount(amount);
		if (nameValid && amountValid) {
			const data = {
				mileageType: name.trim(),
				mileageAmount: Number(Number(amount).toFixed(2)),
				mileageInUse: mileageTypeEnabled,
				id: Number(editId),
			};
			if (type === 'edit') {
				return updateMileageType.mutate(data, {
					onSuccess: onCloseModal,
				});
			} else {
				return mileageTypesPost.mutate(data, {
					onSuccess: onCloseModal,
				});
			}
		}

		return message.open({
			type: 'error',
			content: t('errors.fill-empty-or-invalid-fields'),
			duration: 3,
		});
	}, [mileageTypesPost, name, amount, message, t, editId, mileageTypeEnabled, onCloseModal, type, updateMileageType, validateAmount, validateName]);

	const onDelete = useCallback(() => {
		if (editId) {
			return deleteMileageType.mutate({mileageId: editId}, {
				onSuccess: onCloseModal,
			});
		}
		return message.open({
			type: 'error',
			content: t('errors.something-went-wrong'),
			duration: 3,
		});
	}, [deleteMileageType, editId, message, t, onCloseModal]);

	return (
		<Modal
			title={<ModalTitle text={title} />}
			open={visible}
			onOk={onSubmit}
			okText={t('mileages.categories.save')}
			onCancel={onCloseModal}
			cancelText={t('mileages.categories.cancel')}
			footer={
				<ModalButtons
					onSubmit={onSubmit}
					submitLoading={isLoading}
					onCancel={onCloseModal}
					onDelete={onDelete}
					canDelete={type === 'edit' && !mileageTypesUsedData.used}
					deleteLoading={isLoadingDeleteMileageType}
				/>
			}
		>
			<div className={'mileages__modal'}>
				<Input
					label={t('mileages.categories.new-name')}
					status={nameError ? 'error' : ''}
					value={name}
					onChange={onNameChange}
				/>

				<Input
					label={t('mileages.categories.new-amount')}
					status={amountError ? 'error' : ''}
					value={amount}
					onChange={onAmountChange}
					disabled={type === 'edit'}
				/>

				{type === 'add' && (
					<Text italic className={'mileages__modal__info'}>{t('mileages.categories.new-info')}</Text>
				)}
				{type === 'edit' && (
					<Text italic>{t('mileages.categories.edit-amount-info')}</Text>
				)}

				{type === 'edit' && (
					<div className={'mileages__modal__edit'}>
						<Paragraph><Text strong>{t('mileages.categories.enabled-title')}</Text></Paragraph>
						<Paragraph>{t('mileages.categories.enabled-info')}</Paragraph>
						<Select
							options={mileageTypeEnabledOptions}
							labelInValue={true}
							value={{value: mileageTypeEnabled}}
							onChange={({value}) => setMileageTypeEnabled(value)}
						/>

						<Paragraph><Text strong>{t('mileages.categories.delete-title')}</Text></Paragraph>

						{isLoadingGetMileageTypesUsed ? (
							<Spin />
						) : (
							<>
								{mileageTypesUsedData.used ? (
									<Paragraph>{t('mileages.categories.delete-impossible-info')}</Paragraph>
								) : (
									<>
										<Paragraph>{t('mileages.categories.delete-possible-info')}</Paragraph>

									</>
								)}
							</>
						)}
					</div>
				)}
			</div>
		</Modal>
	);
};

export default AddOrEditModal;