import React, {useCallback, useMemo, useState} from 'react';
import {Button, Typography, Checkbox} from 'antd';
import {CheckboxChangeEvent} from 'antd/es/checkbox';
import {useTranslation, Trans} from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';
import {Link} from 'react-router-dom';

import {FormData} from '../../types';
import {useCreateAccount, CreateAccountData} from '../../../../queries/useCreateAccount';
import i18n from '../../../../i18n';
import {getLanguage} from '../../../../queries/types';

const {Text, Title} = Typography;

type Props = {
	formData: FormData | null;
	userHasToRegister: boolean;
	focused: boolean;
	nextStep: () => void;
	previousStep: (skip?: number) => void;
}

const Summary = ({formData, userHasToRegister, focused, nextStep, previousStep}: Props) => {
	const {t} = useTranslation();

	const {isLoading, ...createAccount} = useCreateAccount();
	const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);

	const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState<boolean>(false);
	const [termsAccepted, setTermsAccepted] = useState<boolean>(false);

	const onTermsChange = useCallback((e: CheckboxChangeEvent) => {
		setTermsAccepted(e.target.checked);
	}, []);

	const onPrivacyPolicyChange = useCallback((e: CheckboxChangeEvent) => {
		setPrivacyPolicyAccepted(e.target.checked);
	}, []);

	const invoicingMethod = useMemo(() => {
		if (formData?.invoicing.emailForInvoicing) return 'email';
		return 'e-invoice';
	}, [formData]);

	const onSubmit = useCallback(() => {
		const data: CreateAccountData = {
			invoicingMethod,
			accountName: formData?.organisation.accountName || '',
			accountSlug: formData?.organisation.accountSlug || '',
			businessId: formData?.organisation.businessId || '',
			userEmail: formData?.user.userEmail || '',
			userPassword: formData?.user.userPassword || formData?.register.newPassword || '',
			userName: formData?.register.userName,
			recaptchaToken: recaptchaToken || '',
			language: getLanguage(i18n.language),
		};

		if (invoicingMethod === 'email') {
			data.emailForInvoicing = formData?.invoicing.emailForInvoicing;
		} else {
			data.eInvoiceAddress = formData?.invoicing.eInvoiceAddress;
			data.eInvoiceProvider = formData?.invoicing.eInvoiceProvider;
		}

		createAccount.mutate(data, {
			onSuccess: () => {
				nextStep();
			},
		});

	}, [formData, createAccount, nextStep, invoicingMethod, recaptchaToken]);

	const canOpenAccount = useMemo(() => {
		return recaptchaToken && privacyPolicyAccepted && termsAccepted;
	}, [recaptchaToken, privacyPolicyAccepted, termsAccepted]);

	// This is set to prevent focus fields that are not visible by pressing tab button
	const focusProps = useMemo(() => {
		if (focused) return {};
		return {tabIndex: -1};
	}, [focused]);

	return (
		<div className={'createaccount__summary'}>
			<Title level={4}>{t('create-account.summary.title')}</Title>
			<Text>{t('create-account.summary.info')}</Text>

			<Title level={5}>{t('create-account.organisation.title')}</Title>
			<Text strong>{t('create-account.organisation.name')}</Text>
			<Text>{formData?.organisation.accountName || '–'}</Text>
			<Text strong>{t('create-account.organisation.slug')}</Text>
			<Text>https://{formData?.organisation.accountSlug}.kululaskut.fi</Text>
			<Text strong>{t('create-account.organisation.business-id')}</Text>
			<Text>{formData?.organisation.businessId || '–'}</Text>

			<Title level={5}>{t('create-account.invoicing.title')}</Title>
			<Text strong>{t('create-account.invoicing.invoicing-method')}</Text>
			<Text>{t(`create-account.invoicing.${invoicingMethod}`)}</Text>
			{invoicingMethod === 'email' ? (
				<>
					<Text strong>{t('create-account.invoicing.email-for-invoicing')}</Text>
					<Text>{formData?.invoicing.emailForInvoicing || '–'}</Text>
				</>
			) : (
				<div className={'createaccount__summary__einvoice'}>
					<Text strong>{t('create-account.invoicing.e-invoice-provider')}</Text>
					<Text strong>{t('create-account.invoicing.e-invoice-address')}</Text>
					<Text>{formData?.invoicing.eInvoiceProvider || '–'}</Text>
					<Text>{formData?.invoicing.eInvoiceAddress || '–'}</Text>
				</div>
			)}

			<Title level={5}>{t('create-account.user.title')}</Title>
			<Text strong>{t('create-account.user.email')}</Text>
			<Text>{formData?.user.userEmail || '–'}</Text>
			<Text strong>{t('create-account.register.name')}</Text>
			<Text>{formData?.user.userName || formData?.register.userName || '–'}</Text>

			<div className={'createaccount__summary__recaptcha'}>
				<ReCAPTCHA
					sitekey={process.env.REACT_APP_RECAPTCHA_KEY || '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'}
					onChange={setRecaptchaToken}
					{...focusProps}
				/>
			</div>

			<div className={'createaccount__summary__terms'}>
				<Checkbox checked={termsAccepted} onChange={onTermsChange}>
					<Trans
						i18nKey={'create-account.summary.accept-terms'}
						components={{
							termsLink: <Link to={'https://kululaskut.fi/kayttoehdot/'} target={'_blank'}></Link>,
						}}
					/>
				</Checkbox>
				<Checkbox checked={privacyPolicyAccepted} onChange={onPrivacyPolicyChange}>
					<Trans
						i18nKey={'create-account.summary.accept-privacy-policy'}
						components={{
							privacyPolicyLink: <Link to={'https://kululaskut.fi/tietosuoja/'} target={'_blank'}></Link>,
						}}
					/>
				</Checkbox>
			</div>

			<Button
				className={'createaccount__summary__submit'}
				onClick={() => previousStep(userHasToRegister ? 0 : 1)}
				{...focusProps}
			>
				{t('create-account.back')}
			</Button>

			<Button
				type={'primary'}
				className={'createaccount__summary__submit'}
				onClick={onSubmit}
				disabled={!canOpenAccount}
				loading={isLoading}
				{...focusProps}
			>
				{t('create-account.summary.submit')}
			</Button>
		</div>
	);
};

export default Summary;