import {useQuery} from '@tanstack/react-query';

import Api from './api';
import {AxiosResponse} from 'axios';

export type LoginValidateData = {
	authorized: boolean | null;
};

type LoginValidateResponse = {
	data: LoginValidateData;
	isLoading: boolean;
	isSuccess: boolean;
	isError: boolean;
	error: unknown;
}

const getLoginValidate = async () => {
	const {data}: AxiosResponse<LoginValidateData> = await Api.get('/login/validate');
	return data;
};

export const useLoginValidate = (loggedIn: boolean): LoginValidateResponse => {
	const query = useQuery({
		queryKey: ['login-validate'],
		queryFn: getLoginValidate,
		cacheTime: 60 * 60 * 1000,
		enabled: loggedIn,
	});

	return {
		data: query.data || {authorized: null},
		isLoading: query.isLoading,
		isSuccess: query.isSuccess,
		isError: query.isError,
		error: query.error,
	};
};