import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Typography, Dropdown, Button, Skeleton, Space, Divider, theme} from 'antd';
import {DownOutlined, PlusOutlined} from '@ant-design/icons';
import type {MenuProps} from 'antd';
import {useNavigate} from 'react-router-dom';

import {useGetUserInfo} from '../../../queries/useGetUserInfo';
import {useGetAccountInfo} from '../../../queries/useGetAccountInfo';

const {useToken} = theme;

const {Text, Paragraph} = Typography;

type Props = {
	onChangeAccount: (accountId: number) => void;
};

const UserInfo = ({onChangeAccount}: Props) => {
	const {token} = useToken();
	const {t} = useTranslation();
	const navigate = useNavigate();

	const {data: userInfo, isLoading: isLoadingUserInfo} = useGetUserInfo();
	const {data: accountInfo, isLoading: isLoadingAccountInfo} = useGetAccountInfo();

	const isLoading = useMemo(() => isLoadingUserInfo || isLoadingAccountInfo, [isLoadingUserInfo, isLoadingAccountInfo]);

	const items: MenuProps['items'] = useMemo(() => {
		return [
			...userInfo.userAccounts.map((account) => ({
				key: account.accountId,
				label: account.accountName,
				onClick: () => onChangeAccount(account.accountId),
				type: 'primary',
			}))
		];
	}, [userInfo, onChangeAccount]);

	if (isLoading) {
		return (
			<div className={'menu__sidebar__userinfo'}>
				<Skeleton active={true} paragraph={{rows: 1}} title={false} />
				<Skeleton.Button active={true} size={'small'} className={'menu__sidebar__userinfo__loading__button'} />
			</div>
		);
	}

	const contentStyle = {
		backgroundColor: token.colorBgElevated,
		borderRadius: token.borderRadiusLG,
		boxShadow: token.boxShadowSecondary,
	};

	return (
		<div className={'menu__sidebar__userinfo'}>
			<Paragraph><Text strong>{userInfo.userName}</Text></Paragraph>
			<Dropdown
				menu={{items, selectedKeys: [userInfo.userActiveAccount.toString()]}}
				placement={'bottomLeft'}
				arrow={true}
				dropdownRender={(menu) => (
					<div style={contentStyle}>
						{React.cloneElement(menu as React.ReactElement, {style: {boxShadow: 'none'}})}
						<Divider style={{margin: 0}} />
						<Space style={{padding: 8}}>
							<Button icon={<PlusOutlined/>} type={'primary'} onClick={() => navigate('/create-account')}>{t('menu.new-account')}</Button>
						</Space>
					</div>
				)}
				overlayClassName={'menu__sidebar__userinfo__accountselector'}
			>
				<Button size={'small'}>
					<div>
						<div>
							{accountInfo.accountName}
						</div>
						<DownOutlined />
					</div>
				</Button>
			</Dropdown>
		</div>
	);
};

export default UserInfo;